import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Try from './../img/packages/try.png'
// import team3 from './../img/team3.png';
function Blog() {

  const [marcosModal, setMarcosModal] = useState(false);
  const [gonzaModal, setGonzaModal] = useState(false);
  const [elianaModal, setElianaModal] = useState(false);
  const handleMarcosClose = () => setMarcosModal(false);
  const handleMarcosShow = () => setMarcosModal(true);
  const handleGonzaClose = () => setGonzaModal(false);
  const handleGonzaShow = () => setGonzaModal(true);
  const handleElianaClose = () => setElianaModal(false);
  const handleElianaShow = () => setElianaModal(true);

  return (
    <motion.div
      className="wrapper mb-5 pb-5"
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partners | Coming Soon - Scuba Point Tenerife</title>
        <link rel="canonical" href="https://www.scuba-point.com/blog_about_scuba_diving_tenerife/" />
        <meta name="description" content="Partners of Scuba Point Tenerife are about to make a splash! Stay tuned for an amazing line-up of collaborations. 'Water' you waiting for?" />
        <meta name="keywords" content="Scuba Diving, Partners, Diving Partners, Scuba Point, Tenerife Diving, Diving Collaborations, Scuba Partners, Diving Companies, Scuba Point Partners" />
      </Helmet>
      <Container>
        <Row>
          <div className="col-12 col-xl-8 offset-xl-2 mb-5 pb-5">
            <Container>
              <Row>
                <div
                  className='col-12'
                >
                  <motion.h1
                    className='col-xl-9'
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 1, delay: 0 }
                    }}>
                    <span className="h1 mb-5">
                      SCUBa
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{

                          opacity: 1,
                          position: "relative",
                          transition: { duration: 2, delay: 1 }
                        }}
                      >&nbsp;POINT </motion.span>
                      BLOG COMING SOON</span></motion.h1>
                  <motion.p
                    className="col-xl-9"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.3, delay: 0.3 }
                    }}
                  >

                    Our blog is currently learning how to dive! Soon, we'll be surfacing with incredible diving stories, tips, and a whole lot more. We promise it'll be worth the 'wet'! Stay&nbsp;tuned!
                  </motion.p>
                </div>
                <motion.div
                  className='col-12 col-md-4'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.1 }
                  }}
                >
                  <img src={Try} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'><p> Coming soon</p></h2>
                  <p className='text-center'> Coming soon</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleMarcosShow}>READ&nbsp;MORE</span>
                  <Modal
                    keyboard={false}
                    show={marcosModal}
                    onHide={handleMarcosClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'>Coming soon</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <p> Coming soon</p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.3 }
                  }}
                >
                  <img src={Try} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'><p> Coming soon</p></h2>
                  <p className='text-center'> Coming soon</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleGonzaShow}>READ&nbsp;MORE</span>
                  <Modal
                    show={gonzaModal}
                    onHide={handleGonzaClose}
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'><p> Coming soon</p></h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p> Coming soon</p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.5 }
                  }}
                >
                  <img src={Try} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'><p> Coming soon</p></h2>
                  <p className='text-center'><p> Coming soon</p></p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleElianaShow}>READ&nbsp;MORE</span>
                  <Modal
                    show={elianaModal}
                    onHide={handleElianaClose}
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'><p> Coming soon</p></h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p> Coming soon</p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
    </motion.div >
  )
}

export default Blog
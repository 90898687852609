import React from 'react'
import "./../css/Footer.scss";
import { Link } from "react-router-dom";
import SocialIcons from "./SocialIcons";
import LogoLight from "./../img/scuba-point.svg";

function Footer() {

 return (
  <>
   <footer>
    <Link to="/#">
     <img className="logo" src={LogoLight} alt="Scuba Point logo" />
    </Link>
    <div className="social-roll-wrap"> <SocialIcons /></div>


   </footer>
  </>
 )
}

export default Footer
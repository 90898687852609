import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/loading.scss'
import AnimatedRoutes from './assets/js/AnimatedRoutes';
import Footer from './assets/js/Footer';
import Nav from './assets/js/Nav';
import IntroModal from "./assets/js/IntroModal"
import './assets/css/cookies.scss';
function App() {


  return (
    <div className="App">
      <main>
        <AnimatedRoutes />
        <Footer />
      </main>
      <Nav />
      <IntroModal />
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import AboutReviews from './AboutReviews'
import AboutEventPartners from './AboutEventPartners';
// import BookingsModal from "./BookingsModal";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Wrapper.scss';
import team1 from './../img/team1.png';
import team2 from './../img/team2.png';
import team3 from './../img/team3.png';
import marcos from './../img/marcos.jpg';
//import marcos2 from './../img/marcos2.jpg';
import gonza from './../img/gonza.jpg';
import eliana1 from './../img/eliana1.jpg';
import eliana2 from './../img/eliana2.jpg';
import eliana3 from './../img/eliana3.jpg';

// import emailIcon from './../img/icons/emailIcon.png';
function About() {

  const [marcosModal, setMarcosModal] = useState(false);
  const [gonzaModal, setGonzaModal] = useState(false);
  const [elianaModal, setElianaModal] = useState(false);
  const handleMarcosClose = () => setMarcosModal(false);
  const handleMarcosShow = () => setMarcosModal(true);
  const handleGonzaClose = () => setGonzaModal(false);
  const handleGonzaShow = () => setGonzaModal(true);
  const handleElianaClose = () => setElianaModal(false);
  const handleElianaShow = () => setElianaModal(true);

  const AboutldJson = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Scuba Point Diving School",
    "url": "https://www.scuba-point.com/about_scuba_point_diving_tenerife/",
    "logo": "https://scuba-point.com/static/media/scuba-point-black.358ecd351e02c5d651eae49d0721c7e0.svg",
    "sameAs": [
      "https://www.facebook.com/profile.php?id=100094764036107",
      "https://www.instagram.com/scubapointtenerife/?hl=en",
      "https://www.pinterest.co.uk/ScubaPointDivingTenerife/",
      "https://twitter.com/ScubaPointDives",
      "https://www.linkedin.com/in/scuba-point-diving-tenerife/",
      "https://www.tiktok.com/@scubapointtenerife"
    ],
    "member": [
      {
        "@type": "Person",
        "name": "Marcos",
        "jobTitle": "Founder and Instructor",
        "description": "Marcos is a founder and instructor at Scuba Point, with 17 years of diving experience and a love for the underwater world.",
        "image": "https://scuba-point.com/static/media/team1.dac9596cb4bbe2ef5023.png"
      },
      {
        "@type": "Person",
        "name": "Gonza",
        "jobTitle": "Instructor",
        "description": "Gonza is an Argentinian legend who is passionate about diving and excels at keeping it fun too.",
        "image": "https://scuba-point.com/static/media/team2.66051f82f3a4023e95d8.png"
      },
      {
        "@type": "Person",
        "name": "Eliana",
        "jobTitle": "Marketing and Accounting",
        "description": "Eliana loves Tenerife and swimming with rays across the rocks. She supports Scuba Point through marketing and accounting.",
        "image": "https://scuba-point.com/static/media/team3.cbd8064ce7802d0e9451.png"
      }
    ]
  }




  return (
    <motion.div
      className="wrapper"
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Scuba Point | Scuba Dive Tenerife with Experts</title>
        <link rel="canonical" href="https://www.scuba-point.com/about_scuba_point_diving_tenerife/" />
        <meta name="description" content="Learn about Scuba Point, the leading scuba diving school in Tenerife. Discover why our expert guidance and diverse diving experiences make us the top choice for those looking to scuba dive in Tenerife." />
        <meta name="keywords" content="Scuba Dive Tenerife, Scuba Diving School, Diving Experts, Diving Courses, Underwater Adventure, Scuba Point" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(AboutldJson)}
      </script>
      <Container>
        <Row>
          <div className="col-12 col-xl-8 offset-xl-2">

            <Container>
              <Row>

                <motion.div
                  className='mb-2 col-12'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 20,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.5, delay: 0 }
                  }}
                ><span className="h1 mb-5">ABOUT scuba
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        position: "relative",
                        transition: { duration: 2, delay: 1 }
                      }}
                    >&nbsp;point </motion.span>
                    IN&nbsp;TENERIFE</span>
                </motion.div>
                <motion.p
                  className='col-12 col-xl-9 special_p'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 30,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 0.6, delay: 0.6 }
                  }}
                >

                  🌊 Welcome to our diving school 🏫 in <a href="https://www.google.com/maps/place/Scuba+Point:+Diving+Tenerife/@28.0486359,-16.72079,15z/data=!4m2!3m1!1s0x0:0x103e2db62123bb5?sa=X&ved=2ahUKEwjV6JfR_p-AAxXkUaQEHfiNDzYQ_BJ6BAhqEAA&ved=2ahUKEwjV6JfR_p-AAxXkUaQEHfiNDzYQ_BJ6BAh1EAg">Calle Espigon</a> (38650), Los Cristianos, Tenerife 🌴. We've been organising excursions 🛥️ in Tenerife for over 4 years. That's longer than a sea turtle's 🐢 nap! So buckle up your sea belts 🌊🔗 and get ready to dive! 🤿🐠
                </motion.p>

                <motion.p
                  className='col-12 col-xl-3 special_p'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 40,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 0.7, delay: 0.7 }
                  }}
                >
                </motion.p>
                <motion.p
                  className='col-12 col-xl-6'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 40,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 0.8, delay: 0.8 }
                  }}
                >
                  🐠 Dive into a world where fish 🐟 are friends, not food 🍽️! At Scuba Point 🤿, you'll have the 'oppor-tuna-ty' 🐟😂 to explore Tenerife's underwater wonderland 🌊🌈 that's more colourful than a rainbow parrotfish's 👗🌈 wardrobe. We're not just 'fishing' 🎣 for compliments here, we really do offer a whale 🐋 of diving courses, from 'just getting my fins wet' 🦶🌊 beginner to 'I speak dolphin' 🐬💬 professional. No matter your experience level, we've got the perfect dive 🌊 waiting for you. Trust us, we're not 'squidding' 🦑 around. Dive in! 🌊🤿
                </motion.p>
                <motion.p
                  className='col-12 col-xl-6'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 40,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 0.9, delay: 0.9 }
                  }}
                >
                  🤿 As for our diving excursions, from exploring hidden caves 🌌 to party crashing at sunken ships 🚢 teeming with life, we've got the whole underwater shebang! 🎉 Expect to see friendly green turtles 🐢, waltz with graceful rays 🩰, play peek-a-boo with cheeky octopuses 🐙, and sing duets with elusive moray eels 🎤. Welcome to our underwater 'hood! 🌊🏠 And guess what? We've got your back from A-Z: equipment 🎒, transportation 🚌, and briefings for all you water babies 🧜‍♂️. Book through our website, and BAM! - snag yourself a <Link to="/packages_from_scuba_point_tenerife">10% off</Link> 🎉💸 disco-discount! 🌊🤩
                </motion.p>
                <motion.div
                  className='mb-2 col-12'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 100,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.1, delay: 1.1 }
                  }}
                ><span className="h1 mb-5">MEET THE
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{

                        opacity: 1,
                        position: "relative",
                        transition: { duration: 1.2, delay: 1.1 }
                      }}
                    > TEAM</motion.span>
                  </span>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4 pt-5 px-5'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.1 }
                  }}
                >
                  <img src={team1} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'>Marcos</h2>
                  <p className='text-center'>Marcos is a founder and instructor at Scuba Point, he has 17 years of diving experience and loves the underwater world.</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleMarcosShow}>FULL&nbsp;BIO</span>
                  <Modal
                    keyboard={false}
                    show={marcosModal}
                    onHide={handleMarcosClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'>Marcos bio</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Marcos is a diving instructor with 17 years of experience.
                      <img src={marcos} className="img-fluid mt-3 mb-3" alt="Marcos, a diving instructor in Tenerife" />
                      <p>He is based in Tenerife, and has extensive knowledge of the local diving spots.</p>
                      <p>He is an experienced and skilled instructor who is passionate about diving and dedicated to ensuring his students have a safe and enjoyable experience.</p>

                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4 pt-5 px-5'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.3 }
                  }}
                >
                  <img src={team2} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'>Gonza</h2>
                  <p className='text-center'>Gonza is an Argentinian legend who is passionate about diving, he is excellent at keeping it fun too.</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleGonzaShow}>FULL&nbsp;BIO</span>
                  <Modal
                    show={gonzaModal}
                    onHide={handleGonzaClose}
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'>Gonza bio</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Meet Gonza, an experienced and passionate Argentinian diving instructor based in Tenerife.
                      <img src={gonza} className="img-fluid mt-3 mb-3" alt="Marcos, a diving instructor in Tenerife" />
                      <p> Gonza has dedicated his life to diving and sharing his love for the ocean with others. He has been a diving instructor for many years and has a wealth of knowledge about the local diving spots. </p>
                      <p>When Gonza is not teaching diving, he loves to spend time with his 5-year-old daughter Moana, who is already showing an interest in following in her father's footsteps and exploring the underwater world. Gonza is a dedicated and patient instructor who is committed to ensuring that his students have a safe and enjoyable diving experience. He's a family man and loves to share his passion with his daughter Moana.</p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4 pt-5 px-5'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.5 }
                  }}
                >
                  <img src={team3} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'>Eliana</h2>
                  <p className='text-center'>Eliana is a new member of the team, she loves Tenerife and swimming with rays across the rocks.</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleElianaShow}>FULL&nbsp;BIO</span>
                  <Modal
                    show={elianaModal}
                    onHide={handleElianaClose}
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'>Eliana bio</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Meet Eliana, a valuable member of the Scuba Point diving team based in Tenerife.
                      <img src={eliana2} className="img-fluid mt-3 mb-3" alt="Marcos, a diving instructor in Tenerife" />
                      <p>While she is not currently leading diving excursions, she plays an important role in the business by helping with marketing and accounting. She is a highly organized and detail-oriented professional with a deep understanding of the diving industry.</p>
                      <img src={eliana1} className="img-fluid mt-3 mb-3" alt="Marcos, a diving instructor in Tenerife" />
                      <p>Eliana is a dedicated and hardworking team member who is committed to ensuring the success of the diving business. She brings her skills and experience to the team and works closely with the instructors to ensure that the business runs smoothly.</p>
                      <img src={eliana3} className="img-fluid mt-3 mb-3" alt="Marcos, a diving instructor in Tenerife" />
                      <p> Despite not being a diving instructor, Eliana still maintains her diving certifications and stays up-to-date on industry standards, so she can be ready to jump in and assist with diving excursions when needed.</p>

                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 2 }
                  }}
                >
                  <AboutReviews />
                  <AboutEventPartners />
                </motion.div>

              </Row>
            </Container>
          </div>
        </Row>
      </Container>

    </motion.div >
  )
}

export default About
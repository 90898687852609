import React from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import InnerGallery from './GalleryInner';
// import BookingsModal from "./BookingsModal"
function Gallery() {

  const GalleryldJson = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Scuba Diving Gallery | Explore Underwater Tenerife with Scuba Point",
    "description": "Immerse yourself in our photo gallery showcasing the captivating underwater world of Tenerife. Get a glimpse of what you can experience when you scuba dive in Tenerife with Scuba Point.",
    "url": "https://scuba-point.com/gallery_of_scuba_diving_tenerife/",
    "mainEntity": {
      "@type": "ImageGallery",
      // "image": [
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb1.00e4b26348359655b22d.jpg",
      //     "description": "Three scuba divers on La Vista beach in sunny Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb2.9fb59e2fda8d7f9589e5.jpg",
      //     "description": "3 underwater scuba divers in Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb3.79799c44795eaec9b373.jpg",
      //     "description": "Two scuba divers on La Vista beach in sunny Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb4.eb3f45d98165c83caa96.jpg",
      //     "description": "Gonza on a boat in Los Cristianos with his divers"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb5.7f36e415ca919406daea.jpg",
      //     "description": "Snorkelers on a boat in Los Cristianos"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb6.ff16f000ae37e9591ff3.jpg",
      //     "description": "Young boy snorkeling in Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb7.1c54f2eae064af8f59d7.jpg",
      //     "description": "Turtles and Scuba divers underwater in Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb8.966272b9dc405b56a1fb.jpg",
      //     "description": "Boy snorkeling in Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb9.995d85c84b5bc153162f.jpg",
      //     "description": "Two boys snorkeling in Tenerife"
      //   },
      //   {
      //     "@type": "ImageObject",
      //     "contentUrl": "https://scuba-point.com/static/media/thumb10.f620261a0e294968d5f0.jpg",
      //     "description": "A man and his son snorkeling off the coast of Los Cristianos in Tenerife"
      //   }
      // ]
    }
  }


  return (
    <motion.div
      className="wrapper"
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Scuba Diving Gallery | Explore Underwater Tenerife with Scuba Point</title>
        <link rel="canonical" href="https://www.scuba-point.com/gallery_of_scuba_diving_tenerife/" />
        <meta name="description" content=" Immerse yourself in our photo gallery showcasing the captivating underwater world of Tenerife. Get a glimpse of what you can experience when you scuba dive in Tenerife with Scuba Point." />
        <meta name="keywords" content="Scuba Dive Tenerife, Underwater Photos, Diving Gallery, Scuba Point" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(GalleryldJson)}
      </script>
      <Container>
        <Row>

          <motion.h1
            className='col-xl-7'
            initial={{ opacity: 0 }}
            animate={{
              y: 20,
              opacity: 1,
              position: "relative",
              transition: { duration: 1, delay: 0 }
            }}
          >
            <span className="h1">THE SCUBa
              <motion.span
                initial={{ opacity: 0 }}
                animate={{

                  opacity: 1,
                  position: "relative",
                  transition: { duration: 2, delay: 1 }
                }}
              >&nbsp;point </motion.span>
              PHOTO GALLERY</span></motion.h1>
          <motion.p
            className="col-xl-6 mb-5"
            initial={{ opacity: 0 }}
            animate={{
              y: 20,
              opacity: 1,
              position: "relative",
              transition: { duration: 0.3, delay: 0.3 }
            }}
          >
            Before you scroll down to our gallery, we suggest grabbing some popcorn, because our past trips are so exciting they could be blockbuster movies! We're talking 'Finding Nemo' meets 'The Little Mermaid' levels of excitement here.
          </motion.p>
          {/* <motion.div
      className='col-12 col-xl-12'
      initial={{ opacity: 0 }}
      animate={{
       y: -20,
       opacity: 1,
       position: "relative",
       transition: { duration: 0.4, delay: 0.4 }
      }}
     >
      <br />
      <BookingsModal />
     </motion.div> */}
        </Row>
      </Container>
      <InnerGallery />
    </motion.div >
  )
}

export default Gallery
import React from 'react';
// import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Google_reviews.scss';
import Iframe from 'react-iframe'
function AboutReviews() {

  return (
    <>
      <motion.div
        className='mb-2 col-12 mt-5'
        initial={{ opacity: 0 }}
        animate={{
          y: 20,
          opacity: 1,
          position: "relative",
          transition: { duration: 1.5, delay: 2.5 }
        }}
      ><span className="h1 mb-5">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              position: "relative",
              transition: { duration: 2, delay: 1 }
            }}
          >Our </motion.span>
          Reviews</span>
      </motion.div>

      <motion.div
        className='mb-2 col-12 mt-5'
        initial={{ opacity: 0 }}
        animate={{
          y: 20,
          opacity: 1,
          position: "relative",
          transition: { duration: 1.5, delay: 3 }
        }}
      >
        <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/google_reviews.html"
          width="100%"
          height="100%"
          className="reviews_iframe"
          display="block"
          position="relative" />
      </motion.div>
    </>
  )
}

export default AboutReviews


















import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './../css/DiveMapClickablepoint.scss';
import PlayaLaVista from './../img/photos/playa-vista-tenerife.jpg'
// import PalmMarCave from './../img/photos/palm-mar-wall-dive-spot.jpg'
import Pall1 from './../img/photos/Pall1.jpg'
import Pall2 from './../img/photos/Pall2.jpg'
import Cave1 from './../img/photos/cave1.jpg'
import Cave2 from './../img/photos/cave2.jpg'
import Cave3 from './../img/photos/cave3.jpg'
import Champ1 from './../img/photos/champ1.jpg'
import Champ2 from './../img/photos/champ2.jpg'
import Champ3 from './../img/photos/champ3.jpg'
import Paraiso1 from './../img/photos/paraiso1.jpg'
import Paraiso2 from './../img/photos/paraiso2.jpg'
import Paraiso3 from './../img/photos/paraiso3.jpg'
import Paraiso4 from './../img/photos/paraiso4.jpg'
import Meridian1 from './../img/photos/Meridian1.jpg'
import Meridian2 from './../img/photos/Meridian2.jpg'
import Condesito1 from './../img/photos/Condesito1.jpg'
import Condesito2 from './../img/photos/Condesito2.jpg'
import Abades1 from './../img/photos/Abades1.jpg'
import Abades2 from './../img/photos/Abades2.jpg'
import Abades3 from './../img/photos/Abades3.jpg'
import ElPoris1 from './../img/photos/ElPoris1.jpg'
import ElPoris2 from './../img/photos/ElPoris2.jpg'
import LasEras1 from './../img/photos/LasEras1.jpg'
import LasEras2 from './../img/photos/LasEras2.jpg'
import ElTablado1 from './../img/photos/ElTablado1.jpg'
import ElTablado2 from './../img/photos/ElTablado2.jpg'
import Roncadores1 from './../img/photos/Roncadores1.jpg'
import Roncadores2 from './../img/photos/Roncadores2.jpg'
import Chuchos1 from './../img/photos/Chuchos1.jpg'
import Chuchos2 from './../img/photos/Chuchos2.jpg'
import Tabaiba1 from './../img/photos/Tabaiba1.jpg'
import Tabaiba2 from './../img/photos/Tabaiba2.jpg'
import Radazul1 from './../img/photos/Radazul1.jpg'
import Radazul2 from './../img/photos/Radazul2.jpg'
import Puerto1 from './../img/photos/Puerto1.jpg'
import Puerto2 from './../img/photos/Puerto2.jpg'
import Puerto3 from './../img/photos/Puerto3.jpg'
import Arcos1 from './../img/photos/Arcos1.jpg'
import Arcos2 from './../img/photos/Arcos2.jpg'
import Arcos3 from './../img/photos/Arcos3.jpg'
import Punta1 from './../img/photos/Punta1.jpg'
import Punta2 from './../img/photos/Punta2.jpg'
import Punta3 from './../img/photos/Punta3.jpg'
import MarAzul1 from './../img/photos/Marazul1.jpg'
import MarAzul2 from './../img/photos/Marazul2.jpg'
import MarAzul3 from './../img/photos/Marazul3.jpg'
import Highway1 from './../img/photos/Highway1.jpg'
import Cruz1 from './../img/photos/Cruz1.jpg'
import Cruz2 from './../img/photos/Cruz2.jpg'
function DiveMapClickablePoints() {
 const [DiveSpot1Modal, DiveSpot1setModal] = useState(false);
 const DiveSpot1handleClose = () => DiveSpot1setModal(false);
 const DiveSpot1handleShow = () => DiveSpot1setModal(true);
 const [DiveSpot2Modal, DiveSpot2setModal] = useState(false);
 const DiveSpot2handleClose = () => DiveSpot2setModal(false);
 const DiveSpot2handleShow = () => DiveSpot2setModal(true);
 const [DiveSpot3Modal, DiveSpot3setModal] = useState(false);
 const DiveSpot3handleClose = () => DiveSpot3setModal(false);
 const DiveSpot3handleShow = () => DiveSpot3setModal(true);
 const [DiveSpot4Modal, DiveSpot4setModal] = useState(false);
 const DiveSpot4handleClose = () => DiveSpot4setModal(false);
 const DiveSpot4handleShow = () => DiveSpot4setModal(true);
 const [DiveSpot5Modal, DiveSpot5setModal] = useState(false);
 const DiveSpot5handleClose = () => DiveSpot5setModal(false);
 const DiveSpot5handleShow = () => DiveSpot5setModal(true);
 const [DiveSpot6Modal, DiveSpot6setModal] = useState(false);
 const DiveSpot6handleClose = () => DiveSpot6setModal(false);
 const DiveSpot6handleShow = () => DiveSpot6setModal(true);
 const [DiveSpot7Modal, DiveSpot7setModal] = useState(false);
 const DiveSpot7handleClose = () => DiveSpot7setModal(false);
 const DiveSpot7handleShow = () => DiveSpot7setModal(true);
 const [DiveSpot8Modal, DiveSpot8setModal] = useState(false);
 const DiveSpot8handleClose = () => DiveSpot8setModal(false);
 const DiveSpot8handleShow = () => DiveSpot8setModal(true);
 const [DiveSpot9Modal, DiveSpot9setModal] = useState(false);
 const DiveSpot9handleClose = () => DiveSpot9setModal(false);
 const DiveSpot9handleShow = () => DiveSpot9setModal(true);
 const [DiveSpot10Modal, DiveSpot10setModal] = useState(false);
 const DiveSpot10handleClose = () => DiveSpot10setModal(false);
 const DiveSpot10handleShow = () => DiveSpot10setModal(true);
 const [DiveSpot11Modal, DiveSpot11setModal] = useState(false);
 const DiveSpot11handleClose = () => DiveSpot11setModal(false);
 const DiveSpot11handleShow = () => DiveSpot11setModal(true);
 const [DiveSpot12Modal, DiveSpot12setModal] = useState(false);
 const DiveSpot12handleClose = () => DiveSpot12setModal(false);
 const DiveSpot12handleShow = () => DiveSpot12setModal(true);
 const [DiveSpot13Modal, DiveSpot13setModal] = useState(false);
 const DiveSpot13handleClose = () => DiveSpot13setModal(false);
 const DiveSpot13handleShow = () => DiveSpot13setModal(true);
 const [DiveSpot14Modal, DiveSpot14setModal] = useState(false);
 const DiveSpot14handleClose = () => DiveSpot14setModal(false);
 const DiveSpot14handleShow = () => DiveSpot14setModal(true);
 const [DiveSpot15Modal, DiveSpot15setModal] = useState(false);
 const DiveSpot15handleClose = () => DiveSpot15setModal(false);
 const DiveSpot15handleShow = () => DiveSpot15setModal(true);
 const [DiveSpot16Modal, DiveSpot16setModal] = useState(false);
 const DiveSpot16handleClose = () => DiveSpot16setModal(false);
 const DiveSpot16handleShow = () => DiveSpot16setModal(true);
 const [DiveSpot17Modal, DiveSpot17setModal] = useState(false);
 const DiveSpot17handleClose = () => DiveSpot17setModal(false);
 const DiveSpot17handleShow = () => DiveSpot17setModal(true);
 const [DiveSpot18Modal, DiveSpot18setModal] = useState(false);
 const DiveSpot18handleClose = () => DiveSpot18setModal(false);
 const DiveSpot18handleShow = () => DiveSpot18setModal(true);
 const [DiveSpot19Modal, DiveSpot19setModal] = useState(false);
 const DiveSpot19handleClose = () => DiveSpot19setModal(false);
 const DiveSpot19handleShow = () => DiveSpot19setModal(true);
 const [DiveSpot20Modal, DiveSpot20setModal] = useState(false);
 const DiveSpot20handleClose = () => DiveSpot20setModal(false);
 const DiveSpot20handleShow = () => DiveSpot20setModal(true);
 const [DiveSpot21Modal, DiveSpot21setModal] = useState(false);
 const DiveSpot21handleClose = () => DiveSpot21setModal(false);
 const DiveSpot21handleShow = () => DiveSpot21setModal(true);
 const [DiveSpot22Modal, DiveSpot22setModal] = useState(false);
 const DiveSpot22handleClose = () => DiveSpot22setModal(false);
 const DiveSpot22handleShow = () => DiveSpot22setModal(true);
 const [DiveSpot23Modal, DiveSpot23setModal] = useState(false);
 const DiveSpot23handleClose = () => DiveSpot23setModal(false);
 const DiveSpot23handleShow = () => DiveSpot23setModal(true);

 return (
  <>
   <div className='marker_wrap'>
    <div className="marker">
    </div>
    <div className="logo"></div>
   </div>
   <div className="towns">
    <h5>Los Cristianos</h5>
    <h5>Los Gigantes</h5>
    <h5>Santa Cruz</h5>
    <h5>La Laguna</h5>
    <h5>Hidalgo</h5>
    <h5>Puerto de la Cruz</h5>
    <h5>El Medano</h5>
    <h5>Abades</h5>
    <h5>SSHHH! Grandad is sleeping!</h5>
   </div>
   <div className="dive-spot-1" onClick={DiveSpot1handleShow}>
    <span>1</span>
   </div>
   <Modal
    keyboard={false}
    onHide={DiveSpot1handleClose}
    show={DiveSpot1Modal}
    style={DiveSpot1Modal ? {} : { display: 'none' }}

   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Playa Vista: your journey starts here!</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2 className="h4">La Vista Beach: South Tenerife's Underwater Gem For Beginners</h2>
     <img src={PlayaLaVista} className="img-fluid" alt="Playa Vista in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item"> <h5>🚩🏁🏳 <strong>Depth:</strong> 12m</h5></li>
      <li className="list-group-item"> <h5>🍳😅❄ <strong>Temp:</strong> 20 degrees all year round baby!</h5></li>
      <li className="list-group-item"> <h5>🐡🐠🐟 <strong>Creatures:</strong> Tropical fish will eat bread in your hand! Big manta rays occasionally in winter.</h5></li>
      <li className="list-group-item"> <h5>👀👀👀  <strong>Visibility:</strong> Very good most days</h5></li>
      <li className="list-group-item"> <h5>👁👅🧠  <strong>Things to see:</strong> Swim aound the rocks of the pier to see lots of fish in alcoves</h5></li>
     </ul>
     <h5>Welcome to a sunny beach</h5>
     <p>Located in the sun-soaked southern coast of Tenerife, <a href="https://www.google.com/maps/place/Beach+of+the+Views/@28.0510934,-16.7246021,17z/data=!3m1!4b1!4m6!3m5!1s0xc6a978051f57119:0x63827795aed58c1!8m2!3d28.05269!4d-16.7243853!16s%2Fg%2F121dpl3y?entry=ttu">La Vista Beach</a> is a slice of paradise that provides an unparalleled experience for both beach lovers and underwater adventurers. This pristine shoreline isn't just known for its shimmering golden sands and the gentle lapping of its waves, but it's also a revered spot for scuba diving enthusiasts.</p>
     <h5>First point of contact for new divers</h5>
     <p>For many diving novices, La Vista Beach is where they take their first breaths underwater. The majority of dive schools in Tenerife prefer this location for trial dives, and it's not hard to see why. The beach offers calm and clear waters, making it an ideal setting for beginners to familiarize themselves with the sport without feeling overwhelmed.</p>
     <h5>Marine life</h5>
     <p>But it's not just about clear waters and safety. The marine life at La Vista Beach is nothing short of spectacular. On a personal dive, I had the unforgettable experience of encountering a massive ray, gracefully gliding along the sandy seabed. The sight of such a majestic creature, with its expansive wingspan and serene movements, is something that remains etched in memory. For those curious about these gentle giants, our guide on <a href="https://www.google.com/search?rlz=1C5GCEM_en&sxsrf=AB5stBjrkhybhjwpC8SfBLeKMsmDHJAhZA:1689950168321&q=rays+tenerife&tbm=isch&sa=X&ved=2ahUKEwj20uH7gqCAAxXET6QEHXIVC_oQ0pQJegQITRAB&biw=1920&bih=920&dpr=1">rays at La Vista</a> provides a deep dive into their world.</p>
     <h5>Ecosystem</h5>
     <p>Encounters like these are commonplace at La Vista Beach. The diverse marine ecosystem ensures that every dive is a new adventure. From playful shoals of colorful fish darting between rocks to the occasional curious octopus peeking out from its den, the underwater world here is teeming with life and surprises.</p>
     <h5>Proximity</h5>
     <p>Moreover, the beach's proximity to numerous dive schools in South Tenerife makes it a bustling hub for diving activities. It's common to see groups of excited beginners, gearing up for their first dive, mingling with seasoned divers sharing tales of their underwater exploits. And for those not into diving, the beach itself is a haven to relax, sunbathe, and soak in the Canarian sun.</p>
     <h5>Conclusion</h5>
     <p>In conclusion, La Vista Beach is more than just a scenic spot in South Tenerife. It's an underwater playground that promises and delivers thrilling experiences with each dive. Whether you're taking the plunge for the first time or are a seasoned diver, the waters here have a story to share, a spectacle to showcase. So, the next time you're in Tenerife, make sure to add La Vista Beach to your itinerary. Dive in, explore, and be enchanted by the wonders of the deep blue.</p>
     <p>Thinking of embarking on this underwater journey? <a href="/packages_from_scuba_point_tenerife">Book your dive today!</a>.</p>
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot1handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-2" onClick={DiveSpot2handleShow}>
    <span>2</span>
   </div>
   <Modal
    keyboard={false}
    show={DiveSpot2Modal}
    onHide={DiveSpot2handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>The Palm Mar Wall</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Pal Mar Wall: Tenerife's Hidden Underwater Gem</h2>
     <img src={Pall1} className="img-fluid" alt="Pall Mar Wall Dive spot Cliff" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item"> <h5>🚩🏁🏳 <strong>Depth:</strong> 20m</h5></li>
      <li className="list-group-item"> <h5>🍳😅❄ <strong>Temp:</strong> 19 degrees all year round baby!</h5></li>
      <li className="list-group-item"> <h5>🐡🐠🐟 <strong>Creatures:</strong> Teeming with wild life! Dolphins, Turtles, rays, Octopus, you name it!</h5></li>
      <li className="list-group-item"> <h5>👀👀👀  <strong>Visibility:</strong> Very good most days</h5></li>
      <li className="list-group-item"> <h5>👁👅🧠  <strong>Things to see:</strong> Be amazined by all the creatures swimming around you.</h5></li>
     </ul>
     <h5>Marine life</h5>
     <p>Prepare your scuba gear for a spectacular adventure as we take a boat ride to the enthralling Pal Mar Wall, Tenerife's little-known underwater treasure. This dive site, snuggled just off the quaint community of Palm Mar, is designed by Mother Nature herself to be a marine wonderland. The wall's distinctive horseshoe shape carves out countless crevices and hidey-holes, making it a giant game of hide-and-seek for the marine life and an explorer's paradise for us divers.</p>
     <img src={Pall2} className="img-fluid mb-4" alt="Pall Mar dive site  in Tenerife" />
     <h5>Proximity</h5>
     <p>It is accessed by boat from the port of Los Cristianos. The proximity to local fish farms has turned Pal Mar Wall into the hottest dining spot in town for roncadores and bream, which are often spotted enjoying their meals. The area's water temperature, which ranges from a cool 17C/63F in February to a warm 24C/75F in August, makes it an all-season diving destination. And hey, did I mention the drift diving? Nothing quite matches the thrill of drifting along with the current, letting the ocean guide your path.</p>
     <h5>Local jokers</h5>
     <p>But the real crowd-pleasers here are the larger residents. Dolphins, those jovial jesters of the sea, are known to make an appearance, their playful antics adding an extra dash of joy to the dive. You can also spot barracudas, the lone wolves of the ocean, silently stalking their prey. And don't forget the stingrays, gliding gracefully along the ocean floor like underwater birds in flight. The wall's vibrant inhabitants also include the colorful rainbow wrasse and the enigmatic blue fin damsel fish.</p>
     <h5>Conclusion</h5>
     <p>So, buckle up, divers! The <a href="https://www.google.com/search?rlz=1C5GCEM_en&sxsrf=AB5stBi_Xot4gnPyODgiCK4qBK6NM2BBAA:1689951859545&q=palm+mar+wall+diving+spot&tbm=isch&sa=X&ved=2ahUKEwj-65miiaCAAxW5SKQEHfXqDZYQ0pQJegQIChAB&biw=1920&bih=863&dpr=1#imgrc=fgjIUFy0T5ukdM">Palm Mar Wall</a> in Tenerife promises an underwater extravaganza like no other. Dive in, and let the party begin!</p>
     <p>Thinking of embarking on this underwater journey? <a href="/packages_from_scuba_point_tenerife">Book your dive today!</a>.</p>
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot2handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-3" onClick={DiveSpot3handleShow}>
    <span>3</span>
   </div>
   <Modal
    keyboard={false}
    show={DiveSpot3Modal}
    onHide={DiveSpot3handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'> Ali Baba Cave </h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>The Mysterious Ali Baba Cave of Palm Mar</h2>

     <img src={Cave1} className="img-fluid" alt="Palm Mar Cave" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item"> <h5>🚩🏁🏳 <strong>Depth:</strong> 30m</h5></li>
      <li className="list-group-item"> <h5>🍳😅❄ <strong>Temp:</strong> 19 degrees all year round baby!</h5></li>
      <li className="list-group-item"> <h5>🐡🐠🐟 <strong>Creatures:</strong>
       Bursting with vibrant marine life! From playful dolphins and majestic turtles to graceful rays and elusive octopuses - the ocean's wonders await!</h5></li>
      <li className="list-group-item"> <h5>👀👀👀  <strong>Visibility:</strong> Very good most days</h5></li>
      <li className="list-group-item"> <h5>👁👅🧠  <strong>Things to see:</strong>Pay reverance to the shrines.</h5></li>
     </ul>
     <h5>Location</h5>
     <p>Just when you thought the waters of Tenerife had shown you all their wonders, the Palm Mar Cave, often whispered about as the Ali Baba Cave, beckons with its aura of mystery and reverence. Situated a mere 300 meters from the shores of the charming town of Palm-Mar, in Arona's embrace, this underwater cavern holds tales that resonate with both awe and somberness.</p>
     <h5>In the cave</h5>
     <p>Delving into the cave, which nestles at a depth of about 30 meters, is like stepping into nature's grand chamber. Its walls, sculpted by time and tide, bear the silent witness to countless epochs and the myriad secrets of the ocean deep. The play of light and shadow within the cave paints a picture that's equal parts enchanting and eerie. Each crevice and curve seems to whisper tales from the abyss.</p>
     <h5>Reverance</h5>
     <p>Yet, amidst the cave's natural splendor lies a poignant reminder of the unpredictability of the ocean. Emblems, now weathered by time, are respectfully placed within the cave. They serve as silent tributes to the brave divers who, in their pursuit of underwater beauty, met their untimely end within the cave's embrace. These emblems not only immortalize their spirit but also underscore the cave's dual nature – a site of unparalleled beauty that also demands respect and caution.</p>
     <h5>Conclusion</h5>
     <p>Today, the <a href="https://tenerife-underwater.com/ali-baba-cave">Ali Baba Cave</a> stands as a testament to the ocean's unyielding power and the indomitable spirit of adventurers. A dive here is not just about witnessing the marvels of the underwater world; it's a journey through time, emotion, and reverence. So, as you prepare to explore this cavernous wonder, remember to tread with both curiosity and care, honoring both its natural majesty and the memories it safeguards.</p>
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot3handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-4" onClick={DiveSpot4handleShow}>
    <span>4</span>
   </div>
   <Modal
    keyboard={false}
    show={DiveSpot4Modal}
    onHide={DiveSpot4handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Enter and exit through a cave!</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>

     <h2>THE NEPTUNE CAVE</h2>
     <img src={Puerto1} className="img-fluid" alt="Puerto de la Cruz dive site  in Tenerife" />


     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item"> <h5>🌊📏 <strong>Depth:</strong> A cool 18 meters down.</h5></li>
      <li className="list-group-item"> <h5>🌡🏊‍♂️ <strong>Temperature:</strong> A consistent 19°C all year round. Perfect diving temps!</h5></li>
      <li className="list-group-item"> <h5>🐢🦑🦐 <strong>Marine Life:</strong> Lobsters, prawn and shrimp</h5></li>
      <li className="list-group-item"> <h5>🔍🌅 <strong>Visibility:</strong> Crystal clear on most days.</h5></li>
      <li className="list-group-item"> <h5>🌐🙏 <strong>Highlights:</strong> Navigate through a cave to a new exit!</h5></li>
     </ul>
     <h5>Location</h5>
     <p>Positioned just 20 minutes south of Tenerife, and a short swim away from the iconic Punta Rasca lighthouse, lies the enchanting Neptune Cave. This mesmerizing dive spot promises an unparalleled subaquatic experience for divers.</p>
     <h5>Features</h5>
     <p>One of the standout features of the Cueva Neptuno is its welcoming embrace. Even as you venture inside, the daylight continues to touch every nook and cranny, ensuring that divers always have a clear vision of their surroundings. This natural illumination makes the cave not only a sight to behold but also ensures that every dive here is both secure and straightforward.</p>
     <img src={Puerto2} className="img-fluid mb-4" alt="Puerto de la Cruz diving point in Tenerife" />
     <h5>Crustacious crabby cave </h5>
     <p>At a depth of approximately 18 meters, the Neptune Cave is a sanctuary for a myriad of marine life. From the elusive grouper lobsters to the captivating catalufas, from the elegant trumpet fish to the industrious cleaner prawns and shrimps, every turn inside the cave offers a new spectacle. And if you're lucky, you might even spot the rare Canarian Norway lobster making its way through the crevices.</p>
     <img src={Puerto3} className="img-fluid mb-4" alt="Puerto de la Cruz diving point in Tenerife" />
     <h5>Cave navigation</h5>
     <p>Upon exiting the cave, divers are greeted with a sandy expanse dotted with rocky ridges. These natural formations create the perfect setting for a serene drift dive, taking you further south and deeper into the mysteries of Tenerife's underwater realm.</p>
     <h5>Conclusion</h5>
     <p>Experience the magic of The Neptune Cave, a must-visit scuba diving site in southern Tenerife.</p>
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot4handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-5" onClick={DiveSpot5handleShow}>
    <span>5</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot5Modal}
    onHide={DiveSpot5handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Cruz San Miguel</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Cruzing for a water viewing</h2>
     <img src={Cruz1} className="img-fluid" alt="Cruz San Miguel dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📉 <strong>Depth:</strong> Dive into the deep end, but not too deep, at 18 meters. Deep enough to escape those pesky Wi-Fi signals! 📵</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🤽‍♂️ <strong>Temperature:</strong> Always a comfortable 19°C. It's like Mother Nature's own heated pool, without the chlorine smell!</h5>
      </li>
      <li className="list-group-item">
       <h5>🦞🍤🎉 <strong>Marine Life:</strong> A seafood lover's dream with lobsters, prawn, and shrimp. Just remember, it's not a buffet, it's a dive site! 🙌</h5>
      </li>
      <li className="list-group-item">
       <h5>🔍🌊 <strong>Visibility:</strong> So clear, you might think you're still on land. Perfect for spotting that lost pair of sunglasses... or a flipper.</h5>
      </li>
      <li className="list-group-item">
       <h5>🏞️🚪 <strong>Highlights:</strong> Dive into Narnia as you navigate through a cave and pop out a whole new exit! Surprise! 🎊</h5>
      </li>
     </ul>

     <h5>🤿💦 <strong>Dive Alert for the Pros!</strong> 🚀🌊</h5>
     <p>Hey there, diving aficionados! Looking for a dive that's not just deep, but also deep-ly amusing? Head south in Tenerife! But remember, this one’s for those who've been around the ocean block a few times! 🌍🔄</p>
     <img src={Cruz2} className="img-fluid mb-4" alt="Cruz San Miguel dive site  in Tenerife" />
     <h5>Diving there</h5>
     <p>The dive starts with a good ol' splashdown from Los Cristianos or Las Galletas, aiming straight for La Punta de Rasca. 📍 Once you're 28 meters down (holding onto a cable like it's your mom's hand on the first day of school 🎒), brace yourself for some currents that are so strong, they could rival your morning coffee! ☕💪</p>
     <h5>Animals</h5>
     <p>But the true spectacle? An arch at 40 meters deep, cut out in volcanic rock. It's not just an arch, it's the gateway to the ocean's VIP lounge! 🌌🚪 And guess what’s hanging there? Black coral! It's like the ocean's version of fancy drapes. Dive deeper to find a cozy sandy patch, and if you're lucky, you might spot some butterfly rays and angelfish just chillin'. 🦋🐟</p>
     <h5>Barracudas</h5>
     <p>Oh, and speaking of chilling... ever seen a grouper so huge it might have its own gravitational pull? 🪐 Or a European barracuda school so big, it makes your high school look like preschool? 🏫🐟</p>
     <h5>Conclusion</h5>
     <p>Pack your gear, your experience, and your best fishy dad jokes. Why did the fish stay in school? So it wouldn’t be caught! 🎣😂 Dive in, and maybe you'll find the punchline underwater!</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot5handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-6" onClick={DiveSpot6handleShow}>
    <span>6</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot6Modal}
    onHide={DiveSpot6handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>A sassy sunken WW2 ship!</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>El Meridian Wreck Dive Site: Dive Into History!</h2>

     <img src={Meridian1} className="img-fluid" alt="Meridian sunken ship in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item"> <h5>🌊📏🐠 <strong>Depth:</strong> A cool 30m - She's deeper than most of my philosophical conversations!</h5></li>
      <li className="list-group-item"> <h5>🌞🔥❄️ <strong>Temp:</strong> 19 degrees all year. So consistent it puts my morning routine to shame!</h5></li>
      <li className="list-group-item"> <h5>🦐🐡🦑 <strong>Creatures:</strong> Packed with more marine celebs than a Hollywood party! Dolphins doing the cha-cha, turtles taking it slow, rays showing off their elegance, and octopuses playing hide-and-seek. What's not to love?</h5></li>
      <li className="list-group-item"> <h5>🤿👁️🌟 <strong>Visibility:</strong> Crystal clear on most days - a better view than my future!</h5></li>
      <li className="list-group-item"> <h5>🚢🗺️💭 <strong>Things to see:</strong> Wonder around the sunken ship!!</h5></li>
     </ul>

     <p><strong>Vessel Name:</strong> El Meridian (also known as "The Old KFK 469" but she's sensitive about the "old" part! 😉)</p>

     <p><strong>Origin:</strong> Born in Germany, raised as a KFK kriegsfischkutter. But, she always had dreams of being a superstar dolphin watcher! 🐬</p>

     <p><strong>Quick Stats:</strong></p>
     <ul>
      <li><strong>Dimensions:</strong> A sassy 24.5 meters long and a curvy 6.4 meters wide. She's been trying yoga recently. 🧘</li>
      <li><strong>Final Rest:</strong> Took the ultimate plunge on 23 October 2005. It wasn't a mid-life crisis; she just wanted a change of scenery! 🌊</li>
      <li><strong>Previous Life:</strong> Before her splashdown, she was the "it boat" for whale and dolphin spotting in Tenerife. 🐳</li>
      <li><strong>Birthplace:</strong> Crafted with German precision at Burmester Swinemunde Ost back in 1944. She doesn't look a day over 30 though, right? 😄</li>
     </ul>
     <h5>Why She's Chilling Below:</h5>
     <p> Thought being an artificial reef sounded cool. Plus, she heard the underwater parties were legendary! 🎉</p>
     <img src={Meridian2} className="img-fluid mb-4" alt="Meridian sunken ship in Tenerife" />
     <h5>Her Hangout Spot?</h5>
     <p>Just chilling near Palm Mar, Tenerife, at a relaxed depth of 30 meters. Drop by for a visit, she loves company!</p>
     <h5>Did You Know?</h5>
     <p> Back in World War II, she was the diva of the sea, known as the KFK 469. Some even say she played a role in a few big sea dramas. Star-studded history, right? 🌟</p>
     <h5>Got Gossip or Fun Facts?</h5>
     <p>Spill the beans! We're all ears (and fins) for more juicy details about our star, El Meridian. And if you're looking to explore more, dive right into our Wrecks Mapping Projects! 🕵️‍♂️🌐</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot6handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-7" onClick={DiveSpot7handleShow}>
    <span>7</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot7Modal}
    onHide={DiveSpot7handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>El Condesito ship wreck diving site</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>A Wreck Dive Site: Dive Into History!</h2>

     <img src={Condesito1} className="img-fluid" alt="Condesito sunken ship in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏📚 <strong>Depth:</strong> Ranges from a casual 6m/20ft to an "I-need-a-snack-break" 21m/69ft. Perfect for contemplating the mysteries of the ocean (and why socks disappear in the laundry).</h5>
      </li>
      <li className="list-group-item">
       <h5>🌞🔥❄️ <strong>Temp:</strong> Averages around 19°C, but heats up to a toasty 24°C in the summer. It's like nature's own Jacuzzi... just without the bubbles.</h5>
      </li>
      <li className="list-group-item">
       <h5>🦐🐬🎉 <strong>Creatures:</strong> The place is buzzing! Trumpet fish hosting karaoke nights, morays peeking out for the latest gossip, and barracudas just trying to fit in. Dive in and join the party!</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👓🌞 <strong>Visibility:</strong> Up to a whopping 35m/115ft. So clear, you might spot that missing sock from earlier!</h5>
      </li>
      <li className="list-group-item">
       <h5>🚢🏴‍☠️🗺️ <strong>Things to see:</strong> The legendary shipwreck itself! But remember, no treasure hunting - we're here for the views!</h5>
      </li>
     </ul>
     <h5>Drunken mandem crew</h5>
     <p>🚢 Well this is a funny one, rumor has it the crew got drunk on the stocked alcohol, hit the reef and sunk it! Ask Gonza about it, he knows all about these types of events, he usually attends them! 🎉</p>
     <h5>The legend</h5>
     <p>🚢 Ahoy there! Ever heard of the legendary El Condesito? Nope, it's not a spicy taco, but rather a ship that had a bit too much fun. This party-loving ship danced a bit too hard with a rock during a wild storm in 1972 near Las Galletas. 🎉⛈️</p>
     <h5>About this dive</h5>
     <p>💃 You might think it's just chilling in some shallow kiddie pool, but don't be fooled. She's lounging at depths that range from a cool 6m/20ft to a more "I-can't-see-the-surface" 21m/69ft. And let me tell you, the currents here? They've got more swirls than my morning coffee! ☕ So if you're a newbie, you might want to get a few more dives under your belt before attempting this one. 🌪️🏊</p>
     <h5>Creatures</h5>
     <p>🐠 But oh, the sights you'll see! We're talking the who's who of the underwater world: dolphins doing the moonwalk, trumpet fish belting out tunes, morays giving side-eyes, and octopuses throwing the best ink parties. 🦑🎶</p>
     <img src={Condesito2} className="img-fluid mb-4" alt="Condesito sunken ship in Tenerife" />
     <h5>Temperature</h5>
     <p>🌡️ As for the temps, think of a beach day in August with a sweet 29C/84F in the air and a pleasant 24C/75F in the water. But come February, you'll want to snuggle up with the fishies at a crisp 19C/66F. 🌊❄️</p>
     <h5>Best Times to Dive</h5>
     <p><strong>Wondering about the best times to dive "El Condesito"? 📅</strong></p>
     <p>Here's a cheeky breakdown for you:</p>
     <ul>
      <li>March: 20℃ air, 19℃ water, 25m visibility, and a current speedier than my last relationship at 1 knot.</li>
      <li>April: 20℃ air, 18℃ water, 25m visibility, and currents that'll give you a wild hairdo at 3 knots.</li>
      <li>August: 32℃ air (hot hot hot!), 22℃ water, 25m visibility, and lazy currents of 0-1 knots.</li>
      <li>October: 25℃ air, 23℃ water, 25m visibility, and a calm current at 0 knots, perfect for spotting those elusive marine critters.</li>
      <li>November: 24℃ air, 23℃ water, 25m visibility, and a zen-like current at 0 knots.</li>
     </ul>
     <p>Now, that's based on historical data, gossiping fish, and a sea turtle named Dave. So it's pretty accurate, if you ask me! 🐢📈</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot7handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-8" onClick={DiveSpot8handleShow}>
    <span>8</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot8Modal}
    onHide={DiveSpot8handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Roncadores Del Faro</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Weird volcanic rock formations, just for you!</h2>

     <img src={Roncadores1} className="img-fluid" alt="Roncadores Del Faro in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🎢 <strong>Depth:</strong> From a kiddie pool depth of 4m to a "Did I remember to feed my goldfish?" depth of 18m. The perfect spot for contemplating if fish also get thirsty.</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🔥❄️ <strong>Temp:</strong> Stays around a comfy 19°C but can spike up to 24°C in summers. It's nature's way of saying, "Who needs a spa day?"</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🕺💃 <strong>Creatures:</strong> It's like the Oscars down here! Barracudas doing the red carpet walk, trumpet fish blowing their own horns, and octopuses trying to figure out which tentacle to shake first. A marine fiesta at Roncadores Del Faro!</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👀🔭 <strong>Visibility:</strong> Crystal clear up to 35m. So transparent, you might just see tomorrow's weather forecast.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🏰🚢 <strong>Things to see:</strong> Not just your average underwater scenery! We've got nature-carved caves and tunnels here, perfect for an episode of "Underwater Cribs: Roncadores Del Faro Edition".</h5>
      </li>
     </ul>
     <h5>Greetings friend!</h5>
     <p>🏊‍♂️ Welcome to <strong>Roncadores Del Faro</strong>! Beginning our underwater journey at a chill 4 meters, you'd think we're just dipping our toes. But hold onto your snorkels, because just a few fancy fin strokes away lies a dramatic ocean cliff that'll take you straight down to a mysterious 18 meters! 🌊</p>
     <h5>Off the wall bro!</h5>
     <p>🌋 The walls here at Roncadores Del Faro have a certain... swagger. Made of diva-esque basalt stone, they could easily pass off as the works of some renowned architect. But it’s all Mother Nature’s doing! When she let water play it cool with the fiery lava, what emerged was a masterpiece of caves and tunnels. Probably the ocean's version of penthouses! 🛠️🎨</p>
     <img src={Roncadores2} className="img-fluid mb-4" alt="Roncadores Del Faro in Tenerife" />
     <h5>Dive Deeper</h5>
     <p>🐠 Dive deeper and you’ll find the ocean’s A-listers partying away. Massive gatherings of yellowfin grunts gossiping away, barracudas making their starry appearances, trumpet fish trying their best to act casual (and failing), and if you’ve got the eagle eyes, you might spot an octopus playing hide-and-seek or an angel shark just chilling. All this and more, only at <strong>Roncadores Del Faro</strong>! 🎉🐙🦈</p>


    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot8handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-9" onClick={DiveSpot9handleShow}>
    <span>9</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot9Modal}
    onHide={DiveSpot9handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Los Chuchos</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Chuchos Muchachos!</h2>

     <img src={Chuchos1} className="img-fluid" alt="Los Chuchos diving in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🎢 <strong>Depth:</strong> Dips from a "just-wetting-my-toes" 15m to a thrilling "I-can-almost-see-a-mermaid" 22m. Perfect for wondering, "Do fish ever get the hiccups?"</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🔥❄️ <strong>Temp:</strong> Consistently around 19°C, but can feel like a warm embrace at 24°C during those summer dives. It's like Mother Nature's cozy blanket.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎸🎤 <strong>Creatures:</strong> It's the underwater Coachella here! Stingrays strutting their stuff, moray eels grooving in their rocky homes, and the occasional shrimp photo-bombing your dive selfies. Los Chuchos is where the marine party's at!</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👀🌌 <strong>Visibility:</strong> So clear, you might just spot the twinkle in a dolphin's eye from 15m away. Who knew the ocean had its own brand of HD?</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🎭🚤 <strong>Things to see:</strong> Beyond the fishy celebrities and the swaying sea plants, there's a sunken fishing boat waiting to tell its tale. And if you're lucky, a stingray</h5>
      </li>
     </ul>
     <h5>Greetings friend!</h5>
     <p>🛥️⚓ Welcome to the <strong>Los Chuchos Dive Site</strong>! Just a hop, skip, and a two-minute boat ride away from the port of Las Galletas, this under-the-sea city is also known by its fancy alias, "El Estorn", or the more informal "City of the Chuchos". Think of it as the underwater version of New York City, but with less traffic and more fins.</p>
     <img src={Chuchos2} className="img-fluid mb-4" alt="Los Chuchos diving in Tenerife" />
     <h5>Descent</h5>
     <p>🏊🌊 As we anchor at a cool 16 meters, prepare for a descent into an underwater wonderland. And for those thrill-seekers, this place is a bit deeper, making it perfect for the adventurous souls.</p>
     <h5>Navigation</h5>
     <p>🗺️🐟 Get ready to navigate through overhanging ledges, peek into small caves (no dragons here, we promise), and even bump into a sunken fishing boat that's now a fish apartment complex. Depths range from a chill 15 meters to a "I can't believe it's 22 meters" depth.</p>
     <h5>The locals</h5>
     <p>🐡🐠 As for the marine locals? Oh, they're a friendly bunch! Especially the star of the show - the rays. Don't be surprised if you find some moray eels lounging around, probably discussing the latest underwater gossip.</p>
     <h5>Surroundings</h5>
     <p>🌴🍹 To cap it off, each dive into Los Chuchos feels like a brand-new episode of "Underwater Explorer". And as you surface and approach the coast, take a moment to appreciate the tantalizing aromas wafting from the restaurants of this quaint town. Talk about surf and turf!</p>

     <h5><strong>Diving Summary for Los Chuchos:</strong></h5>
     <ul>
      <li>🛥️ Type: Boat dive, because why walk when you can sail?</li>
      <li>🤿 Suitability: All types of divers - rookies to pros.</li>
      <li>💨 Breath: Air and nitrox. Breathe in, dive deep!</li>
      <li>🌊 Current Status: Rarely there, occasionally moderate. Kinda like my motivation on Mondays.</li>
      <li>📏 Depth: Between a relaxing 15 and a thrilling 22 meters. Variety is the spice of diving life!</li>
     </ul>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot9handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-10" onClick={DiveSpot10handleShow}>
    <span>10</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot10Modal}
    onHide={DiveSpot10handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Los Champinones</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Los Mushroom vibes!</h2>

     <img src={Champ1} className="img-fluid" alt="Los Champinones dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🍄 <strong>Depth:</strong> From a "I'm-just-testing-the-waters" 15m to a dramatic "I-think-I-just-high-fived-a-mermaid" 30m. Ever wondered if fish have a favorite depth? 🤔</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞❄️ <strong>Temp:</strong> Steady as she goes at 19°C, but sometimes the ocean decides to turn up the heat to 24°C. It's like the sea's version of mood swings.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎉🍹 <strong>Creatures:</strong> Think of it as the ocean's VIP lounge! With rockstar barracudas, lounge singer morays, and octopuses doing the cha-cha. And remember, what happens in "El Mushroom", stays in "El Mushroom".</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👓🔮 <strong>Visibility:</strong> So pristine, you might start believing you've got X-ray vision. If you squint, you can probably see tomorrow's weather.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🍄🕺 <strong>Things to see:</strong> Besides the aquatic party animals, there's the legendary mushroom-shaped rocks. And rumor has it, they throw the best shade in the ocean. Dive in and find out!</h5>
      </li>
     </ul>

     <img src={Champ2} className="img-fluid mb-4" alt="Los Champinones diving point in Tenerife" />
     <h5>Shroomtown</h5>
     <p>🍄 "El Mushroom": No, it's not a trendy new restaurant or the name of a rock band, but a dive site in Las Galletas Bay christened after two mushroom-shaped rocks! These funky fungi twins act like nature's bouncers, shielding us from any unruly currents. 🌊</p>
     <img src={Champ3} className="img-fluid mb-4" alt="Los Champinones diving point in Tenerife" />
     <h5>At the bottom</h5>
     <p>Imagine lounging on a sandy bottom that's a solid 30 meters deep, right where the dramatic cliff waves goodbye. Now, while you *could* exit stage right and head towards "Los Cañones," we opted for the round-trip ticket. The current? On that day, it was like a gentle nudge, politely reminding us to move. But, here's a secret – I once encountered the wildest current of my life right at this spot! 🌀 So, "El Mushroom" can sometimes be... let's call it "spicy". 🌶️😉</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot10handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-11" onClick={DiveSpot11handleShow}>
    <span>11</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot11Modal}
    onHide={DiveSpot11handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Welcome to Paradise!</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Playa Paraiso</h2>
     <img src={Paraiso1} className="img-fluid" alt="Los Champinones dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🌴 <strong>Depth:</strong> Starts at a "just-getting-my-feet-wet" 4m and plunges to a "Did-I-just-bump-into-Atlantis?" 25m. Ever thought about the fishy version of mountain climbers down there? 🐠🏔️</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🍦 <strong>Temp:</strong> Chills at 19°C in the winter, but by summer? It’s like the ocean's got its own sunbathing schedule at 24°C. Bring out the suntan (or wetsuit)!</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎸🎤 <strong>Creatures:</strong> The under-the-sea club is lit! 🎉 Rock-n-roll crabs, octopuses busting dance moves, and shrimp that just can't stop grooving. It's the oceanic Coachella, and you're on the guest list!</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🔭🎆 <strong>Visibility:</strong> So crystal clear, you might just catch a fish taking a selfie! 📸 You're not just diving; you're time-traveling to the ocean's Renaissance.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🎢🏰 <strong>Things to see:</strong> Giant volcanic boulders that look suspiciously like ancient fish art. And who knows, you might stumble upon the ocean's version of a coffee shop in one of those nooks. ☕️🐟</h5>
      </li>
     </ul>
     <img src={Paraiso2} className="img-fluid mb-4" alt="Playa Paraiso dive site  in Tenerife" />
     <h5>Surroundings</h5>
     <p>🏖️🐠 At the Playa Paraiso dive site, it's not just another beach day! Dive into an underwater game of hide and seek with colossal volcanic boulders serving as nature's version of hidey-holes. 🌋</p>
     <img src={Paraiso3} className="img-fluid mb-4" alt="Playa Paraiso dive site  in Tenerife" />
     <h5>Down below</h5>
     <p>🔍 As you explore the many rock nooks and sneaky crannies, be ready to bump into marine introverts - from shifty crabs and secretive shrimp to the elusive octopus playing peek-a-boo. And hey, if you're into sandcastles, the sandy floor at 25m/82ft down is the place to be! Not to build, but to spot the marine architects - flatfish and rays - that have made their burrowed homes there. 🦑🦀</p>
     <img src={Paraiso4} className="img-fluid mb-4" alt="Playa Paraiso dive site  in Tenerife" />
     <h5>Temperature</h5>
     <p>❄️💧 Feeling the chill? Well, the water might get a bit nippy at 19C/66F in winter, but come summer, it's like the ocean's got its own warm hug at 24C/75F. So, ready to party with the underwater locals? They're waiting all year round for you at Playa Paraiso! 🎉🌊</p>


    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot11handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-12" onClick={DiveSpot12handleShow}>
    <span>12</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot12Modal}
    onHide={DiveSpot12handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>A bit further up...</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>📍 <strong>Dive Site: Abades</strong></h2>

     <img src={Abades1} className="img-fluid" alt="Abades dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🚀 <strong>Depth:</strong> From "Just dipping my toes" 5m to an "I'm-pretty-sure-that-was-Ariel's-cousin" 16m. If fish had bedrooms, I bet they'd pick somewhere in between. 🐠💤</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞❄️ <strong>Temp:</strong> Consistent 19°C, but sometimes the ocean cranks the thermostat up to a sizzling 24°C. It's like the sea's saucy summer fling.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎸🎤 <strong>Creatures:</strong> Welcome to the Marine Met Gala! Rays showing off their elegant glide, morays doing dramatic solos, and octopuses giving salsa lessons. But remember, what happens in Abades, trends in Abades. 🕺💃</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👓🌌 <strong>Visibility:</strong> So crystal clear, you'd swear you've acquired superpowers. Keep an eye out, you might just spot a mermaid's diary.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌱🎉 <strong>Things to see:</strong> Aside from the marine blockbuster cast, you've got reefs that put most city skylines to shame. And if you're lucky, an Angel Shark might just photobomb your selfie.</h5>
      </li>
     </ul>


     <img src={Abades2} className="img-fluid mb-4" alt="Abades diving point in Tenerife" />
     <h5>The town</h5>
     <p>Ever thought of a seaside town where fish rule, and boats with noisy engines are a big no-no? 🚫🚤 Welcome to Abades! This former fisherman's paradise along the south-east coast is now the underwater Hollywood of the Canaries. Why, you ask? Well, all the marine A-listers come here to start their families. From dashing rays to the ever-elusive Angel Shark, who apparently thinks of Abades as the perfect nursery. 🦈🍼</p>
     <img src={Abades3} className="img-fluid mb-4" alt="Abades diving point in Tenerife" />
     <h5>Descent</h5>
     <p>With a depth that doesn't go beyond a "I-can-still-see-my-toes" 16 meters, Abades is a comfortable dive spot for all. Whether you're a newbie just getting your fins wet or a pro with a camera trying to capture that perfect Angel Shark pout, this place has got you covered. 📸🐟</p>
     <h5>Creatures bobbing around</h5>
     <p>It’s a reef party here! Two jam-packed reefs and lush seagrass beds so comfy, our local turtles swear by them for their afternoon siestas. 🐢💤</p>
     <h5>Sharks (small ones!)</h5>
     <p>And here's the showstopper: Abades is now officially an Angel Shark nursery! Given they're on the endangered list, it's like finding out a unicorn just moved in next door. 🦄🌊</p>
     <p><strong>Abades Dive Summary:</strong></p>
     <ul>
      <li>🌊 Type: Shore dive (because why complicate with boats?)</li>
      <li>🎓 Skill Level: From "Just got my diving license" to "I've seen more fish than people".</li>
      <li>🎈 Gear: All about that air.</li>
      <li>🌬️ Currents: From "Is this even moving?" to "Ah, a gentle breeze".</li>
      <li>📏 Depth: A chill 5m to a "let's get serious" 16m.</li>
     </ul>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot12handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-13" onClick={DiveSpot13handleShow}>
    <span>13</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot13Modal}
    onHide={DiveSpot13handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Welcome to El Poris Boris</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>The lounge deck of Tenerife dives</h2>

     <img src={ElPoris1} className="img-fluid" alt="El Poris dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🎈 <strong>Depth:</strong> From a "Still got phone signal" 20m to a "Oops, lost my floaties" 28m. Wonder if fish down here have penthouses? 🏢</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🏝️❄️ <strong>Temp:</strong> Swinging from a "Need my woolly socks" 18°C in winter to a "Ice cream, anyone?" 23°C in summer. Mother Nature's got the AC just right here.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐠🎭🕶️ <strong>Creatures:</strong> Imagine an underwater Hollywood! We’ve got stingrays signing autographs, octopuses as the eight-armed paparazzi, and barracudas with their ever-so-dramatic entrances. And of course, the angel sharks playing it cool in the background. 🌟</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👀🔦 <strong>Visibility:</strong> So clear, you'll feel like you’ve just stepped into an HD documentary. Rumor has it, fish here have started their own reality show.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌋🎨 <strong>Things to see:</strong> Beyond the marine red carpet, the sculptures here are nature-made. Think of the volcanic rock formations as Earth's attempt at modern art. And for those who like a bit of adrenaline? The currents occasionally throw in a surprise twist!</h5>
      </li>
     </ul>
     <h5>Cute little town</h5>
     <p>🏖️ Welcome to El Poris, the underwater equivalent of a chill lounge bar. It's shallow, calm, and draped in soft corals that are the envy of other dive sites. Think of the volcanic rock formations as nature's own art installations, but don't touch, the museum policy applies underwater too!</p>
     <h5>Cave man time</h5>
     <p>🕳️ Fancy a bit of cave exploration? Dive right in! But beware, while most of the water here is as calm as a zen monk, there are some deeper parts that occasionally have mood swings with strong streams. So, maybe avoid those if you're not feeling too adventurous.</p>
     <h5>Inhabitants</h5>
     <p>🐠🐟🦑 For the marine life enthusiasts, it's a total jackpot! You've got ballan wrasses putting on a show, damselfish playing hard to get, octopuses being their usual mysterious selves, electric rays that don't need charging, and so much more! Oh, and if you’re lucky, you might even get photobombed by a barracuda or greeted by a chilled-out turtle.</p>




     <img src={ElPoris2} className="img-fluid mb-4" alt="El Poris diving point in Tenerife" />
     <h5>For all types</h5>
     <p>🤿 Dive in straight from the shore, no fancy boat trips needed. Whether you're a rookie diver or a seasoned pro, El Poris rolls out the red carpet for all. The views? Crystal clear up to 30m/98ft. As for currents, they're mostly mild-mannered, but occasionally they like to show off with speeds of 1-2 knots.</p>
     <h5>Night dives</h5>
     <p>🌚 Love diving under the moonlight? Or fancy practicing your underwater photography skills? Perhaps you're just in for some snorkeling? El Poris is the place to be! Best time to visit? May to October. But here’s a tip: pack a wetsuit because the water in winter can feel like a chilly hug, at 18C/64F. Summer? It's like the ocean's personal sauna at 23C/73F.</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot13handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-14" onClick={DiveSpot14handleShow}>
    <span>14</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot14Modal}
    onHide={DiveSpot14handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Las Eras</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Great eco dive vibes!</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <img src={LasEras1} className="img-fluid" alt="Las Eras dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🕳️ <strong>Depth:</strong> From a "Is-this-a-puddle?" 5m to a jaw-dropping "Wait, is that Atlantis?" 28m. Do fish also worry about depth charges on their credit cards? 🤔</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞⛄ <strong>Temp:</strong> It keeps it cool at 18°C in winters, but come summer and it's a sizzlin' 23°C. Ocean's personal thermostat game is on point.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎤🎸 <strong>Creatures:</strong> Welcome to the marine Broadway! We've got octopuses belting high notes, barracudas playing electric guitars, and angel sharks doing the tango. Las Eras' got talent!</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👓🚀 <strong>Visibility:</strong> Clearer than a freshly cleaned window on a sunny day - up to 30m. So clear, you might just spot Nemo if you look closely.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌋🎭 <strong>Things to see:</strong> Apart from the marine A-listers, there's a dramatic rocky drop and a show-stopping cave that deserves its own standing ovation. Dive in, the performance is about to begin!</h5>
      </li>
     </ul>

     <img src={LasEras2} className="img-fluid mb-4" alt="Las Eras diving point in Tenerife" />
     <h5>Cute spot</h5>
     <p>🤿🌊 Welcome to <strong>Las Eras</strong> - where the marine celebrities hang out and the caves have a secret whisper! Just imagine taking a breezy swim through a fabulous cave, with the ocean sharing its secrets in your ear. 🦑🐟</p>
     <h5>Location</h5>
     <p>Located near the quaint town of Las Eras, this dive site rocks a fabulous rocky point that drops all the way to 18m/59ft. And if you're thinking this is just any regular underwater spot, think again! From congers having their little conferences, barracudas practicing their best sneer, to octopuses playing peek-a-boo, Las Eras is the underwater version of Hollywood! 🎥🌟</p>


     <img src={Champ3} className="img-fluid mb-4" alt="Los Champinones diving point in Tenerife" />
     <h5>Currents</h5>
     <p>But hey, a little heads up! This spot can sometimes be a tad moody with its currents, kinda like it had too much coffee. So, check the weather, maybe bribe it with a cookie, and dive in when it's feeling friendly! 🍪🌀</p>
     <h5>Tempz</h5>
     <p>Oh, and for the fashion-conscious divers wondering about the temperature - in summers, the waters are a toasty 23°C/73°F. Just perfect to flaunt that new wetsuit! But in winters, it cools down to a chill 18°C/64F, so maybe pack a warm hug? 🌞❄️</p>
     <h5>Times to visit</h5>
     <p>As for the best time to visit? May to October is when Las Eras rolls out the underwater red carpet. But honestly, every dive here feels like an exclusive premiere! 🎬🍿</p>
     <h5>Conclusion</h5>
     <p>And hey, for those who love statistics (you know who you are 🤓): here's a quick snapshot of diving conditions:
      <ul>
       <li>May's Air Temp: 21°C, and the sea's being a bit secretive about its temperature.</li>
       <li>July sees a Visibility of up to 25m. Enough to spot a fishy soap opera unfold!</li>
       <li>August: Air at 22°C, Water at... well, it's keeping it a mystery. Visibility? A crisp 25m. Current? A calm 0 knots. It's like the sea took a chill pill!</li>
       <li>September: Another 25m visibility month with a comfy 23°C air temp.</li>
       <li>November's got the vibes with Air at 25°C, Water at 24°C, a Visibility of 10m, and a slightly caffeinated current at 4 knots.</li>
       <li>December cools things down with Air at 21°C, Water at 21°C, and a Visibility of 10m. Perfect for some winter wonder-dive!</li>
      </ul>
     </p>

     <p>So, ready to dive into the glam world of Las Eras? Don't forget your autograph book, you never know which marine superstar you'll bump into! 🐠🌟</p>



    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot14handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-15" onClick={DiveSpot15handleShow}>
    <span>15</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot15Modal}
    onHide={DiveSpot15handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>El Tablado</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Just lay out the ocean food broseph</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <img src={ElTablado1} className="img-fluid" alt="El Tablado dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🚀 <strong>Depth:</strong> From a "Let's see where this goes" 15m to a "I'm pretty sure I spotted Aquaman's crib" 30m. Fish probably hold secret meetings down there. 🐟🔍</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🌨️ <strong>Temp:</strong> Rock steady at 19°C, but occasionally the ocean cranks up the thermostat to 24°C. The ocean's moodier than my Wi-Fi signal.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎈🎊 <strong>Creatures:</strong> Imagine a marine rave! Groovy cuttlefish, octopuses DJing, and barracudas just vibing to the beat. But remember, what happens in "El Tablado", stays underwater. 🕺💧</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🕶️🌌 <strong>Visibility:</strong> Clearer than my intentions on a first date. If you focus, you might even foresee your next meal.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🏰🎡 <strong>Things to see:</strong> Aside from the marine fest, there's the iconic "El Arco" structure. Word on the reef is, it's the ocean's top model for underwater photoshoots. Strike a pose and dive in!</h5>
      </li>
     </ul>

     <h5>Welcome to the table</h5>
     <p>🌅 Rise and shine, Underwater Adventurers! 🐠 So, we've been hearing whispers, little fishy tales, about a brand new dive spot unveiled right here in Tenerife, and guess where? El Tablado! We just HAD to see for ourselves!</p>
     <h5>Our last visit</h5>
     <p>🚗 After a flawless parking job (if we do say so ourselves), gearing up, and some last-minute pep talks, we faced our first epic challenge: the mighty waves at the beach entrance. Just when we thought we'd timed it perfectly, BOOM! A surprise wave, probably just the ocean's way of saying hello. 😉</p>

     <img src={ElTablado2} className="img-fluid mb-4" alt="El Tablado diving point in Tenerife" />
     <h5>Preparation</h5>
     <p>🌊 After a bit of a wait, spotting our chance, and a deep breath, we plunged in. Once we were in the clear and away from those cheeky waves, the real adventure began. We swam across a vast expanse that looked like a white desert. The currents, being our friendly escorts, swiftly brought us to our destination: the recently christened "El Arco", a stone structure that's basically nature's version of modern art. We explored, posed, snapped a few pics, and wrapped up our dive.</p>
     <h5>Fun stuff</h5>
     <p>🏖️ The real fun? Getting back out. Let's just say the waves wanted a rematch. 😂</p>
     <h5>Location</h5>
     <p>📹 Curious? We've pinned down the <a href="https://www.google.com/maps/place/38591+El+Tablado,+Santa+Cruz+de+Tenerife/@28.2403618,-16.4034821,18z/data=!3m1!4b1!4m6!3m5!1s0xc402f7073ee74e9:0x94bb569904db129f!8m2!3d28.2403199!4d-16.403414!16s%2Fg%2F120tb1hv?entry=ttu">exact spot</a> so dive in, enjoy, and remember: Always make waves! Cheers! 🐙</p>


    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot15handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-16" onClick={DiveSpot16handleShow}>
    <span>16</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot16Modal}
    onHide={DiveSpot16handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>El Tabaiba wreck</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Another sunken gem</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <img src={Tabaiba1} className="img-fluid" alt="El Tabaiba ship wreck dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🚢 <strong>Depth:</strong> It starts with a "Just dipping my fins" 16m, stretching to a "Wait, is that Aquaman's crib?" 30m. Do fish throw depth parties? 🥳</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🌚 <strong>Temp:</strong> Stays cool at 19°C, but occasionally, the ocean thinks it's salsa night and cranks up to 24°C. Ever seen a fish sweat? Me neither.</h5>
      </li>
      <li className="list-group-item">
       <h5>🐠🎶🎤 <strong>Creatures:</strong> Picture this: barracudas doing the moonwalk, morays crooning ballads, and octopuses, well, multitasking. And always remember, what goes down in Tabaiba, becomes fish lore.</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🔍💎 <strong>Visibility:</strong> So crystal clear, you'd think Mother Nature just installed HD underwater. Blink twice, and you might just see a fish's weekend plans.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🚢💡 <strong>Things to see:</strong> Aside from the partying marine life, there's the iconic 'El Peñon' wreck. Rumor says it's the ocean's best-kept secret. Ready to unravel the mystery?</h5>
      </li>
     </ul>

     <h5>intro to the spot</h5>

     <p>🌅 You will find this ship featured on our homepage illustration! Rise and shine, deep-sea enthusiasts! Ever wanted to explore the Titanic but couldn't find it? Well, Tabaiba's got the next best thing!</p>
     <h5>Close to town</h5>
     <p>🏘️ Nestled close to the quaint town of Tabaiba, just a short fin-kick from the shore, lies the famed 'El Peñon'. No, it's not a lost pirate ship, but a 35-metre long vessel that took a planned dive (literally) in 2006. Why? To jazz up the marine scene and give divers like us some bragging rights! 🚢💥</p>

     <img src={Tabaiba2} className="img-fluid mb-4" alt="El Tabaiba ship wreck dive site  in Tenerife" />
     <h5>Descent</h5>
     <p>🤿 As you dive down, you'll notice the boat's got a bit of a lean to the right (30 degrees to starboard, to be exact). Peek inside, and you might just get a surprise hello from Mr. Octopus 🐙, a couple of photogenic moray eels 🐍, and polyps that seem to be hosting their own underwater disco party.</p>
     <h5>Difficulty</h5>
     <p>🌊 Dive difficulty? Let's call it 'moderate with a sprinkle of challenge', especially given its depth. But hey, it's a fab spot for those looking to level-up their wreck diving game. And for those who love a bit of extra dive time, going nitrox is the way to go. More time with the marine peeps? Yes, please! 🕐🐟</p>
     <h5>The journey</h5>
     <p>🏊‍♂️ Sure, the journey to 'El Peñon' might not win any awards, but every so often, a dazzling school of damselfish might just photobomb your dive. And let's not forget the trumpet fish that seem to think they're the local celebs, and the barracudas, mackerel, and amberjack that just love to show off around the wreck.</p>
     <h5>Conclusion</h5>
     <p>📸 So, gear up, dive in, and don't forget to snap some pics (because if it's not on Instagram, did it even happen?). Dive safe, amigos! 🐠🎉</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot16handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>

   <div className="dive-spot-17" onClick={DiveSpot17handleShow}>
    <span>17</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot17Modal}
    onHide={DiveSpot17handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Radazul </h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Great for night dives!</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🐟 <strong>Depth:</strong> Starting from a "Baby steps" 2m to a jaw-dropping "Did I just enter Atlantis?" 40m. Bet even fish need an elevator at these depths! 🛗</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞❄️ <strong>Temp:</strong> Chill at a "Mild winter day" 18°C, but sometimes Mother Ocean cranks up the thermostat to a "Feels like summer" 23°C. Someone get her a fan!</h5>
      </li>
      <li className="list-group-item">
       <h5>🐠🎸🎤 <strong>Creatures:</strong> It's like the ocean's version of Broadway! Starring damselfishes in dramatic roles, barracudas doing stand-up, and octopuses nailing those dance solos. Remember, every dive in Radazul is a ticket to the show! 🎭</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👓🌟 <strong>Visibility:</strong> So clear, you'd think the fish hired a window cleaner. With this clarity, spotting Nemo is child's play! 🐟</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌌🎨 <strong>Things to see:</strong> Apart from the star-studded marine cast, there's an underwater art gallery of rocks and corals. And if you listen closely, even the sardines are whispering secrets of the deep. 🤫</h5>
      </li>
     </ul>
     <h5>Welcome to buff town!</h5>
     <p>🌴 Welcome to Radazul, just a stone's throw (okay, maybe a 13km drive) south of Santa Cruz on the ever-sunny Tenerife Island. Fancy a midnight splash? It's the ideal spot for night dives. 🌌🦉</p>
     <img src={Radazul1} className="img-fluid mb-4" alt="Radazul diving point in Tenerife" />
     <h5>Descent</h5>
     <p>🌊 As you dive in, it starts with a "Just getting my hair wet" depth of 2m, but hold onto your fins! It plunges to an "I'm-in-another-realm" depth of 40m. Perfect for those who like their dives like they like their movie plots - deep and mysterious. 🎬</p>
     <img src={Radazul2} className="img-fluid mb-4" alt="Radazul diving point in Tenerife" />
     <h5>All year round geezer</h5>
     <p>🌡️ Thinking of packing your bags? You can dive here all year round! Though May to October is when the ocean throws its best parties. Expect winter air temps between "light jacket" 17°C and "still need a jacket" 22°C. Summers, on the other hand, range from a "perfect tan" 26°C to "don't forget sunscreen" 30°C. As for the water, it's a comfy 23°C in July (think lukewarm bath) and a slightly chillier 18°C in January (think refreshing shower). 🛁</p>
     <h5>At the bottom</h5>
     <p>🐠🔍 Down below, it's not just rocks and sand. It's like the underwater version of a bustling city square! From damselfishes gossiping about the latest ocean trends, sardines forming flash mobs, to barracudas chilling like they own the place. And if you're lucky, you might even spot an octopus or two playing hide-and-seek! 🐙</p>
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot17handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-18" onClick={DiveSpot18handleShow}>
    <span>18</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot18Modal}
    onHide={DiveSpot18handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Puerto de la Cruz</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Another beginner's paradise similar to La Vista (1)!</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <img src={Cave2} className="img-fluid" alt="Puerto de la Cruz" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🏝️ <strong>Depth:</strong> Ranging from a casual "Let's just paddle" 18m to a "Did I just bump into Aquaman?" 30m. Ever think fish might just be organizing depth-themed parties down there? 🎈</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🍦 <strong>Temp:</strong> Consistently chill at 19°C, but sometimes the ocean gets the fever and cranks it up to a toasty 24°C. Mother Nature's unpredictable thermostat, anyone?</h5>
      </li>
      <li className="list-group-item">
       <h5>🐠🎤🎸 <strong>Creatures:</strong> Picture an undersea Coachella! Featuring headliners like the barracudas, backup vocals by the morays, and octopuses dropping sick dance moves. And trust me, what goes down in "Puerto Party Central" is the stuff of fishy legends.</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🕶️🌟 <strong>Visibility:</strong> So darn clear, you'll feel like you've just upgraded to HD. Heck, focus hard enough, and you might just spot Nemo planning his next great escape.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌈🎨 <strong>Things to see:</strong> Beyond the marine rave, there's nature's own art gallery. Who needs museums when the seabed is sprinkled with nature's masterpieces? Dive, explore, and become a part of the art!</h5>
      </li>
     </ul>
     <h5>Newb Zone</h5>
     <p>🤿 So, you're thinking of taking a dive into the newbie-friendly waters of Puerto de la Cruz? Great choice! This "harbor" is basically Mother Nature's own dive site... with a tiny twist. See it on <a href="https://goo.gl/maps/tyL3QfkQEpisyq1s6">Google maps here</a> . 🌀</p>
     <img src={Cave3} className="img-fluid mb-4" alt="Puerto de la Cruz" />
     <h5>Vibrant environment</h5>
     <p>🐟 It's like someone saw a vibrant reef and thought, "Let's throw in a harbor wall for fun!" And voilà, a dive site was born! But don't let the man-made addition fool you. This spot is a favorite hangout for fish, who probably think of it as their version of a trendy cafe. ☕</p>
     <h5>Local animals</h5>
     <p>🏊‍♂️ And if you're wondering why the locals are treating it like a pool party, well, can you blame them? Between the snorkeling, diving, and the occasional Marco Polo game, it's THE place to be. Plus, with the range of species showing off here, it's like nature's version of a red carpet event. 🌟</p>
     <h5>Nearby you will find...</h5>
     <p>🌟 There is also a more advanced dive spot nearby called La Cathedral. More on that soon. 🌟</p>
     <h5>Pro tip:</h5>
     <p>💡  You might want to practice your fishy autograph requests. Who knows? You might just bump into the Angelina Jolie of angelfish. 🐠🖋️</p>
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot18handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-19" onClick={DiveSpot19handleShow}>
    <span>19</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot19Modal}
    onHide={DiveSpot19handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Los Arcos</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Underwater arches in a port!</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <img src={Arcos1} className="img-fluid" alt="Los Arcos dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🌋 <strong>Depth:</strong> From "Dipping my pinky toe in" at 19m to "Did I just crash an underwater party?" at 30m. Do fish throw pool parties at specific depths? 🎈</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🌬️ <strong>Temp:</strong> A consistent 19°C but occasionally, the ocean feels like cranking up the thermostat to a warm 24°C. Maybe the sea just wants to keep us on our toes? 🤷</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎸🎵 <strong>Creatures:</strong> Dive into the ocean's own concert! With barracudas on lead guitar, moray eels belting out the tunes, and octopuses breaking it down on the dance floor. And always remember, what happens at "Arcos", echoes throughout the sea. 🎤</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿👓🌟 <strong>Visibility:</strong> So clear, you'd think the fish had a spring cleaning spree! Squint a bit, and you might spot Nemo planning his next adventure. 🐠</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌄🎨 <strong>Things to see:</strong> Apart from the marine rave, there's the epic volcanic art installations. And whispers in the waves say, they're the Picasso of the underwater world. Dive in and be the judge! 🖼️</h5>
      </li>
     </ul>

     <img src={Arcos2} className="img-fluid mb-4" alt="Los Arcos diving point in Tenerife" />
     <h5>Garage dudes</h5>
     <p>📍 Welcome to Garachico Villa and Puerto on the lower island, the OG departure lounge for ships loaded with wine and sugar, setting sail for the Americas and Europe! 🚢🍷</p>
     <h5>Fishing is tight here</h5>
     <p>🐟 This fishing hotspot doubles up as an underwater treasure trove, reshaped by the whims of the Arenas Negras volcano. It's like Mother Nature's extreme makeover but underwater! 🌋</p>
     <img src={Arcos3} className="img-fluid mb-4" alt="Los Arcos diving point in Tenerife" />
     <h5>Check the arch</h5>
     <p>🏊‍♂️ Dive into "El Arco" and expect a VIP meet-and-greet with starfish celebs like groupers, abbots, bream, rock sama, and the ever-elusive octopus. Oh, and look out for the cuttlefish babies! They're the newest sensation in town. 🐙</p>
     <h5>Critters</h5>
     <p>🌱 Pair these with a backdrop of algae, diverse sponges, and the main attraction - a mind-blowing volcanic canvas. It's like attending an art exhibit, but you're floating! 🖼️</p>
     <h5>More info</h5>
     <p>🌊 Dive Details for "Los Arcos": Average Depth: 22m, Surface and Bottom Temp: A cozy 18.8°C. Best time to dive? Historical divemasters suggest the entire year, but especially February and November for that perfect blend of air and water temperature. 🌡️📅</p>
     <h5>⚠️ Dive Note:</h5>
     <p> Always check for the latest conditions. Mother Nature can be a tad unpredictable sometimes! ⛈️</p>


    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot19handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-20" onClick={DiveSpot20handleShow}>
    <span>20</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot20Modal}
    onHide={DiveSpot20handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>Punta Blanca</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>One of the best dives here!</h2>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 We don't usually dive up here because there are better spots in the South. Please kindly remember to get in touch at least a few days in advance if you want to dive in the north of the island because we would have to charge you extra for this service! 🚫🚫🚫
     </div>
     <img src={Punta1} className="img-fluid" alt="Punta Blanca dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📐🐚 <strong>Depth:</strong> From a "Just dipping my flippers" 20m to an "OMG, I just played hide and seek with Nemo" 30m. Do fish throw depth parties? Who knows? 🎉</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🌊 <strong>Temp:</strong> Coasting steady at a "Goldilocks-approved" 20°C, but sometimes Mother Ocean cranks up the thermostat to a toasty 24°C. Talk about underwater hot flashes!</h5>
      </li>
      <li className="list-group-item">
       <h5>🐠🎸🕶️ <strong>Creatures:</strong> Welcome to the undersea jazz club! With bass-playing barracudas, morays belting out ballads, and octopuses jiving to the beat. And just so you know, what goes down in "Punta Party", stays in "Punta Party".</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🔍🌌 <strong>Visibility:</strong> So clear, it's like the ocean just got LASIK. Try hard enough and you might just see into next week's dive schedule.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌋🎨 <strong>Things to see:</strong> Beyond the marine maestros, you've got nature's own sculptures - think of them as the ocean's abstract art collection. Word on the wave is they're the ocean's best conversation starters. Take the plunge and chat them up!</h5>
      </li>
     </ul>

     <img src={Punta2} className="img-fluid mb-4" alt="Punta Blanca diving point in Tenerife" />
     <h5>⚠️ Buffness galore</h5>
     <p>👀 If you're seeking a dive spot that's less "meh" and more "WHOA!", you've hit the jackpot! Dive into a world of underwater tunnels 🌀, majestic overhangs, and a reef that feels like nature's own skyscraper, stretching from 12-22m deep. But wait, there's more! Wander a bit, and you'll stumble upon Boulder City (not the official name, but it should be) 🪨 with gigantic stone blocks nestled at a depth of 30m. It's like the fish's version of Times Square, bustling with grunts, breams, and perhaps an eel or two playing hide-and-seek.</p>
     <img src={Punta3} className="img-fluid mb-4" alt="Punta Blanca diving point in Tenerife" />
     <h5>NatGeo ting</h5>
     <p>🌋 Geologically speaking, this place is like diving into a Nat Geo documentary. The combo of diverse marine life and intricate terrains makes this a multi-tiered diving adventure. Perfect for those who think diving in a straight line is too mainstream. 🌀 And for the shutterbugs 📸, every corner here screams "Instagram-worthy!"</p>
     <h5>Wanna try?</h5>
     <p>💧 Craving a dive? Anytime's a good time! Bask in water that's summer-day warm at 23°C or embrace the cooler embrace of 18°C winter waters. And keep your eyes peeled for the underwater celebs: gruntfish bands, chromis dancers, moray eels that might give autographs, and stingrays that, well, just sting (not really, but you get the drama). Oh, and don't miss the red carpet - or should we say, the red gorgonians at the wall's base. 🌹</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot20handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-21" onClick={DiveSpot21handleShow}>
    <span>21</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot21Modal}
    onHide={DiveSpot21handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>MarAzul</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>The must see turquoise water!</h2>

     <img src={MarAzul1} className="img-fluid" alt="MarAzul Blanca dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📐🏝️ <strong>Depth:</strong> From a "Gentle toe-dip" 12m to "I just had tea with a mermaid" 20m deep. Ever ponder if fish host depth-based potlucks? 🍲</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🌊 <strong>Temp:</strong> Cruising steady at a "Beach day every day" 19°C, but sometimes, the sea decides to host a summer BBQ and hits 24°C. Who brought the sunblock? ☀️</h5>
      </li>
      <li className="list-group-item">
       <h5>🐟🎤🎹 <strong>Creatures:</strong> Welcome to Marazul's marine concert! Featuring trumpetfish trumpeting, morays with their soulful solos, and octopuses dropping the freshest beats. And don't forget, what grooves in Marazul, stays in Marazul. 🎶</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🔍🌟 <strong>Visibility:</strong> Crystal clear, as if the ocean's been using premium window cleaner. Squint, and you might just spot next weekend's underwater festivities.</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🪨🎨 <strong>Things to see:</strong> Apart from the jiving sea critters, you've got rock formations that could easily be nature's Mona Lisas. The ocean's whispering that they're the real VIPs down there. Dive in, maybe you'll get an autograph!</h5>
      </li>
     </ul>


     <img src={MarAzul2} className="img-fluid mb-4" alt="MarAzul Blanca diving point in Tenerife" />
     <h5>Intro</h5>
     <p>🐠💦 Dive Alert! Marazul is rolling out the underwater red carpet for you. Imagine diving into a multi-level underwater playground, where rock formations are the skyscrapers and marine life are the bustling city folks.</p>
     <h5>Navigation</h5>
     <p>🌊🪨 Get ready to navigate through massive rock towers that stretch from 12m to 20m, resembling nature's version of Jenga blocks. Oh, and there's a sneaky little cave sculpted by Mother Nature's moody currents. It's like her secret hideout, but she's cool with sharing.</p>

     <img src={MarAzul3} className="img-fluid mb-4" alt="MarAzul Blanca diving point in Tenerife" />
     <h5>Descent</h5>
     <p>🐟🔍 As you make your descent, you'll play 'I spy' with tiny marine critters playing peek-a-boo in rock crevices. The fish? Think of them as the flash mobs of the ocean, constantly putting on spontaneous shows.</p>
     <h5>Tempz</h5>
     <p>🌞🌡️ You can dive into this aquatic wonderland all year. But, summer is when Marazul truly becomes the Ibiza of the sea with balmy 24°C waters. Winter? More like a chilled-out lounge room at 19°C.</p>
     <h5>Animals</h5>
     <p>🦑🐢🦈 Oh, and the guest list? Star-studded! From octopi DJs to trumpetfish pop stars, ray breakdancers, and the elusive angel shark celebs. Plus, rumor has it, turtles sometimes crash the party. Dive in and mingle!</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot21handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-22" onClick={DiveSpot22handleShow}>
    <span>22</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot22Modal}
    onHide={DiveSpot22handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>La Calleta</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <h2>Another must see!</h2>

     {/* <img src={Punta1} className="img-fluid" alt="Punta Blanca dive site  in Tenerife" /> */}
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🐠 <strong>Depth:</strong> From a "Gently floating with the fishes" 12m to a "Whoa, did I just photobomb a fish family portrait?" 25m. Fishy gossip says they have depth preference polls! 🤷</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️🌞🌊 <strong>Temp:</strong> Rolling steady at a "Just right for a fish spa" 20°C, but every so often, Mother Ocean feels like having a warm bath at a cozy 24°C. Get ready for some tropical vibes! 🍹</h5>
      </li>
      <li className="list-group-item">
       <h5>🐠🎷🎤 <strong>Creatures:</strong> Step into the ocean's groove joint! Featuring the trumpet-playing tuna, morays dropping the bass, and octopuses with their eight-arm salsa. Psst... what's revealed in "La Caleta Club", stays in "La Caleta Club". 🎶</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🔍🌈 <strong>Visibility:</strong> So crystal-clear, you'll think the ocean just had its annual window wash. Peek around, you might just spot next week's undersea events. 📅</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🏞️🎨 <strong>Things to see:</strong> Beyond the jazzy marine ensemble, there's Mother Nature's art gallery. Picture rocky masterpieces that might just rival modern art. Dive in and feel free to critique – the fish are all ears! 🖌️</h5>
      </li>
     </ul>
     <h5>Cheeky town of mischief</h5>
     <p>🐠 <strong>THE UNDERWATER SHENANIGANS OF LA CALETA:</strong> 🐠</p>
     <p>Alright, diving enthusiasts! Grab your fins and snorkels because La Caleta is calling! Just a fin's flap away from the port, going all northwesterly, and bam! You'll stumble upon some rock star ledges. No, seriously, they're ledges made of rock.</p>
     <h5>At the start</h5>
     <p>Start off at a casual "just getting my hair wet" depth of 9 meters. Feeling adventurous? Slide down the underwater cliff till you hit the sandy dance floor at 18 meters. And be ready to waltz, because the currents here might just lead your dance moves.</p>
     <h5>Magic stuff</h5>
     <p>Now, here's where the magic happens! Imagine the biggest, most colorful school reunion of grunts and horse mackerel. It's like watching a marine musical. Popcorn, anyone? 🍿</p>
     <h5>More info</h5>
     <p>But wait, there's more! Peek under the ledge, and you might just spot a stingray doing the limbo or an angelshark playing hide and seek.</p>
     <h5>Animals</h5>
     <p>Prepare to be serenaded by trumpets (fish, not the instrument), get dazzled by the glamour of nudibranchs, and maybe share a joke or two with the ever-so-chatty black moray eels. You'll also bump into Canary Norway lobsters, cleaner shrimp (they don’t actually clean, false advertising!), mysterious octopuses, and a whole parade of underwater celebrities.</p>
     <h5>Conclusion</h5>
     <p>To sum it up, if La Caleta's dive isn't on your Tenerife bucket list, you're missing out on an underwater blockbuster. Lights, camera, dive! 🎥🌊</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot22handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
   <div className="dive-spot-23" onClick={DiveSpot23handleShow}>
    <span>23</span>
   </div >
   <Modal
    keyboard={false}
    show={DiveSpot23Modal}
    onHide={DiveSpot23handleClose}
   >
    <Modal.Header closeButton>
     <Modal.Title><h5 className='mt-3'>🚫 Forbidden to dive: The highway!</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
     <div className="alert alert-danger" role="alert">
      🚫🚫🚫 Diving is forbidden here but you should know there is an ocean super highway here for mammals of all kind! 🚫
     </div>
     <h2>🚫 You CANNOT dive with whales and dolphins in the open sea!</h2>

     <img src={Highway1} className="img-fluid" alt="Ocean super highway dive site  in Tenerife" />
     <ul className="list-group mt-4 mb-4">
      <li className="list-group-item">
       <h5>🌊📏🐋🚫 <strong>Depth:</strong> No diving allowed and it is very deep 🤔</h5>
      </li>
      <li className="list-group-item">
       <h5>🌡️❄️🌊🚫 <strong>Temp:</strong>No diving allowed here 🥶</h5>
      </li>
      <li className="list-group-item">
       <h5>🐬🎤🐳🚫 <strong>Creatures:</strong> Lots to see at a guess but diving is prohibited here, with whale solos, squid ensembles, and the mesmerizing rhythm of the deep-sea orchestra. But remember, what's sung in the "Dolphin's Studio", echoes forever. 🎵</h5>
      </li>
      <li className="list-group-item">
       <h5>🤿🔦🌌🚫 <strong>Visibility:</strong> It's so mystic, you'd think you've teleported to Atlantis but again, diving is forbidden here. 🌠</h5>
      </li>
      <li className="list-group-item">
       <h5>🗺️🌌🎨🚫 <strong>Things to see:</strong> Despite diving being forbidden here, one would imagine the ocean displays its most enchanting luminescent art pieces. 🌌</h5>
      </li>
     </ul>

     <h5>Whale of a Tale Town</h5>
     <p>🚫 Dreaming of diving in the ocean super highway? Well, pump the brakes! It's a no-dive zone to protect our fin-tastic dolphin and whale buddies. Their peace and habitat are a top priority, and we must respect their space. 🐬🐋</p>
     <p>💡 Interested in diving at other breathtaking dive spots in Tenerife? Let's chat, and we'll guide you to the best and safe adventures under the waves. 🌊🤿</p>

    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={DiveSpot23handleClose}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
  </>
 )
}

export default DiveMapClickablePoints
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Promo from "./../img/promo.png";
import 'bootstrap/dist/css/bootstrap.min.css';
// import team3 from './../img/team3.png';
function IntroModal() {
  const [IntroModal, setIntroModal] = useState(false);

  // check if cookies are present
  const cookies = document.cookie;
  if (!cookies) {
    // show modal after a delay
    setTimeout(() => {
      setIntroModal(true);
    }, 15000);
  }
  const handleIntroClose = () => {
    document.cookie = "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setIntroModal(false);
  }
  // function to handle "Accept" button click
  const handleAccept = () => {
    // set a cookie to remember user's choice
    document.cookie = "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setIntroModal(false);
  };

  //  onHide={handleIntroClose} on Modal 


  return (
    <>
      <Modal
        keyboard={false}
        show={IntroModal}
        backdrop="static"
        onHide={handleIntroClose}
      >
        <Modal.Header>
          <Modal.Title><h2 className="mt-3">ARE COOKIES OK?</h2>
            <button className="btn btn-outline-dark btn-lg mb-3" onClick={handleAccept}>ACCEPT COOKIES</button><br /></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <img className="img-fluid mb-3" src={Promo} alt="Special offer on messages through the website! 10% off total." />

          WELCOME TO THE SCUBa Point DIVE SCHOOL IN LOS CRISTIANOS, RUN BY MARCOS, GONZA AND ELIANA.
          <p className="mt-3" >Our dive school is located in the beautiful South of Tenerife and offers a wide range of diving experiences for all levels. Whether you're a beginner or an experienced diver, we have something for everyone.</p>
          <p>We offer snorkeling trips for those who want to explore the shallow waters and see the vibrant marine life. For more experienced divers, we have fun dives and try dives for those who want to discover the incredible underwater world here.</p>
          <p>We also offer the PADI Open Water diploma and the Advanced Open Water diploma amongst others for those who want to become certified divers, see our packages page. Our experienced instructors will guide you through the process and ensure that you have a safe and enjoyable experience. For those who want to take their diving skills to the next level, we also have the PADI Dive Master Diploma course available.
          </p>
          <p>We have a wide range of quality equipment available for rental, so you don't have to worry about bringing your own.</p>
          <p>We pride ourselves on providing excellent service and safety standards and have a team of experienced instructors who speak multiple languages to make sure everyone can understand the safety briefing.</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default IntroModal
import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import SocialIcons from './SocialIcons';

import "./../css/Nav.scss"
import LogoWhite from "./../img/scuba-point-white.svg";
import LogoBlack from "./../img/scuba-point-black.svg";






function Nav() {
  // logo swap dark light
  const location = useLocation();
  const { pathname } = location;
  const isInteractiveMapPage = pathname === "/interactive_scuba_diving_map_of_tenerife";
  //end logo swap
  // Add a new state to track whether the menu is open or not for showing white and black version on interactive map
  const [isMenuOpen, setMenuOpen] = useState(false);

  function showMenu() {
    var toggleVariable: any = document.getElementById('main-menu');
    // if shown
    if (toggleVariable.style.display === 'block') {
      // hide it after.5
      setTimeout(function () {
        toggleVariable.style.display = 'none';
      }, 300);
      // change the class
      toggleVariable.classList.remove('menu-on');
      toggleVariable.classList.add('menu-off');
      // stuff to remove cross from burger
      var showCross: any = document.getElementById('burger-cross');
      showCross.classList.remove('show-cross');
      // Close the menu
      setMenuOpen(false);
    } else {
      // otherwise show it
      toggleVariable.style.display = 'block';
      // otherwise add the class
      toggleVariable.classList.add('menu-on');
      toggleVariable.classList.remove('menu-off');
      // stuff to add cross from burger
      var showCross2: any = document.getElementById('burger-cross');
      showCross2.classList.add('show-cross');
      // Open the menu
      setMenuOpen(true);
    }
  }

  return (
    <>
      <header>

        <div id="main-menu">
          <h3>MENU</h3>
          <Link className="main_nav" onClick={showMenu} to="/">HOME</Link>
          <Link className="main_nav" onClick={showMenu} to="/about_scuba_point_diving_tenerife">ABOUT</Link>
          <Link className="main_nav buy_now" onClick={showMenu} to="/packages_from_scuba_point_tenerife">PACKAGES</Link>
          <Link className="main_nav" onClick={showMenu} to="/gallery_of_scuba_diving_tenerife">GALLERY</Link>
          <Link className="main_nav" onClick={showMenu} to="/partners_of_scuba_point_in_tenerife">PARTNERS</Link>
          <Link className="main_nav" onClick={showMenu} to="/interactive_scuba_diving_map_of_tenerife">DIVE MAP</Link>
          <Link className="main_nav" onClick={showMenu} to="/blog_about_scuba_diving_tenerife">BLOG</Link>
          <Link className="main_nav" onClick={showMenu} to="/faq_about_scuba_diving_tenerife">FAQ</Link>
          <Link className="main_nav" onClick={showMenu} to="/contact_scuba_point_in_tenerife">CONTACT</Link>
          {/* <Link className="main_nav" to="/does-not-exist">Catch all route</Link> */}
          <div className="social-roll-nav-wrap"> <SocialIcons /></div>
        </div>

        <button
          aria-label="open menu"
          className="menu-button"
          onClick={showMenu}
        >
          <div id="burger-cross" className="burger-menu">
            <div className="burger"></div>

          </div>
        </button>
        <Link to="/">
          <img src={isMenuOpen ? LogoBlack : LogoWhite && isInteractiveMapPage ? LogoWhite : LogoBlack} alt="Scuba Point Logo" className="logo" />
        </Link>
        <div
          className='col-12 col-xl-12 mb-2'
        >
          <br />
          <div className="nav_booking">

            <Link className="btn btn-outline-dark btn-lg" to="/packages_from_scuba_point_tenerife">
              BOOK
            </Link>
          </div>


        </div>

      </header>

    </>
  )
}

export default Nav



import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import { motion } from 'framer-motion'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Iframe from 'react-iframe'
import 'bootstrap/dist/css/bootstrap.min.css';
import BookingsModal from './BookingsModal';
import fun from './../img/packages/fun.png';
import master from './../img/packages/master.png';
import open from './../img/packages/open.png';
import rescue from './../img/packages/rescue.png';
import rescue1 from './../img/packages/rescue1.png';
import snorkle from './../img/packages/snorkle.png';
import packs from './../img/packages/packs.png';
import night from './../img/packages/night.png';
import trial from './../img/packages/try.png';
import efr from './../img/packages/efr.png';
import special from './../img/packages/special.png';
import refresh from './../img/packages/refresh.png';
import accommodation1 from './../img/packages/accommodation1.png';
import accommodation2 from './../img/packages/accommodation2.png';
import accommodation3 from './../img/packages/accommodation3.png';
import accommodation4 from './../img/packages/accommodation4.png';
import './../css/Packages.scss'


declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

function Packages() {

  const [Package0Modal, setPackage0Modal] = useState(false);
  const handlePackage0Close = () => setPackage0Modal(false);
  const handlePackage0Show = () => setPackage0Modal(true);
  const [Package1Modal, setPackage1Modal] = useState(false);
  const handlePackage1Close = () => setPackage1Modal(false);
  const handlePackage1Show = () => setPackage1Modal(true);
  const [Package2Modal, setPackage2Modal] = useState(false);
  const handlePackage2Close = () => setPackage2Modal(false);
  const handlePackage2Show = () => setPackage2Modal(true);
  const [Package3Modal, setPackage3Modal] = useState(false);
  const handlePackage3Close = () => setPackage3Modal(false);
  const handlePackage3Show = () => setPackage3Modal(true);
  const [Package4Modal, setPackage4Modal] = useState(false);
  const handlePackage4Close = () => setPackage4Modal(false);
  const handlePackage4Show = () => setPackage4Modal(true);
  const [Package5Modal, setPackage5Modal] = useState(false);
  const handlePackage5Close = () => setPackage5Modal(false);
  const handlePackage5Show = () => setPackage5Modal(true);
  const [Package6Modal, setPackage6Modal] = useState(false);
  const handlePackage6Close = () => setPackage6Modal(false);
  const handlePackage6Show = () => setPackage6Modal(true);
  const [Package7Modal, setPackage7Modal] = useState(false);
  const handlePackage7Close = () => setPackage7Modal(false);
  const handlePackage7Show = () => setPackage7Modal(true);
  const [Package8Modal, setPackage8Modal] = useState(false);
  const handlePackage8Close = () => setPackage8Modal(false);
  const handlePackage8Show = () => setPackage8Modal(true);
  const [Package9Modal, setPackage9Modal] = useState(false);
  const handlePackage9Close = () => setPackage9Modal(false);
  const handlePackage9Show = () => setPackage9Modal(true);
  const [Package10Modal, setPackage10Modal] = useState(false);
  const handlePackage10Close = () => setPackage10Modal(false);
  const handlePackage10Show = () => setPackage10Modal(true);
  const [Package11Modal, setPackage11Modal] = useState(false);
  const handlePackage11Close = () => setPackage11Modal(false);
  const handlePackage11Show = () => setPackage11Modal(true);


  useEffect(() => {


    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "https://scuba-point.com") {
        console.log('Origin mismatch:', event.origin);
        return;
      }
      console.log('Message received from iframe:', event.data);

      // Assuming the event.data contains { event: 'purchase', ...otherDetails }
      if (event.data.event === 'purchase') {
        // Replace with your GA4 event tracking code
        window.gtag('event', 'purchase', {
          // You can add more details from event.data here
          value: event.data.value, // Example detail
          currency: 'EUR',
          transaction_id: event.data.transaction_id // Example detail
        });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);


  const PackagesldJson = [
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "SNORKEL BOAT TOUR",
      "image": "https://scuba-point.com/static/media/snorkle.662cb88827244837fd23.png",
      "description": "Check the scene without getting involved with the technical stuff.",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€45.00"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "TRY DIVE",
      "image": "https://scuba-point.com/static/media/try.2f859eebbd96aa09f1a0.png",
      "description": "Try scuba diving for the first time and see if it is your type of cake",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€58.50"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "FUN DIVE",
      "image": "https://scuba-point.com/static/media/fun.c00cc9e3334c61c19c76.png",
      "description": "A little experience already? We can take you to explore something fun!",
      "url": ""
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "PADI OPEN WATER",
      "image": "https://scuba-point.com/static/media/open.5d620d47676449aaaa53.png",
      "description": "Do the 3 day Open Water course and pass the theory test. Then dive anywhere!",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€355.50"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "ADVANCED OPEN WATER",
      "image": "https://scuba-point.com/static/media/rescue.7b1c77206a8fb0d1bdb3.png",
      "description": "Learn the intermediate certificate that will teach you about search and rescue.",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€328.50"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "PADI RESCUE DIVER",
      "image": "https://scuba-point.com/static/media/rescue1.e658710f71564380ccf8.png",
      "description": "Prepare yourself to manage all kinds of stress or rescue situations for other divers",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€328.50"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "EMERGENCY FIRST RESPONSE",
      "image": "https://scuba-point.com/static/media/efr.3b307d0a4b48da248f2e.png",
      "description": "Respond to accidents not only in the water as a diver, but also in daily life.",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€288.00"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "PADI DIVE MASTER",
      "image": "https://scuba-point.com/static/media/master.467502b3eb9f055d9afd.png",
      "description": "Become a scuba diving jedi master and help others use the force while underwater.",
      "url": "",
      "offers": {
        "@type": "Offer",
        "price": "€652.50"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "OUR SPECIAL DIVES",
      "image": "https://scuba-point.com/static/media/special.fa648f6ab1e11c3c0bf6.png",
      "description": "Ask about our special dives: Deep diver, wreck diver, dive vehicle propulsion or even Nitrox.",
      "url": ""
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "Hostel pack 1",
      "image": "https://scuba-point.com/static/media/accommodation1.3bae1f08c0e1af21eb66.png",
      "description": "What does pack 1 include?<BR/>+ Open Water Diver Course <BR/>+ Pick up / Drop Off<BR/>+ 7 nights at the <A href=\"https://www.endlesssummertenerife.com/\">Endless Summer hostel",
      "url": "https://scuba-point.com/contact_scuba_point_in_tenerife",
      "offers": {
        "@type": "Offer",
        "price": "€ 599.00"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "pack",
      "image": "https://scuba-point.com/static/media/accommodation2.d32912f8084fec408fbf.png",
      "description": "What does pack 2 include?<BR/>+ Open Water + Advanced Open Water Course<BR/>+ Pick up / Drop Off<BR/>+ 7 nights at the <A href=\"https://www.endlesssummertenerife.com/\">Endless Summer hostel",
      "url": "https://scuba-point.com/contact_scuba_point_in_tenerife",
      "offers": {
        "@type": "Offer",
        "price": "€ 859.00"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "Hostel pack 3",
      "image": "https://scuba-point.com/static/media/accommodation3.17e6cb8cd526c6a0a866.png",
      "description": "What does pack 3 include?<BR/>+ Try Dive + Snorkelling tour <BR/>+ Pick up / Drop Off<BR/>+ 7 nights at the <A href=\"https://www.endlesssummertenerife.com/\">Endless Summer hostel",
      "url": "https://scuba-point.com/contact_scuba_point_in_tenerife",
      "offers": {
        "@type": "Offer",
        "price": "€ 369.00"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "pack",
      "image": "https://scuba-point.com/static/media/accommodation4.bf9e6150f47543619a5e.png",
      "description": "What does pack 4 include?<BR/>+ The Open Water Diver Course <BR/>+ Pick up / Drop Off<BR/>+ 5 nights at the <A href=\"https://www.endlesssummertenerife.com/\">Endless Summer hostel",
      "url": "https://scuba-point.com/contact_scuba_point_in_tenerife",
      "offers": {
        "@type": "Offer",
        "price": "€ 469.00"
      }
    }
  ]

  return (
    <motion.div
      className="wrapper"
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Book Your Dive | Scuba Diving Packages in Tenerife - Scuba Point</title>
        <link rel="canonical" href="https://www.scuba-point.com/packages_from_scuba_point_tenerife/" />
        <meta name="description" content="Dive into adventure with our scuba diving packages in Tenerife. Explore our offerings and book your next scuba dive in Tenerife with Scuba Point today." />
        <meta name="keywords" content="Scuba Dive Tenerife, Diving Packages, Book Scuba Diving, Scuba Point" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(PackagesldJson)}
      </script>
      <Container>
        <Row>
          <div className="col-12 col-xl-8 offset-xl-2">
            <motion.h1
              className='col-xl-9'
              initial={{ opacity: 0 }}
              animate={{
                y: 20,
                opacity: 1,
                position: "relative",
                transition: { duration: 1, delay: 0 }
              }}>
              <span className="h1 mb-5">
                SCUBa
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{

                    opacity: 1,
                    position: "relative",
                    transition: { duration: 2, delay: 1 }
                  }}
                >&nbsp;POINT </motion.span>
                DIVING PACKAGES</span></motion.h1>
            <motion.p
              className="col-xl-9 mb-5"
              initial={{ opacity: 0 }}
              animate={{
                y: 20,
                opacity: 1,
                position: "relative",
                transition: { duration: 0.3, delay: 0.3 }
              }}
            >

              Listen up, ocean adventurers! At Scuba Point, we've got more adventure packages than a pirate has treasure chests! There's something for everyone, no matter if you're a young guppy or a wise old sea turtle.
            </motion.p>
            <motion.div
              className='col-12 col-xl-12 mb-2'
              initial={{ opacity: 0 }}
              animate={{
                y: -20,
                opacity: 1,
                position: "relative",
                transition: { duration: 0.4, delay: 0.4 }
              }}
            >
              <br />
              {/* <BookingsModal /> */}
            </motion.div>

            <motion.div
              className='col-12 col-xl-12'
              initial={{ opacity: 0 }}
              animate={{
                y: -20,
                opacity: 1,
                position: "relative",
                transition: { duration: 0.4, delay: 0.4 }
              }}
            >
              <Container>
                <Row>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 0.4 }
                    }}
                  >
                    <img className="img-fluid w-100" src={packs} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">NEW DIVE<br />PACKS</h2>
                    <p className="text-center">Buy multiple dives and save even more of your hard earned cash!</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage0Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package0Modal}
                      onHide={handlePackage0Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>DIVE&nbsp;PACKS</h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>Discover the best dive packs available:</p>
                        <ul>
                          <li>2 dives: <del>€90.00</del> €81.00</li>
                          <li>4 dives: <del>€175.00</del> €157.50</li>
                          <li>6 dives: <del>€225.00</del> €202.50</li>
                          <li>8 dives: <del>€275.00</del> €247.50</li>
                          <li>10 dives: <del>€300.00</del>  €270.00</li>
                        </ul>
                        <p className="mt-3">
                          Choose from our various dive pack options to explore the stunning underwater world. Our experienced guides will ensure you have a safe and memorable experience.
                        </p>

                        <Iframe
                          url="https://scuba-point.com/iframes-dont-delete/dive_packs.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative"
                        />
                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center">FROM <del>€90.00</del></h5>
                    <h4 className="text-center">€81.00</h4>


                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}

                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 0.4 }
                    }}
                  >
                    <img className="img-fluid w-100" src={snorkle} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">SNORKEL<br />BOAT TOUR</h2>
                    <p className="text-center">Check the scene without getting involved with the technical stuff.</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage1Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package1Modal}
                      onHide={handlePackage1Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>SNORKEL BOAT&nbsp;TRIP</h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        A snorkeling boat tour of Tenerife is an adventure that takes you to some of the most beautiful and diverse marine habitats in the Canary Islands.
                        <p className="mt-3">The tour, which costs 49.50 euros per person, departs from the port of Tenerife and takes you on a journey to the clear waters of the Atlantic Ocean.
                        </p>
                        <p>During the tour, you will have the opportunity to see a wide range of marine life such as trumpet fish, sea turtles and sometimes we are lucky enough to see dolphins.</p>
                        <p> The tour guides will provide you with all the necessary equipment, including snorkels, masks, and fins, as well as give you a detailed briefing on the best ways to enjoy your time in the water. You'll also be able to relax and enjoy the scenery on the boat trip.</p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/snorkel_boat_tour.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />
                        <br />HERE */}
                        <Iframe url="https://scuba-point.com/iframes-dont-delete/snorkel_boat_tour.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />
                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€55.00</del></h5>
                    <h4 className="text-center">€49.50</h4>


                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}

                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 0.7 }
                    }}
                  >
                    <img className="img-fluid w-100" src={trial} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">TRY<br />DIVE</h2>
                    <p className="text-center">Try scuba diving for the first time and see if it is your type of cake.</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage2Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package2Modal}
                      onHide={handlePackage2Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>TRY DIVE</h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        A trial scuba diving session in Tenerife is the perfect way to experience the underwater world of the Canary Islands.
                        <p className="mt-3"> The session is designed for those who have never dived before or are looking to try it out before committing to a full certification course. It typically lasts for about 2-3 hours and begins with a brief training session on land, where you will learn the basics of diving, such as how to use the equipment and important safety procedures.
                        </p>
                        <p>After the briefing, you will access the dive site with a certified instructor who will guide you through the dive, helping you to feel comfortable and safe underwater. You will have the opportunity to explore the clear waters and see a variety of marine life.
                          You will also get an opportunity to practice your newly learned skills and enjoy the sea bed in a safe and controlled environment.</p>

                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/try_dive.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />
 */}


                        <Iframe url="https://scuba-point.com/iframes-dont-delete/try_dive.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />



                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€75.00</del></h5>
                    <h4 className="text-center">€67.50</h4>
                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1 }
                    }}
                  >
                    <img className="img-fluid w-100" src={fun} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">FUN<br />DIVE</h2>
                    <p className="text-center">A little experience already? We can take you to explore something fun!</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage3Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package3Modal}
                      onHide={handlePackage3Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>FUN DIVE</h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Fun dives at Tenerife based scuba diving club Scuba Point are the perfect way for intermediate divers to explore the beautiful waters of the Canary Islands.
                        <p className="mt-3">  The club offers a variety of dive sites to choose from, each with its own unique marine life and underwater landscapes. The dive sites are suitable for divers who have dived before and have an Open Water or equivalent certification.
                        </p>

                        <p>The fun dives are led by experienced and certified dive instructors who will guide you through the dive, ensuring your safety and helping you to make the most of your underwater experience. They'll also give you a briefing on the dive site, pointing out the best places to see marine life and interesting features.</p>

                        <p>The fun dives are a great opportunity for intermediate divers to improve their diving skills, explore new dive sites, and see some of the most spectacular marine environments.</p>
                        <p>* <i>Please note that boat dives are 12e more per dive, every diver must have their own insurance, otherwise we can provide you our DAN insurance per day, month or year. We go diving every day to the best diving sites in Tenerife for certified divers, both from the boat and from the coast. </i></p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/fun_dive.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" /> */}
                        <Iframe url="https://scuba-point.com/iframes-dont-delete/fun_dive.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />

                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€55/€100</del></h5>
                    <h4 className="text-center">€49.50/€90</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}

                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1 }
                    }}
                  >
                    <img className="img-fluid w-100" src={refresh} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">REFRESH<br />DIVE</h2>
                    <p className="text-center">Diving Amnesia? We can remind you how the dive gear works again</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage11Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package11Modal}
                      onHide={handlePackage11Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>REFRESHER DIVE</h2></Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        Rediscover the joy of diving with our Gear Refresh Dive at Scuba Point in Tenerife. This dive is designed for certified divers who need a refresher to regain their confidence underwater.
                        <p className="mt-3">
                          We offer access to a variety of dive sites, each teeming with unique marine life and stunning underwater landscapes. This dive is perfect for those holding an Open Water or equivalent certification but can't remember all the techniques and gear installations.
                        </p>

                        <p>
                          Our experienced and certified dive instructors will provide comprehensive guidance and safety briefings. They will assist you in refreshing your diving skills, ensuring you feel comfortable and secure throughout the dive.
                        </p>

                        <p>
                          The Gear Refresh Dive is an excellent opportunity for divers to brush up on their skills, explore new dive sites, and enjoy the beautiful marine environments that Tenerife has to offer.
                        </p>

                        <Iframe
                          url="https://scuba-point.com/iframes-dont-delete/refresh_dive.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative"
                        />
                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€72.50</del></h5>
                    <h4 className="text-center">€65.25</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}

                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1 }
                    }}
                  >
                    <img className="img-fluid w-100" src={night} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">NIGHT<br />DIVE</h2>
                    <p className="text-center">Been eating lots of carrots ey!? Join us for a wonderful night dive.</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage10Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package10Modal}
                      onHide={handlePackage10Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>NIGHT DIVE</h2></Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        Experience the thrill of night diving at Tenerife with Scuba Point, a premier scuba diving club. Our night dives offer a unique opportunity for certified divers to explore the enchanting underwater world after dark.
                        <p className="mt-3">
                          We provide access to a variety of dive sites, each with distinct marine life and underwater landscapes. These dives are perfect for those holding an Open Water or equivalent certification.
                        </p>

                        <p>
                          Led by our experienced and certified dive instructors, you'll receive comprehensive guidance and safety briefings. Our instructors will highlight the best spots for marine life sightings and other underwater features, ensuring you have an unforgettable experience.
                        </p>

                        <p>
                          Night dives are an excellent way for divers to enhance their skills, discover new dive sites, and witness the vibrant nocturnal marine environment.
                        </p>

                        <Iframe
                          url="https://scuba-point.com/iframes-dont-delete/night_dives.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative"
                        />
                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€72.50</del></h5>
                    <h4 className="text-center">€65.25</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}

                  </motion.div>
                  <motion.h1
                    className='col-xl-9 mt-5'
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 1, delay: 0 }
                    }}>
                    <span className="h1 mb-5">
                      SCUBa
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{

                          opacity: 1,
                          position: "relative",
                          transition: { duration: 2, delay: 1 }
                        }}
                      >&nbsp;POINT </motion.span>
                      COURSES</span></motion.h1>
                  <motion.p
                    className="col-xl-9 mb-5"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.3, delay: 0.3 }
                    }}
                  >
                    We have a variety of different diving courses which can help you become certified. But be warned, side effects may include an intense love for the ocean, a newfound respect for marine life, and an uncontrollable urge to make fish puns.
                  </motion.p>
                  <motion.div
                    className='col-12 col-xl-12 mb-2'
                    initial={{ opacity: 0 }}
                    animate={{
                      y: -20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 0.4 }
                    }}
                  >
                    {/* <br />
                    <BookingsModal /> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.3 }
                    }}
                  >
                    <img className="img-fluid w-100" src={open} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">PADI<br />OPEN<br />WATER</h2>
                    <p className="text-center">Do the 3 day Open Water course and pass the theory test. Then dive anywhere!</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage4Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package4Modal}
                      onHide={handlePackage4Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>OPEN WATER PADI CERTIFICATION</h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        In this course, you will become a certified diver. You will learn to assemble your own diving equipment, the necessary skills to feel confident and safe underwater as well as enjoy the experience much more.

                        <p className="mt-3">  The course consists of a practical part in the water where we will do 5 dives, and a theoretical part where you must take a final exam to later receive certification as a certified diver.
                        </p>
                        <p>The PADI Open Water Diving course in Tenerife is a great way to learn how to dive in a beautiful location. The course typically lasts 2-3 days, with a maximum depth of 18 meters.  </p>
                        <p>You will learn the basics of diving including safety procedures, diving equipment, and underwater communication. You will also have the opportunity to explore the diverse marine life of the Tenerife waters. </p>
                        <p>Upon completion of the course, you will be certified as a PADI Open Water Diver, allowing you to dive independently with a buddy to a maximum depth of 18 meters. Tenerife is a great place to learn to dive, with crystal clear waters and a wide variety of marine life, it's an unforgettable experience.</p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/padi_open_water.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" /> */}


                        <Iframe url="https://scuba-point.com/iframes-dont-delete/padi_open_water.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />
                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€430.00</del></h5>
                    <h4 className="text-center">€387</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.6 }
                    }}
                  >
                    <img className="img-fluid w-100" src={rescue} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">ADVANCED OPEN WATER</h2>
                    <p className="text-center">Learn the intermediate certificate that will teach you about search and rescue.</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage5Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package5Modal}
                      onHide={handlePackage5Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>PADI ADVANCED OPEN WATER</h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        The PADI advanced open water course is a fundamental step in your career as a diver, where you can continue to go deeper.
                        <p className="mt-3">With this course you will learn the skills to be able to go up to 30 meters, but it is not only that! You will also become a good underwater navigator because we will teach you how to navigate, just as we will greatly improve our buoyancy, we can also opt for a night dive, a shipwreck dive or a search and recovery of objects. It is one of the most fun and dynamic courses and it does not have a final exam! Do not miss it!
                        </p>
                        <p>The PADI Advanced Open Water course in Tenerife is a great way to take your diving skills to the next level. The course typically lasts 2 days and includes a total of 5 dives, with a maximum depth of 30 meters. You will learn advanced diving techniques such as deep diving, navigation and peak performance buoyancy.  </p>
                        <p>You will also have the opportunity to explore the diverse marine life at deeper levels in the Tenerife waters. Upon completion of the course, you will be certified as a PADI Advanced Open Water Diver, allowing you to dive to a maximum depth of 30 meters and participate in a variety of specialty dives such as wreck, navigation and deep diving. Tenerife offers crystal clear waters and a wide variety of marine life that will make your course an unforgettable experience. </p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/advanced_open_water.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" /> */}

                        <Iframe url="https://scuba-point.com/iframes-dont-delete/advanced_open_water.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />


                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€405.50</del></h5>
                    <h4 className="text-center">€364.95</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={rescue1} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">PADI<br />RESCUE<br />DIVER</h2>
                    <p className="text-center">Prepare yourself to manage all kinds of stress or rescue situations for other divers</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage7Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package7Modal}
                      onHide={handlePackage7Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>PADI RESCUE DIVER </h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        The PADI Rescue Diver course in Tenerife is a 2-day program that equips divers with the skills and knowledge to effectively manage stress and handle rescue situations. This course is designed for divers who are looking to expand their knowledge and improve their safety skills.
                        <p className="mt-3"> During the course, you will learn how to recognize and respond to dive emergencies, as well as how to assist injured or panicked divers. You will also learn how to provide first aid and perform rescue breathing, which are crucial skills for any diver.
                        </p>
                        <p> The course is taught by certified PADI instructors and is conducted in a safe and controlled environment. Upon completion of the course, you will be awarded a PADI Rescue Diver certification.</p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/padi_rescue_diver.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />
                           */}
                        <Iframe url="https://scuba-point.com/iframes-dont-delete/padi_rescue_diver.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />

                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€405.50</del></h5>
                    <h4 className="text-center">€364.95</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={efr} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">EMERGENCY<br />FIRST<br />RESPONSE</h2>
                    <p className="text-center">Respond to accidents not only in the water as a diver, but also in daily life.

                    </p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage8Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package8Modal}
                      onHide={handlePackage8Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>PADI FIRST RESPONSE (EFR) </h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        The PADI Emergency First Response (EFR) course in Tenerife is a one-day program that teaches individuals how to provide emergency care and first aid in a scuba diving environment.
                        <p className="mt-3">  The course is designed for divers and non-divers alike, and it is a prerequisite for the PADI Rescue Diver course. The course covers essential topics such as basic life support, cardiopulmonary resuscitation (CPR) and injury management.</p>
                        <p>
                          Your instructor will teach you how to give CPR correctly, and how to respond to accidents that will make you not only a better diver but also a better prepared citizen to attend to emergencies that can happen in life itself.
                          This course is a mandatory requirement to be certified as a rescue diver.

                        </p>
                        <p> During the course, you will learn how to recognize and respond to emergencies, as well as how to provide basic life support and first aid. You will also learn how to perform CPR, which is a vital skill for any diver. The course is taught by certified PADI instructors, and it is conducted in a classroom setting. The course also includes hands-on practice scenarios, so you can apply the skills you have learned.</p>
                        <p>

                          Upon completion of the course, you will be awarded a PADI Emergency First Response certification. This certification is internationally recognized. The EFR course is an excellent way to learn essential life-saving skills, and it provides a solid foundation for more advanced rescue training.</p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/emergency_first_response.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" /> */}
                        <Iframe url="https://scuba-point.com/iframes-dont-delete/emergency_first_response.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />

                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€320.00</del></h5>
                    <h4 className="text-center">€288.00</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={master} alt="Scuba Point Packages" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">PADI<br />DIVE<br />MASTER</h2>
                    <p className="text-center">Become a scuba diving jedi master and help others use the force while underwater.</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage6Show}>BOOK NOW</span>
                    <Modal
                      keyboard={false}
                      show={Package6Modal}
                      onHide={handlePackage6Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>PADI MASTER DIVER </h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        The PADI Master course in Tenerife is a comprehensive professional program designed for experienced divers who want to take their diving skills to the professional level, with this diploma  you can work in the industry anywhere in the world.
                        <p className="mt-3"> During the course, you will learn advanced diving techniques such as dive planning, equipment configuration, and risk management.
                        </p>
                        <p>You will also have the opportunity to gain experience in leadership and dive supervision, as well as refining your rescue skills. Upon completion of the course, you will be certified as a PADI Divemaster, the first level of professional certification, allowing you to assist PADI Instructors in training and leading certified divers.  </p>
                        <p> Tenerife offers great diving locations, crystal clear waters, and a wide variety of marine life, which makes it an ideal place to take the PADI Master course.</p>
                        {/* <Iframe url="https://archibaldbutler.com/projects/scuba_point_booking/dive_master_course.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" /> */}
                        <Iframe url="https://scuba-point.com/iframes-dont-delete/dive_master_course.html"
                          width="100%"
                          height="767px"
                          id=""
                          className="iframed"
                          display="block"
                          position="relative" />

                      </Modal.Body>
                    </Modal>
                    <h5 className="text-center"><del>€725.00</del></h5>
                    <h4 className="text-center">€652.50</h4>

                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-4 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={special} alt="Scuba Point Special dives in Tenerife" />
                    <div className="sparkle"></div>
                    <h2 className="text-center pt-4">OUR<br />SPECIAL<br />DIVES</h2>
                    <p className="text-center">Ask about our special dives: Deep diver, wreck diver, dive vehicle propulsion or even Nitrox.</p>
                    <span className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" onClick={handlePackage9Show}>CONTACT THE TEAM</span>
                    <Modal
                      keyboard={false}
                      show={Package9Modal}
                      onHide={handlePackage9Close}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title><h2 className='mt-3'>SPECIAL DIVES </h2></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Tenerife offers a variety of special dives for divers of all levels. Some of the popular special dives include:

                        <p className="mt-3"> 1. Deep Diver: This course is designed for divers who want to explore the deeper depths of the ocean. It covers the skills and knowledge needed to safely dive to depths of up to 40 meters. During the course, you will learn about the special equipment and techniques required for deep diving, as well as how to manage the additional risks associated with deep diving.
                        </p>
                        <p>2. Wreck Diver: Tenerife is home to several shipwrecks that are popular among divers. The Wreck Diver course teaches divers how to safely explore these wrecks and learn about their history. During the course, you will learn how to navigate and penetrate wrecks, as well as how to handle the additional risks associated with wreck diving.
                        </p>
                        <p>3. Dive Vehicle Propulsion (DVP): This course is designed for divers who want to explore the underwater world using a Dive Propulsion Vehicle (DPV). DPV's allow divers to cover more ground and see more marine life, but they also have specific safety rules, the DVP course will teach you the proper use of these vehicles, and the skills required to operate them safely.</p>

                        <p>4. Nitrox Diver: Nitrox is a type of breathing gas that contains a higher percentage of oxygen. It is popular among divers because it can help to reduce the risk of decompression sickness. During the Nitrox Diver course, you will learn about the benefits and risks of using Nitrox, as well as how to properly plan and execute Nitrox dives.</p>
                        <p>All these special dives will be conducted by certified PADI instructors, and they are held in safe and controlled environments. The courses will provide you with the necessary skills and knowledge to safely explore the underwater world and enjoy more advanced types of diving.</p>
                        <Link className="btn btn-outline-dark btn-lg d-block mb-3" to="/contact_scuba_point_in_tenerife">CONTACT US</Link>
                      </Modal.Body>
                    </Modal>
                    <h4 className="text-center">&nbsp;</h4>
                    {/* <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">BOOK NOW</Link> */}
                  </motion.div>
                  <motion.h1
                    className='col-xl-9 mt-5'
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 1, delay: 0 }
                    }}>
                    <span className="h1 mb-5">
                      SCUBa
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{

                          opacity: 1,
                          position: "relative",
                          transition: { duration: 2, delay: 1 }
                        }}
                      >&nbsp;POINT </motion.span>
                      ACCOMODATION DEALS</span></motion.h1>
                  <motion.p
                    className="col-xl-9 mb-5"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.3, delay: 0.3 }
                    }}
                  >Scuba Point has teamed up with the 'beachin'  <a href="https://www.endlesssummertenerife.com/">Endless Summer hostel</a> hostel to bring you some deals that are hotter than a sunbathing starfish!

                  </motion.p>
                  <motion.div
                    className='col-12 col-xl-12 mb-2'
                    initial={{ opacity: 0 }}
                    animate={{
                      y: -20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 0.4 }
                    }}
                  >
                    <br />
                    <BookingsModal />
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-6 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={accommodation1} alt="Scuba Point Special dives in Tenerife" />
                    <div className="sparkle"></div>
                    <h2 className="pt-4">Hostel pack 1</h2>
                    <p>What does pack 1 include?<br />
                      + Open Water Diver Course <br />
                      + Pick up / Drop Off<br />
                      + 7 nights at the <a href="https://www.endlesssummertenerife.com/">Endless Summer hostel</a></p>
                    <h4 className="text-center">€ 599.00</h4>
                    <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">CONTACT US</Link>
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-6 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={accommodation2} alt="Scuba Point Special dives in Tenerife" />
                    <div className="sparkle"></div>
                    <h2 className="pt-4">Hostel pack 2</h2>
                    <p>What does pack 2 include?<br />
                      + Open Water + Advanced Open Water Course<br />
                      + Pick up / Drop Off<br />
                      + 7 nights at the <a href="https://www.endlesssummertenerife.com/">Endless Summer hostel</a></p>

                    <h4 className="text-center">€ 859.00</h4>
                    <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">CONTACT US</Link>
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-6 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={accommodation3} alt="Scuba Point Special dives in Tenerife" />
                    <div className="sparkle"></div>
                    <h2 className="pt-4">Hostel pack 3</h2>
                    <p>What does pack 3 include?<br />
                      + Try Dive + Snorkelling tour <br />
                      + Pick up / Drop Off<br />
                      + 7 nights at the <a href="https://www.endlesssummertenerife.com/">Endless Summer hostel</a></p>


                    <h4 className="text-center">€ 369.00</h4>
                    <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">CONTACT US</Link>
                  </motion.div>
                  <motion.div className="col-sm-6 col-lg-6 mb-4 p-3"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.4, delay: 1.9 }
                    }}
                  >
                    <img className="img-fluid w-100" src={accommodation4} alt="Scuba Point Special dives in Tenerife" />
                    <div className="sparkle"></div>
                    <h2 className="pt-4">Hostel pack 4</h2>
                    <p>What does pack 4 include?<br />
                      + The Open Water Diver Course <br />
                      + Pick up / Drop Off<br />
                      + 5 nights at the <a href="https://www.endlesssummertenerife.com/">Endless Summer hostel</a></p>

                    <h4 className="text-center">€ 469.00</h4>
                    <Link className="btn btn-outline-dark btn-lg mx-auto w-75 d-block mb-3" to="/contact_scuba_point_in_tenerife">CONTACT US</Link>
                  </motion.div>
                </Row>
              </Container>
            </motion.div >


          </div>
        </Row>
      </Container>
    </motion.div >
  )
}

export default Packages
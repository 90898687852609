import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import BookingForm from "./BookingForm"
import { Link } from "react-router-dom";
import './../css/Home.scss'
import { motion } from 'framer-motion';
// import Promo from "./../img/promo.png";
import Promo from "./../img/promo.png";
import bg from './../img/bg.jpg';
import StartHere from "./../img/StartHere.png";
import lava_cloud from './../img/lava_cloud.png';
import lava from './../img/lava.png';
import sun_fire from './../img/sun_fire.png';
import sun_face from './../img/sun_face.png';
import ferry from './../img/ferry.png';
import boat from './../img/boat.png';
import teacher_arm from './../img/teacher_arm.png';
import gonza_arm from './../img/gonza_arm.png';
import diver_arm from './../img/diver_arm.png';
import dolphin from './../img/dolphin.png';
import whale from './../img/whale.png';
import jellyfish from './../img/jellyfish.png';
import diver from './../img/diver.png';
import cliff_right from './../img/cliff_right.png';
import eel from './../img/eel.png';
import eel_mouth from './../img/eel_mouth.png';
import cliff_left from './../img/cliff_left.png';
import ray from './../img/ray.png';
import turtle from './../img/turtle.png';
import prawn from './../img/prawn.png';
import little_fish from './../img/little_fish.png';
import gold_fish from './../img/gold_fish.png';
import zebra_fish from './../img/zebra_fish.png';
import blue_fish from './../img/blue_fish.png';
import barra1 from './../img/barra1.png';
import barra2 from './../img/barra2.png';
import barra3 from './../img/barra3.png';
import barra4 from './../img/barra4.png';
import barra5 from './../img/barra5.png';
import barra6 from './../img/barra6.png';
import bask from './../img/bask.png';
import monkey_arm_1 from './../img/monkey_arm_1.png';
import monkey_arm_2 from './../img/monkey_arm_2.png';


function Home() {
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const HomeJson = {
        "@context": "http://schema.org",
        "@type": "SportsActivityLocation",
        "name": "Scuba Point Diving School",
        "url": "https://www.scuba-point.com/",
        "logo": "https://scuba-point.com/static/media/scuba-point-black.358ecd351e02c5d651eae49d0721c7e0.svg",
        "description": "Dive into Tenerife's ocean wonders with Scuba Point! From beginner to pro, enjoy top-notch scuba diving fun.",
        "telephone": "+34 633 04 48 85",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Calle el Espigón,  Los Cristianos",
            "addressLocality": "Santa Cruz de Tenerife",
            "postalCode": "38650",
            "addressCountry": "ES"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "28.291564",
            "longitude": "-16.629130"
        },
        "openingHours": "Mo, Tu, We, Th, Fr, Sa, Su 09:00-18:00",
        "sameAs": [
            "https://www.facebook.com/profile.php?id=100094764036107",
            "https://www.instagram.com/scubapointtenerife/?hl=en",
            "https://www.pinterest.co.uk/ScubaPointDivingTenerife/",
            "https://twitter.com/ScubaPointDives",
            "https://www.linkedin.com/in/scuba-point-diving-tenerife/",
            "https://www.tiktok.com/@scubapointtenerife"
        ]
    }

    const modalContent = (
        <>
            <h1 className="h5">Welcome to Scuba Point - Your Premier Destination For Diving&nbsp;Tenerife</h1>
            <p>Hello there, land-dweller! You've stumbled upon Scuba Point, the premier destination for diving in Tenerife. Now, you might be asking yourself, <strong>"Why should I trade my comfortable land legs for a pair of flippers?"</strong> Well, let's just say, we're about to open your eyes to a whole new world where the residents are slightly wetter, a bit more slippery, and a whole lot more&nbsp;colorful!</p>
            <img className="img-fluid mb-3" src={Promo} alt="Special offer on messages through the website! 10% off total." />
            <Link to="/packages_from_scuba_point_tenerife" className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" >Book Your Dive&nbsp;Today!</Link>
            <p>At Scuba Point, we love nothing more than to introduce you to our underwater neighbors. We're talking about the gentle, swaying sea anemones that dance like no one's watching, the cheeky parrotfish that never seem to stop talking (we think it's all the seaweed they eat), and the majestic sea turtles that swim with the wisdom of the ages in their&nbsp;eyes.
            </p>
            <h2 className="h5"><Link to="/about_scuba_point_diving_tenerife">Our Motto</Link>: Dive In, The Water's&nbsp;Fine!</h2>
            <p>We believe that diving isn't just about swimming alongside a few fish. It's about embracing a whole new way of life (don't worry, we won't make you grow gills). It's about discovering a hidden world of beauty and mystery right beneath the waves. It's about swapping your two-step for a dolphin kick and learning to dance with the&nbsp;currents.
            </p>
            <h2 className="h5"><Link to="/about_scuba_point_diving_tenerife">Meet</Link> Our Sea-rious&nbsp;Team</h2>
            <p>Meet our incredible team of certified dive instructors, or as we prefer to call them, the "Merfolk of Tenerife". They are the true heart of Scuba Point, dedicated to making your diving experience fun, safe, and memorable. Just don't ask them to do their mermaid impressions. Trust us on this&nbsp;one.</p>
            <h2 className="h5"><Link to="/contact_scuba_point_in_tenerife">Everyone</Link>'s Invited to the&nbsp;Party</h2>
            <p>Whether you're a fresh-faced newbie or a seasoned diving veteran, we have something for everyone. Our courses range from beginner sessions for those testing the water, all the way to advanced courses for those who are ready to dive into the deep end (literally and&nbsp;figuratively).</p>
            <h2 className="h5">Bubbling Over with&nbsp;Excitement</h2>
            <p>At <Link to="/packages/#">Scuba Point</Link>, we're always bubbling over with excitement (get it? Because bubbles… underwater… nevermind). We can't wait to share with you the wonders of diving in Tenerife. So, what are you waiting for? Get your feet wet with Scuba Point&nbsp;today!</p>
            <h2 className="h5"><Link to="/packages_from_scuba_point_tenerife">Dive Packages</Link>: Something for Every&nbsp;Budget</h2>
            <p>With our range of diving packages, we've got something to suit every budget. It's like a buffet, but instead of deciding between chicken or fish, you're choosing between experiencing the magic of the ocean or... well, not experiencing it. Not much of a choice, really, is&nbsp;it?</p>
            <h2 className="h5">Diving Safety: No&nbsp;Joke!</h2>
            <p>We may like our puns and dad jokes here at Scuba Point, but when it comes to safety, we're as serious as a shark on a diet. Our instructors are all PADI-certified and follow the highest safety standards. After all, we want you to be as comfortable underwater as a fish in... well,&nbsp;water.</p>
            <h2 className="h5"><Link to="/contact_scuba_point_in_tenerife">Contact Us</Link>: We're a Real&nbsp;Catch!</h2>
            <p>Ready to take the plunge? Dive into adventure with Scuba Point! Don't hesitate to get in touch with us, we promise we're a real catch! (And no, you won't need to bait us with worms.)</p>
            <p>So come on, dive headfirst into the world of Scuba Point. Swap your suits for wetsuits, your shoes for fins, and discover a world where every hour is happy hour, and the drinks are always on the house (well, sea water isn't technically drinkable, but you get the idea). Let's make a splash together in&nbsp;Tenerife!</p>
            <p>By the way, if you think our website made a splash, you should dive into a conversation with our business partner,&nbsp;<a rel="nofollow" href="https://www.archibaldbutler.com">Archie</a>.</p>
        </>
    );
    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Diving Tenerife | Scuba Point Diving School</title>
                <link rel="canonical" href="https://www.scuba-point.com/" />
                <meta name="description" content="Dive 🤿 into Tenerife's ocean wonders with Scuba Point! From beginner to pro, enjoy top-notch scuba fun. 💰💵 🎁 10% off exclusively on our website! 🐠🌊" />
                <meta name="keywords" content="Scuba Diving, Diving Tenerife, PADI Courses, Snorkeling" />
            </Helmet>
            <script type="application/ld+json">
                {JSON.stringify(HomeJson)}
            </script>
            <Modal show={show} style={show ? {} : { display: 'none' }} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Introduction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                {loading ? (
                    <div className='d-flex justify-content-center w-100 position-absolute top-0 left-0 right-0 bottom-0'>
                        <h1 className='d-flex justify-content-center align-items-center'>Loading...</h1>
                    </div>
                ) : (
                    <>

                        <div style={{ display: 'none' }}>
                            {modalContent}
                        </div>


                        <motion.div
                            className='home'
                            initial={{ width: "100%" }}
                            animate={{ width: "100%" }}
                            exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
                            style={{ backgroundImage: `url(${bg})` }}

                        >
                            <div className='start_here' style={{ backgroundImage: `url(${StartHere})` }} ></div>
                            <div className='lava_cloud' style={{ backgroundImage: `url(${lava_cloud})` }} ></div>
                            <div className='lava' style={{ backgroundImage: `url(${lava})` }} ></div>
                            <div className='sun_fire' style={{ backgroundImage: `url(${sun_fire})` }} ></div>
                            <div className='sun_face' style={{ backgroundImage: `url(${sun_face})` }} ></div>
                            <div className='ferry' style={{ backgroundImage: `url(${ferry})` }} ></div>
                            <div className="boat_link" onClick={handleShow}>
                                <div className='boat' style={{ backgroundImage: `url(${boat})` }} >
                                    <div className='teacher_arm' style={{ backgroundImage: `url(${teacher_arm})` }} ></div>
                                    <div className='gonza_arm' style={{ backgroundImage: `url(${gonza_arm})` }} ></div>
                                </div>
                            </div>
                            <div className='diver_arm' style={{ backgroundImage: `url(${diver_arm})` }} ></div>
                            <div className="dolphin_contain">
                                <div className="dolphin" style={{ backgroundImage: `url(${dolphin})` }} ></div>
                            </div>
                            <div className="App_city_sparkles">
                                <div className="App_sparkle s1"> </div>
                                <div className="App_sparkle s2"> </div>
                                <div className="App_sparkle s3"> </div>
                                <div className="App_sparkle s4"> </div>
                                <div className="App_sparkle s5"> </div>
                                <div className="App_sparkle s6"> </div>
                                <div className="App_sparkle s7"> </div>
                                <div className="App_sparkle s8"> </div>
                                <div className="App_sparkle s9"> </div>
                                <div className="App_sparkle s10"> </div>
                            </div>
                            <div className="whale" style={{ backgroundImage: `url(${whale})` }} ></div>
                            <div className="jellyfish" style={{ backgroundImage: `url(${jellyfish})` }} ></div>
                            <div className="diver" style={{ backgroundImage: `url(${diver})` }} ></div>
                            <div className="App_bubbles">
                                <div className="bubble x1"></div>
                                <div className="bubble x2"></div>
                                <div className="bubble x3"></div>
                                <div className="bubble x4"></div>
                                <div className="bubble x5"></div>
                                <div className="bubble x6"></div>
                                <div className="bubble x7"></div>
                                <div className="bubble x8"></div>
                                <div className="bubble x9"></div>
                                <div className="bubble x10"></div>
                                <div className="bubble x11"></div>
                                <div className="bubble x12"></div>
                                <div className="bubble x13"></div>
                                <div className="bubble x14"></div>
                                <div className="bubble x15"></div>
                                <div className="bubble x16"></div>
                                <div className="bubble x17"></div>
                                <div className="bubble x18"></div>
                                <div className="bubble x19"></div>
                                <div className="bubble x20"></div>
                                <div className="bubble x21"></div>
                                <div className="bubble x22"></div>
                                <div className="bubble x23"></div>
                                <div className="bubble x24"></div>
                                <div className="bubble x25"></div>
                                <div className="bubble x26"></div>
                                <div className="bubble x27"></div>
                                <div className="bubble x28"></div>
                                <div className="bubble x29"></div>
                                <div className="bubble x30"></div>
                                <div className="bubble x31"></div>
                                <div className="bubble x32"></div>
                                <div className="bubble x33"></div>
                                <div className="bubble x34"></div>
                                <div className="bubble x35"></div>
                                <div className="bubble x36"></div>
                                <div className="bubble x37"></div>
                                <div className="bubble x38"></div>
                                <div className="bubble x39"></div>
                                <div className="bubble x40"></div>
                            </div>
                            <div className="cliff_right" style={{ backgroundImage: `url(${cliff_right})` }} ></div>
                            <div className="eel" style={{ backgroundImage: `url(${eel})` }} >
                                <div className="eel_mouth" style={{ backgroundImage: `url(${eel_mouth})` }} ></div>
                            </div>
                            <div className="cliff_left" style={{ backgroundImage: `url(${cliff_left})` }} ></div>
                            <div className="ray" style={{ backgroundImage: `url(${ray})` }} ></div>
                            <div className="turtle" style={{ backgroundImage: `url(${turtle})` }} ></div>
                            <div className="prawn" style={{ backgroundImage: `url(${prawn})` }} ></div>
                            <div className="little_fish" style={{ backgroundImage: `url(${little_fish})` }} ></div>
                            <div className="gold_fish" style={{ backgroundImage: `url(${gold_fish})` }} ></div>
                            <div className="zebra_fish" style={{ backgroundImage: `url(${zebra_fish})` }} ></div>
                            <div className="blue_fish" style={{ backgroundImage: `url(${blue_fish})` }} ></div>
                            <div className="barra1" style={{ backgroundImage: `url(${barra1})` }} ></div>
                            <div className="barra2" style={{ backgroundImage: `url(${barra2})` }} ></div>
                            <div className="barra3" style={{ backgroundImage: `url(${barra3})` }} ></div>
                            <div className="barra4" style={{ backgroundImage: `url(${barra4})` }} ></div>
                            <div className="barra5" style={{ backgroundImage: `url(${barra5})` }} ></div>
                            <div className="barra6" style={{ backgroundImage: `url(${barra6})` }} ></div>
                            <div className="bask" style={{ backgroundImage: `url(${bask})` }} ></div>
                            <div className="monkey_arm_1" style={{ backgroundImage: `url(${monkey_arm_1})` }} ></div>
                            <div className="monkey_arm_2" style={{ backgroundImage: `url(${monkey_arm_2})` }} ></div>
                            <a href="https://archibaldbutler.com/" rel="nofollow" className="ArchibaldButler">&nbsp;</a>
                            <Link className="blink" to="/packages_from_scuba_point_tenerife"></Link>


                        </motion.div>
                    </>
                )}
            </div>



        </>
    );
}

export default Home;
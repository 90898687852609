import React, { useState } from 'react';
import { motion } from 'framer-motion'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import BookingsModal from "./BookingsModal"
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Polaroid.scss'
import thumb1 from './../img/gallery/thumb1.jpg';
import thumb2 from './../img/gallery/thumb2.jpg';
import thumb3 from './../img/gallery/thumb3.jpg';
import thumb4 from './../img/gallery/thumb4.jpg';
import thumb5 from './../img/gallery/thumb5.jpg';
import thumb6 from './../img/gallery/thumb6.jpg';
import thumb7 from './../img/gallery/thumb7.jpg';
import thumb8 from './../img/gallery/thumb8.jpg';
import thumb9 from './../img/gallery/thumb9.jpg';
import thumb10 from './../img/gallery/thumb10.jpg';
import thumb11 from './../img/gallery/thumb11.jpg';
import thumb12 from './../img/gallery/thumb12.jpg';
import thumb13 from './../img/gallery/thumb13.jpg';
import thumb14 from './../img/gallery/thumb14.jpg';
import thumb15 from './../img/gallery/thumb15.jpg';
import thumb16 from './../img/gallery/thumb16.jpg';
import thumb17 from './../img/gallery/thumb17.jpg';
import thumb18 from './../img/gallery/thumb18.jpg';
import thumb19 from './../img/gallery/thumb19.jpg';
import thumb21 from './../img/gallery/thumb21.jpg';
import thumb22 from './../img/gallery/thumb22.jpg';
import thumb23 from './../img/gallery/thumb23.jpg';
import thumb24 from './../img/gallery/thumb24.jpg';
import thumb26 from './../img/gallery/thumb26.jpg';
import thumb27 from './../img/gallery/thumb27.jpg';
import thumb28 from './../img/gallery/thumb28.jpg';
import thumb30 from './../img/gallery/thumb30.jpg';
import thumb31 from './../img/gallery/thumb31.jpg';
import thumb32 from './../img/gallery/thumb32.jpg';
import thumb33 from './../img/gallery/thumb33.jpg';
import thumb34 from './../img/gallery/thumb34.jpg';
import thumb35 from './../img/gallery/thumb35.jpg';
import thumb36 from './../img/gallery/thumb36.jpg';
import thumb37 from './../img/gallery/thumb37.jpg';
import thumb38 from './../img/gallery/thumb38.jpg';
import thumb39 from './../img/gallery/thumb39.jpg';

import thumb2024xx1 from './../img/gallery/2024/1-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx2 from './../img/gallery/2024/2-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx3 from './../img/gallery/2024/3-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx4 from './../img/gallery/2024/4-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx5 from './../img/gallery/2024/5-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx6 from './../img/gallery/2024/6-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx7 from './../img/gallery/2024/7-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx8 from './../img/gallery/2024/8-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx9 from './../img/gallery/2024/9-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx10 from './../img/gallery/2024/10-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx11 from './../img/gallery/2024/11-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx12 from './../img/gallery/2024/12-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx13 from './../img/gallery/2024/13-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx14 from './../img/gallery/2024/14-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx15 from './../img/gallery/2024/15-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx16 from './../img/gallery/2024/16-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx17 from './../img/gallery/2024/17-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx18 from './../img/gallery/2024/18-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx19 from './../img/gallery/2024/19-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx20 from './../img/gallery/2024/20-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx21 from './../img/gallery/2024/21-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx22 from './../img/gallery/2024/22-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx23 from './../img/gallery/2024/23-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx24 from './../img/gallery/2024/24-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx25 from './../img/gallery/2024/25-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx26 from './../img/gallery/2024/26-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx27 from './../img/gallery/2024/27-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx28 from './../img/gallery/2024/28-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx29 from './../img/gallery/2024/29-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx30 from './../img/gallery/2024/30-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx31 from './../img/gallery/2024/31-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx32 from './../img/gallery/2024/32-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx33 from './../img/gallery/2024/33-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx34 from './../img/gallery/2024/34-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx35 from './../img/gallery/2024/35-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx36 from './../img/gallery/2024/36-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx37 from './../img/gallery/2024/37-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx38 from './../img/gallery/2024/38-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx39 from './../img/gallery/2024/39-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx40 from './../img/gallery/2024/40-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx41 from './../img/gallery/2024/41-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx42 from './../img/gallery/2024/42-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx43 from './../img/gallery/2024/43-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx44 from './../img/gallery/2024/44-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx45 from './../img/gallery/2024/45-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx46 from './../img/gallery/2024/46-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx47 from './../img/gallery/2024/47-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx48 from './../img/gallery/2024/48-2024-scuba-point-dive-school-tenerife.jpg';
import thumb2024xx49 from './../img/gallery/2024/49-2024-scuba-point-dive-school-tenerife.jpg';

function InnerGallery() {
    //2024
    const [Modal2024XX1, setModal2024XX1] = useState(false);
    const handle2024Close1 = () => setModal2024XX1(false);
    const handle2024Show1 = () => setModal2024XX1(true);
    const [Modal2024XX2, setModal2024XX2] = useState(false);
    const handle2024Close2 = () => setModal2024XX2(false);
    const handle2024Show2 = () => setModal2024XX2(true);
    const [Modal2024XX3, setModal2024XX3] = useState(false);
    const handle2024Close3 = () => setModal2024XX3(false);
    const handle2024Show3 = () => setModal2024XX3(true);
    const [Modal2024XX4, setModal2024XX4] = useState(false);
    const handle2024Close4 = () => setModal2024XX4(false);
    const handle2024Show4 = () => setModal2024XX4(true);
    const [Modal2024XX5, setModal2024XX5] = useState(false);
    const handle2024Close5 = () => setModal2024XX5(false);
    const handle2024Show5 = () => setModal2024XX5(true);
    const [Modal2024XX6, setModal2024XX6] = useState(false);
    const handle2024Close6 = () => setModal2024XX6(false);
    const handle2024Show6 = () => setModal2024XX6(true);
    const [Modal2024XX7, setModal2024XX7] = useState(false);
    const handle2024Close7 = () => setModal2024XX7(false);
    const handle2024Show7 = () => setModal2024XX7(true);
    const [Modal2024XX8, setModal2024XX8] = useState(false);
    const handle2024Close8 = () => setModal2024XX8(false);
    const handle2024Show8 = () => setModal2024XX8(true);
    const [Modal2024XX9, setModal2024XX9] = useState(false);
    const handle2024Close9 = () => setModal2024XX9(false);
    const handle2024Show9 = () => setModal2024XX9(true);
    const [Modal2024XX10, setModal2024XX10] = useState(false);
    const handle2024Close10 = () => setModal2024XX10(false);
    const handle2024Show10 = () => setModal2024XX10(true);
    const [Modal2024XX11, setModal2024XX11] = useState(false);
    const handle2024Close11 = () => setModal2024XX11(false);
    const handle2024Show11 = () => setModal2024XX11(true);
    const [Modal2024XX12, setModal2024XX12] = useState(false);
    const handle2024Close12 = () => setModal2024XX12(false);
    const handle2024Show12 = () => setModal2024XX12(true);
    const [Modal2024XX13, setModal2024XX13] = useState(false);
    const handle2024Close13 = () => setModal2024XX13(false);
    const handle2024Show13 = () => setModal2024XX13(true);
    const [Modal2024XX14, setModal2024XX14] = useState(false);
    const handle2024Close14 = () => setModal2024XX14(false);
    const handle2024Show14 = () => setModal2024XX14(true);
    const [Modal2024XX15, setModal2024XX15] = useState(false);
    const handle2024Close15 = () => setModal2024XX15(false);
    const handle2024Show15 = () => setModal2024XX15(true);
    const [Modal2024XX16, setModal2024XX16] = useState(false);
    const handle2024Close16 = () => setModal2024XX16(false);
    const handle2024Show16 = () => setModal2024XX16(true);
    const [Modal2024XX17, setModal2024XX17] = useState(false);
    const handle2024Close17 = () => setModal2024XX17(false);
    const handle2024Show17 = () => setModal2024XX17(true);
    const [Modal2024XX18, setModal2024XX18] = useState(false);
    const handle2024Close18 = () => setModal2024XX18(false);
    const handle2024Show18 = () => setModal2024XX18(true);
    const [Modal2024XX19, setModal2024XX19] = useState(false);
    const handle2024Close19 = () => setModal2024XX19(false);
    const handle2024Show19 = () => setModal2024XX19(true);
    const [Modal2024XX20, setModal2024XX20] = useState(false);
    const handle2024Close20 = () => setModal2024XX20(false);
    const handle2024Show20 = () => setModal2024XX20(true);
    const [Modal2024XX21, setModal2024XX21] = useState(false);
    const handle2024Close21 = () => setModal2024XX21(false);
    const handle2024Show21 = () => setModal2024XX21(true);
    const [Modal2024XX22, setModal2024XX22] = useState(false);
    const handle2024Close22 = () => setModal2024XX22(false);
    const handle2024Show22 = () => setModal2024XX22(true);
    const [Modal2024XX23, setModal2024XX23] = useState(false);
    const handle2024Close23 = () => setModal2024XX23(false);
    const handle2024Show23 = () => setModal2024XX23(true);
    const [Modal2024XX24, setModal2024XX24] = useState(false);
    const handle2024Close24 = () => setModal2024XX24(false);
    const handle2024Show24 = () => setModal2024XX24(true);
    const [Modal2024XX25, setModal2024XX25] = useState(false);
    const handle2024Close25 = () => setModal2024XX25(false);
    const handle2024Show25 = () => setModal2024XX25(true);
    const [Modal2024XX26, setModal2024XX26] = useState(false);
    const handle2024Close26 = () => setModal2024XX26(false);
    const handle2024Show26 = () => setModal2024XX26(true);
    const [Modal2024XX27, setModal2024XX27] = useState(false);
    const handle2024Close27 = () => setModal2024XX27(false);
    const handle2024Show27 = () => setModal2024XX27(true);
    const [Modal2024XX28, setModal2024XX28] = useState(false);
    const handle2024Close28 = () => setModal2024XX28(false);
    const handle2024Show28 = () => setModal2024XX28(true);
    const [Modal2024XX29, setModal2024XX29] = useState(false);
    const handle2024Close29 = () => setModal2024XX29(false);
    const handle2024Show29 = () => setModal2024XX29(true);
    const [Modal2024XX30, setModal2024XX30] = useState(false);
    const handle2024Close30 = () => setModal2024XX30(false);
    const handle2024Show30 = () => setModal2024XX30(true);
    const [Modal2024XX31, setModal2024XX31] = useState(false);
    const handle2024Close31 = () => setModal2024XX31(false);
    const handle2024Show31 = () => setModal2024XX31(true);
    const [Modal2024XX32, setModal2024XX32] = useState(false);
    const handle2024Close32 = () => setModal2024XX32(false);
    const handle2024Show32 = () => setModal2024XX32(true);
    const [Modal2024XX33, setModal2024XX33] = useState(false);
    const handle2024Close33 = () => setModal2024XX33(false);
    const handle2024Show33 = () => setModal2024XX33(true);
    const [Modal2024XX34, setModal2024XX34] = useState(false);
    const handle2024Close34 = () => setModal2024XX34(false);
    const handle2024Show34 = () => setModal2024XX34(true);
    const [Modal2024XX35, setModal2024XX35] = useState(false);
    const handle2024Close35 = () => setModal2024XX35(false);
    const handle2024Show35 = () => setModal2024XX35(true);
    const [Modal2024XX36, setModal2024XX36] = useState(false);
    const handle2024Close36 = () => setModal2024XX36(false);
    const handle2024Show36 = () => setModal2024XX36(true);
    const [Modal2024XX37, setModal2024XX37] = useState(false);
    const handle2024Close37 = () => setModal2024XX37(false);
    const handle2024Show37 = () => setModal2024XX37(true);
    const [Modal2024XX38, setModal2024XX38] = useState(false);
    const handle2024Close38 = () => setModal2024XX38(false);
    const handle2024Show38 = () => setModal2024XX38(true);
    const [Modal2024XX39, setModal2024XX39] = useState(false);
    const handle2024Close39 = () => setModal2024XX39(false);
    const handle2024Show39 = () => setModal2024XX39(true);
    const [Modal2024XX40, setModal2024XX40] = useState(false);
    const handle2024Close40 = () => setModal2024XX40(false);
    const handle2024Show40 = () => setModal2024XX40(true);
    const [Modal2024XX41, setModal2024XX41] = useState(false);
    const handle2024Close41 = () => setModal2024XX41(false);
    const handle2024Show41 = () => setModal2024XX41(true);
    const [Modal2024XX42, setModal2024XX42] = useState(false);
    const handle2024Close42 = () => setModal2024XX42(false);
    const handle2024Show42 = () => setModal2024XX42(true);
    const [Modal2024XX43, setModal2024XX43] = useState(false);
    const handle2024Close43 = () => setModal2024XX43(false);
    const handle2024Show43 = () => setModal2024XX43(true);
    const [Modal2024XX44, setModal2024XX44] = useState(false);
    const handle2024Close44 = () => setModal2024XX44(false);
    const handle2024Show44 = () => setModal2024XX44(true);
    const [Modal2024XX45, setModal2024XX45] = useState(false);
    const handle2024Close45 = () => setModal2024XX45(false);
    const handle2024Show45 = () => setModal2024XX45(true);
    const [Modal2024XX46, setModal2024XX46] = useState(false);
    const handle2024Close46 = () => setModal2024XX46(false);
    const handle2024Show46 = () => setModal2024XX46(true);
    const [Modal2024XX47, setModal2024XX47] = useState(false);
    const handle2024Close47 = () => setModal2024XX47(false);
    const handle2024Show47 = () => setModal2024XX47(true);
    const [Modal2024XX48, setModal2024XX48] = useState(false);
    const handle2024Close48 = () => setModal2024XX48(false);
    const handle2024Show48 = () => setModal2024XX48(true);
    const [Modal2024XX49, setModal2024XX49] = useState(false);
    const handle2024Close49 = () => setModal2024XX49(false);
    const handle2024Show49 = () => setModal2024XX49(true);

    //older
    const [Modal1, setModal1] = useState(false);
    const handleClose1 = () => setModal1(false);
    const handleShow1 = () => setModal1(true);
    const [Modal2, setModal2] = useState(false);
    const handleClose2 = () => setModal2(false);
    const handleShow2 = () => setModal2(true);
    const [Modal3, setModal3] = useState(false);
    const handleClose3 = () => setModal3(false);
    const handleShow3 = () => setModal3(true);
    const [Modal4, setModal4] = useState(false);
    const handleClose4 = () => setModal4(false);
    const handleShow4 = () => setModal4(true);
    const [Modal5, setModal5] = useState(false);
    const handleClose5 = () => setModal5(false);
    const handleShow5 = () => setModal5(true);
    const [Modal6, setModal6] = useState(false);
    const handleClose6 = () => setModal6(false);
    const handleShow6 = () => setModal6(true);
    const [Modal7, setModal7] = useState(false);
    const handleClose7 = () => setModal7(false);
    const handleShow7 = () => setModal7(true);
    const [Modal8, setModal8] = useState(false);
    const handleClose8 = () => setModal8(false);
    const handleShow8 = () => setModal8(true);
    const [Modal9, setModal9] = useState(false);
    const handleClose9 = () => setModal9(false);
    const handleShow9 = () => setModal9(true);
    const [Modal10, setModal10] = useState(false);
    const handleClose10 = () => setModal10(false);
    const handleShow10 = () => setModal10(true);
    const [Modal11, setModal11] = useState(false);
    const handleClose11 = () => setModal11(false);
    const handleShow11 = () => setModal11(true);
    const [Modal12, setModal12] = useState(false);
    const handleClose12 = () => setModal12(false);
    const handleShow12 = () => setModal12(true);
    const [Modal13, setModal13] = useState(false);
    const handleClose13 = () => setModal13(false);
    const handleShow13 = () => setModal13(true);
    const [Modal14, setModal14] = useState(false);
    const handleClose14 = () => setModal14(false);
    const handleShow14 = () => setModal14(true);
    const [Modal15, setModal15] = useState(false);
    const handleClose15 = () => setModal15(false);
    const handleShow15 = () => setModal15(true);
    const [Modal16, setModal16] = useState(false);
    const handleClose16 = () => setModal16(false);
    const handleShow16 = () => setModal16(true);
    const [Modal17, setModal17] = useState(false);
    const handleClose17 = () => setModal17(false);
    const handleShow17 = () => setModal17(true);
    const [Modal18, setModal18] = useState(false);
    const handleClose18 = () => setModal18(false);
    const handleShow18 = () => setModal18(true);
    const [Modal19, setModal19] = useState(false);
    const handleClose19 = () => setModal19(false);
    const handleShow19 = () => setModal19(true);
    // const [Modal20, setModal20] = useState(false);
    // const handleClose20 = () => setModal20(false);
    // const handleShow20 = () => setModal20(true);
    const [Modal21, setModal21] = useState(false);
    const handleClose21 = () => setModal21(false);
    const handleShow21 = () => setModal21(true);
    const [Modal22, setModal22] = useState(false);
    const handleClose22 = () => setModal22(false);
    const handleShow22 = () => setModal22(true);
    const [Modal23, setModal23] = useState(false);
    const handleClose23 = () => setModal23(false);
    const handleShow23 = () => setModal23(true);
    const [Modal24, setModal24] = useState(false);
    const handleClose24 = () => setModal24(false);
    const handleShow24 = () => setModal24(true);
    // const [Modal25, setModal25] = useState(false);
    // const handleClose25 = () => setModal25(false);
    // const handleShow25 = () => setModal25(true);
    const [Modal26, setModal26] = useState(false);
    const handleClose26 = () => setModal26(false);
    const handleShow26 = () => setModal26(true);
    const [Modal27, setModal27] = useState(false);
    const handleClose27 = () => setModal27(false);
    const handleShow27 = () => setModal27(true);
    const [Modal28, setModal28] = useState(false);
    const handleClose28 = () => setModal28(false);
    const handleShow28 = () => setModal28(true);
    // const [Modal29, setModal29] = useState(false);
    // const handleClose29 = () => setModal29(false);
    // const handleShow29 = () => setModal29(true);
    const [Modal30, setModal30] = useState(false);
    const handleClose30 = () => setModal30(false);
    const handleShow30 = () => setModal30(true);
    const [Modal31, setModal31] = useState(false);
    const handleClose31 = () => setModal31(false);
    const handleShow31 = () => setModal31(true);
    const [Modal32, setModal32] = useState(false);
    const handleClose32 = () => setModal32(false);
    const handleShow32 = () => setModal32(true);
    const [Modal33, setModal33] = useState(false);
    const handleClose33 = () => setModal33(false);
    const handleShow33 = () => setModal33(true);
    const [Modal34, setModal34] = useState(false);
    const handleClose34 = () => setModal34(false);
    const handleShow34 = () => setModal34(true);
    const [Modal35, setModal35] = useState(false);
    const handleClose35 = () => setModal35(false);
    const handleShow35 = () => setModal35(true);
    const [Modal36, setModal36] = useState(false);
    const handleClose36 = () => setModal36(false);
    const handleShow36 = () => setModal36(true);
    const [Modal37, setModal37] = useState(false);
    const handleClose37 = () => setModal37(false);
    const handleShow37 = () => setModal37(true);
    const [Modal38, setModal38] = useState(false);
    const handleClose38 = () => setModal38(false);
    const handleShow38 = () => setModal38(true);
    const [Modal39, setModal39] = useState(false);
    const handleClose39 = () => setModal39(false);
    const handleShow39 = () => setModal39(true);




    return (
        <motion.div
            className='mb-2 col-12'
            initial={{ opacity: 0 }}
            animate={{
                y: 20,
                opacity: 1,
                position: "relative",
                transition: { duration: 0.5, delay: 0.5 }
            }}
        >
            <Container>
                <Row>
                    <h3 className="">2024 New photos</h3>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show1} src={thumb2024xx1} alt="Gonza rises with his new crew after a dive around the Pal Mar Wall" />
                            <Modal
                                show={Modal2024XX1}
                                onHide={handle2024Close1}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Gonza rises with his new crew after a dive around the Pal Mar Wall</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx1} alt="Gonza rises with his new crew after a dive around the Pal Mar Wall" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show2} src={thumb2024xx2} alt="Gonza on a boat around Pal Mar wall" />
                            <Modal
                                show={Modal2024XX2}
                                onHide={handle2024Close2}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Gonza on a boat around Pal Mar wall</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx2} alt="Gonza on a boat around Pal Mar wall" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show3} src={thumb2024xx3} alt="Gonza driving the diving boat" />
                            <Modal
                                show={Modal2024XX3}
                                onHide={handle2024Close3}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Gonza driving the diving boat</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx3} alt="Gonza driving the diving boat" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show4} src={thumb2024xx4} alt="Gonza in the clear waters around the Pal Mar cliff" />
                            <Modal
                                show={Modal2024XX4}
                                onHide={handle2024Close4}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Gonza in the clear waters around the Pal Mar cliff</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx4} alt="Gonza in the clear waters around the Pal Mar cliff" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show5} src={thumb2024xx5} alt="A European turtle, you will probably see these!" />
                            <Modal
                                show={Modal2024XX5}
                                onHide={handle2024Close5}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A European turtle, you will probably see these!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx5} alt="A European turtle, you will probably see these!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show6} src={thumb2024xx6} alt="Young girl snorkeling  in Tenerife" />
                            <Modal
                                show={Modal2024XX6}
                                onHide={handle2024Close6}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Young girl snorkeling  in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx6} alt="Young girl snorkeling  in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show7} src={thumb2024xx7} alt="Dives around the Pal Mar caves" />
                            <Modal
                                show={Modal2024XX7}
                                onHide={handle2024Close7}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Dives around the Pal Mar caves</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx7} alt="Dives around the Pal Mar caves" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show8} src={thumb2024xx8} alt="Dives around the Pal Mar caves" />
                            <Modal
                                show={Modal2024XX8}
                                onHide={handle2024Close8}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Dives around the Pal Mar caves</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx8} alt="Dives around the Pal Mar caves" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show9} src={thumb2024xx9} alt="Dives around the Pal Mar caves" />
                            <Modal
                                show={Modal2024XX9}
                                onHide={handle2024Close9}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Dives around the Pal Mar caves</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx9} alt="Dives around the Pal Mar caves" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show10} src={thumb2024xx10} alt="Dives around the Pal Mar caves" />
                            <Modal
                                show={Modal2024XX10}
                                onHide={handle2024Close10}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Dives around the Pal Mar caves</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx10} alt="Dives around the Pal Mar caves" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show11} src={thumb2024xx11} alt="First dives for new visitors on Las Vistas Beach" />
                            <Modal
                                show={Modal2024XX11}
                                onHide={handle2024Close11}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>First dives for new visitors on Las Vistas Beach</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx11} alt="First dives for new visitors on Las Vistas Beach" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show12} src={thumb2024xx12} alt="Las Vistas is where we do the first beginner dive" />
                            <Modal
                                show={Modal2024XX12}
                                onHide={handle2024Close12}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Las Vistas is where we do the first beginner dive</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx12} alt="Las Vistas is where we do the first beginner dive" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show13} src={thumb2024xx13} alt="Putting gear on at Las Vistas beach in Tenerife" />
                            <Modal
                                show={Modal2024XX13}
                                onHide={handle2024Close13}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Putting gear on at Las Vistas beach in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx13} alt="Putting gear on at Las Vistas beach in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show14} src={thumb2024xx14} alt="A group of new young divers trying it out with Scuba Point!" />
                            <Modal
                                show={Modal2024XX14}
                                onHide={handle2024Close14}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A group of new young divers trying it out with Scuba Point!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx14} alt="A group of new young divers trying it out with Scuba Point!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show15} src={thumb2024xx15} alt="Two divers about to dive at the Pal Mar caves where there is lots of sea life" />
                            <Modal
                                show={Modal2024XX15}
                                onHide={handle2024Close15}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two divers about to dive at the Pal Mar caves where there is lots of sea life</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx15} alt="Two divers about to dive at the Pal Mar caves where there is lots of sea life" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show16} src={thumb2024xx16} alt="One of the new divers checking out the fish" />
                            <Modal
                                show={Modal2024XX16}
                                onHide={handle2024Close16}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>One of the new divers checking out the fish</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx16} alt="One of the new divers checking out the fish" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show17} src={thumb2024xx17} alt="Training beginners at Las Vistas beach in Tenerife" />
                            <Modal
                                show={Modal2024XX17}
                                onHide={handle2024Close17}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Training beginners at Las Vistas beach in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx17} alt="Training beginners at Las Vistas beach in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show18} src={thumb2024xx18} alt="Three new divers swimming through the coral of Tenerife" />
                            <Modal
                                show={Modal2024XX18}
                                onHide={handle2024Close18}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Three new divers swimming through the coral of Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx18} alt="Three new divers swimming through the coral of Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show19} src={thumb2024xx19} alt="More turtles! We love these guys!" />
                            <Modal
                                show={Modal2024XX19}
                                onHide={handle2024Close19}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>More turtles! We love these guys!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx19} alt="More turtles! We love these guys!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show20} src={thumb2024xx20} alt="A friendly diver swimming with the Tenerife turtles" />
                            <Modal
                                show={Modal2024XX20}
                                onHide={handle2024Close20}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A friendly diver swimming with the Tenerife turtles</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx20} alt="A friendly diver swimming with the Tenerife turtles" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show21} src={thumb2024xx21} alt="A diver saying let's go up now in diving sign language!" />
                            <Modal
                                show={Modal2024XX21}
                                onHide={handle2024Close21}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A diver saying let's go up now in diving sign language!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx21} alt="A diver saying let's go up now in diving sign language!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show22} src={thumb2024xx22} alt="Punky scuba diver descending through the fish in Tenerife!" />
                            <Modal
                                show={Modal2024XX22}
                                onHide={handle2024Close22}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Punky scuba diver descending through the fish in Tenerife!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx22} alt="Punky scuba diver descending through the fish in Tenerife!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show23} src={thumb2024xx23} alt="Some young friends trying out diving in Las Vistas Beach" />
                            <Modal
                                show={Modal2024XX23}
                                onHide={handle2024Close23}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Some young friends trying out diving in Las Vistas Beach</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx23} alt="Some young friends trying out diving in Las Vistas Beach" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show24} src={thumb2024xx24} alt="Two buddies doing their test dive in Las Vistas Beach" />
                            <Modal
                                show={Modal2024XX24}
                                onHide={handle2024Close24}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two buddies doing their test dive in Las Vistas Beach</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx24} alt="Two buddies doing their test dive in Las Vistas Beach" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show25} src={thumb2024xx25} alt="A Scuba Point diver passing by some reef rocks" />
                            <Modal
                                show={Modal2024XX25}
                                onHide={handle2024Close25}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A Scuba Point diver passing by some reef rocks</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx25} alt="A Scuba Point diver passing by some reef rocks" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>

                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show26} src={thumb2024xx26} alt="Two buddies who are about to dive in Las Vistas" />
                            <Modal
                                show={Modal2024XX26}
                                onHide={handle2024Close26}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two buddies who are about to dive in Las Vistas</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx26} alt="Two buddies who are about to dive in Las Vistas" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show27} src={thumb2024xx27} alt="Visitor about to embark on his first dive in Tenerife" />
                            <Modal
                                show={Modal2024XX27}
                                onHide={handle2024Close27}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Visitor about to embark on his first dive in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx27} alt="Visitor about to embark on his first dive in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show28} src={thumb2024xx28} alt="Young chap about to dive!" />
                            <Modal
                                show={Modal2024XX28}
                                onHide={handle2024Close28}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Young chap about to dive!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx28} alt="Young chap about to dive!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show29} src={thumb2024xx29} alt="Four buddies about to go on an undertwater adventure!" />
                            <Modal
                                show={Modal2024XX29}
                                onHide={handle2024Close29}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Four buddies about to go on an undertwater adventure!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx29} alt="Four buddies about to go on an undertwater adventure!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show30} src={thumb2024xx30} alt="Two young chaps about to do a beginner dive, notice the blue sky!" />
                            <Modal
                                show={Modal2024XX30}
                                onHide={handle2024Close30}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two young chaps about to do a beginner dive, notice the blue sky!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx30} alt="Two young chaps about to do a beginner dive, notice the blue sky!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show31} src={thumb2024xx31} alt="Underwater fun with friends" />
                            <Modal
                                show={Modal2024XX31}
                                onHide={handle2024Close31}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Underwater fun with friends</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx31} alt="Underwater fun with friends" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show32} src={thumb2024xx32} alt="more underwater fun with friends" />
                            <Modal
                                show={Modal2024XX32}
                                onHide={handle2024Close32}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>more underwater fun with friends</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx32} alt="more underwater fun with friends" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show33} src={thumb2024xx33} alt="Three young divers about to race some fish in Las Vistas" />
                            <Modal
                                show={Modal2024XX33}
                                onHide={handle2024Close33}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Three young divers about to race some fish in Las Vistas</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx33} alt="Three young divers about to race some fish in Las Vistas" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show34} src={thumb2024xx34} alt="A couple with big smiles after diving" />
                            <Modal
                                show={Modal2024XX34}
                                onHide={handle2024Close34}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A couple with big smiles after diving</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx34} alt="A couple with big smiles after diving" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show35} src={thumb2024xx35} alt="Snorkeling ladies having lots of fun" />
                            <Modal
                                show={Modal2024XX35}
                                onHide={handle2024Close35}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Snorkeling ladies having lots of fun</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx35} alt="Snorkeling ladies having lots of fun" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show36} src={thumb2024xx36} alt="More snorkeling ladies having lots of fun" />
                            <Modal
                                show={Modal2024XX36}
                                onHide={handle2024Close36}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>More snorkeling ladies having lots of fun</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx36} alt="More snorkeling ladies having lots of fun" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show37} src={thumb2024xx37} alt="Snorkeling ladies taking selfies around Pal Mar" />
                            <Modal
                                show={Modal2024XX37}
                                onHide={handle2024Close37}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Snorkeling ladies taking selfies around Pal Mar</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx37} alt="Snorkeling ladies taking selfies around Pal Mar" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show38} src={thumb2024xx38} alt="A parent snorkeling with child around Pal Mar, great sea life there!" />
                            <Modal
                                show={Modal2024XX38}
                                onHide={handle2024Close38}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A parent snorkeling with child around Pal Mar, great sea life there!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx38} alt="A parent snorkeling with child around Pal Mar, great sea life there!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show39} src={thumb2024xx39} alt="Three kids having a blast snorkeling around Pal Mar!" />
                            <Modal
                                show={Modal2024XX39}
                                onHide={handle2024Close39}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Three kids having a blast snorkeling around Pal Mar!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx39} alt="Three kids having a blast snorkeling around Pal Mar!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show40} src={thumb2024xx40} alt="Three friends snorkel above the reef in Tenerife" />
                            <Modal
                                show={Modal2024XX40}
                                onHide={handle2024Close40}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Three friends snorkel above the reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx40} alt="Three friends snorkel above the reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show41} src={thumb2024xx41} alt="Three friends snorkel above the reef in Tenerife and take a selfie" />
                            <Modal
                                show={Modal2024XX41}
                                onHide={handle2024Close41}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Three friends snorkel above the reef in Tenerife and take a selfie</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx41} alt="Three friends snorkel above the reef in Tenerife and take a selfie" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show42} src={thumb2024xx42} alt="Our new divers on a boat trip to dive around Pal Mar" />
                            <Modal
                                show={Modal2024XX42}
                                onHide={handle2024Close42}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Our new divers on a boat trip to dive around Pal Mar</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx42} alt="Our new divers on a boat trip to dive around Pal Mar" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show43} src={thumb2024xx43} alt="New diver giving the peace sign underwater" />
                            <Modal
                                show={Modal2024XX43}
                                onHide={handle2024Close43}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>New diver giving the peace sign underwater</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx43} alt="New diver giving the peace sign underwater" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show44} src={thumb2024xx44} alt="A big crew of divers coming back from a boat trip" />
                            <Modal
                                show={Modal2024XX44}
                                onHide={handle2024Close44}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A big crew of divers coming back from a boat trip</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx44} alt="A big crew of divers coming back from a boat trip" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show45} src={thumb2024xx45} alt="Some Scuba Point divers enjoying the fish show on the volcanic reef" />
                            <Modal
                                show={Modal2024XX45}
                                onHide={handle2024Close45}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Some Scuba Point divers enjoying the fish show on the volcanic reef</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx45} alt="Some Scuba Point divers enjoying the fish show on the volcanic reef" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show46} src={thumb2024xx46} alt="Young diver giving the I'm OK sign" />
                            <Modal
                                show={Modal2024XX46}
                                onHide={handle2024Close46}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Young diver giving the I'm OK sign</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx46} alt="Young diver giving the I'm OK sign" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show47} src={thumb2024xx47} alt="Snorkeling kid having a blast!" />
                            <Modal
                                show={Modal2024XX47}
                                onHide={handle2024Close47}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Snorkeling kid having a blast!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx47} alt="Snorkeling kid having a blast!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show48} src={thumb2024xx48} alt="A new diving customer giving the OK sign" />
                            <Modal
                                show={Modal2024XX48}
                                onHide={handle2024Close48}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A new diving customer giving the OK sign</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx48} alt="A new diving customer giving the OK sign" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handle2024Show49} src={thumb2024xx49} alt="Scuba Point diver showing the heart sign underwater, basically saying he loves it!" />
                            <Modal
                                show={Modal2024XX49}
                                onHide={handle2024Close49}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Scuba Point diver showing the heart sign underwater, basically saying he loves it!</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2024xx49} alt="Scuba Point diver showing the heart sign underwater, basically saying he loves it!" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>





















                    <h3 className="">Older than 2024</h3>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow1} src={thumb1} alt="Three scuba divers on La Vista beach in sunny Tenerife" />
                            <Modal
                                show={Modal1}
                                onHide={handleClose1}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Three scuba divers on La Vista beach in sunny Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb1} alt="Three scuba divers on La Vista beach in sunny Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow2} src={thumb2} alt="3 underwater scuba divers in Tenerife" />
                            <Modal
                                show={Modal2}
                                onHide={handleClose2}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>3 underwater scuba divers in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb2} alt="3 underwater scuba divers in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow3} src={thumb3} alt="Two scuba divers on La Vista beach in sunny Tenerife" />
                            <Modal
                                show={Modal3}
                                onHide={handleClose3}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two scuba divers on La Vista beach in sunny Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb3} alt="Two scuba divers on La Vista beach in sunny Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow4} src={thumb4} alt="Gonza on a boat in Los Cristianos with his divers" />
                            <Modal
                                show={Modal4}
                                onHide={handleClose4}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Gonza on a boat in Los Cristianos with his divers</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb4} alt="Gonza on a boat in Los Cristianos with his divers" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow5} src={thumb5} alt="Snorkelers on a boat in Los Cristianos" />
                            <Modal
                                show={Modal5}
                                onHide={handleClose5}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Snorkelers on a boat in Los Cristianos</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb5} alt="Snorkelers on a boat in Los Cristianos" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow6} src={thumb6} alt="Young boy snorkeling  in Tenerife" />
                            <Modal
                                show={Modal6}
                                onHide={handleClose6}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Young boy snorkeling  in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb6} alt="Young boy snorkeling  in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow7} src={thumb7} alt="Turtles and Scuba divers underwater in Tenerife" />
                            <Modal
                                show={Modal7}
                                onHide={handleClose7}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Turtles and Scuba divers underwater in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb7} alt="Turtles and Scuba divers underwater in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow8} src={thumb8} alt="Boy snorkeling in Tenerife" />
                            <Modal
                                show={Modal8}
                                onHide={handleClose8}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Boy snorkeling in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb8} alt="Boy snorkeling in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow9} src={thumb9} alt="Two boys snorkeling in Tenerife" />
                            <Modal
                                show={Modal9}
                                onHide={handleClose9}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two boys snorkeling in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb9} alt="Two boys snorkeling in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow10} src={thumb10} alt="A man and his son snorkeling off the coast of Los Cristianos in Tenerife" />
                            <Modal
                                show={Modal10}
                                onHide={handleClose10}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A man and his son snorkeling off the coast of Los Cristianos in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb10} alt="A man and his son snorkeling off the coast of Los Cristianos in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow11} src={thumb11} alt="Captain on a boat with his son going on a Scuba diving expedition in Tenerife" />
                            <Modal
                                show={Modal11}
                                onHide={handleClose11}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Captain on a boat with his son going on a Scuba diving expedition in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb11} alt="Captain on a boat with his son going on a Scuba diving expedition in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow12} src={thumb12} alt="Girl scuba diving deepwater off the coast of Los Cristianos in Tenerife" />
                            <Modal
                                show={Modal12}
                                onHide={handleClose12}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Girl scuba diving deepwater off the coast of Los Cristianos in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb12} alt="Girl scuba diving deepwater off the coast of Los Cristianos in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow13} src={thumb13} alt="Young boy scuba diving in Tenerife" />
                            <Modal
                                show={Modal13}
                                onHide={handleClose13}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Young boy scuba diving in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb13} alt="Young boy scuba diving in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow14} src={thumb14} alt="New scuba divers training in La Vista Beach taking a group shot on land" />
                            <Modal
                                show={Modal14}
                                onHide={handleClose14}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>New scuba divers training in La Vista Beach taking a group shot on land</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb14} alt="New scuba divers training in La Vista Beach taking a group shot on land" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow15} src={thumb15} alt="Woman scuba diving on the reef in Tenerife" />
                            <Modal
                                show={Modal15}
                                onHide={handleClose15}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Woman scuba diving on the reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb15} alt="Woman scuba diving on the reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow16} src={thumb16} alt="Man scuba diving across the reef in Tenerife" />
                            <Modal
                                show={Modal16}
                                onHide={handleClose16}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Man scuba diving across the reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb16} alt="Man scuba diving across the reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow17} src={thumb17} alt="Woman scuba diving above underwater boulders in Tenerife" />
                            <Modal
                                show={Modal17}
                                onHide={handleClose17}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Woman scuba diving above underwater boulders in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb17} alt="Woman scuba diving above underwater boulders in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow18} src={thumb18} alt="Woman scuba diving looking at rock formations and reef in Tenerife" />
                            <Modal
                                show={Modal18}
                                onHide={handleClose18}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Woman scuba diving looking at rock formations and reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb18} alt="Woman scuba diving looking at rock formations and reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow19} src={thumb19} alt="New scuba divers at the ferry port parking of Los Cristianos in Calle El Espigon" />
                            <Modal
                                show={Modal19}
                                onHide={handleClose19}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>New scuba divers at the ferry port parking of Los Cristianos in Calle El Espigon</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb19} alt="New scuba divers at the ferry port parking of Los Cristianos in Calle El Espigon" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow21} src={thumb21} alt="Diver using sign language underwater in Tenerife" />
                            <Modal
                                show={Modal21}
                                onHide={handleClose21}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Diver using sign language underwater in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb21} alt="Diver using sign language underwater in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow22} src={thumb22} alt="Woman scuba diver descending roap under boat in Tenerife" />
                            <Modal
                                show={Modal22}
                                onHide={handleClose22}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Woman scuba diver descending roap under boat in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb22} alt="Woman scuba diver descending roap under boat in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow23} src={thumb23} alt="Woman saying all is OK in sign language underwater in Tenerife" />
                            <Modal
                                show={Modal23}
                                onHide={handleClose23}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Woman saying all is OK in sign language underwater in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb23} alt="Woman saying all is OK in sign language underwater in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow24} src={thumb24} alt="A wild sea turtle on the sand underwater in Tenerife" />
                            <Modal
                                show={Modal24}
                                onHide={handleClose24}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A wild sea turtle on the sand underwater in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb24} alt="A wild sea turtle on the sand underwater in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>

                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow26} src={thumb26} alt="Female scuba diver touching the sand underwater in playa de la Vista" />
                            <Modal
                                show={Modal26}
                                onHide={handleClose26}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Female scuba diver touching the sand underwater in playa de la Vista</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb26} alt="Female scuba diver touching the sand underwater in playa de la Vista" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow27} src={thumb27} alt="Female diver communicating underwater in Tenerife" />
                            <Modal
                                show={Modal27}
                                onHide={handleClose27}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Female diver communicating underwater in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb27} alt="Female diver communicating underwater in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow28} src={thumb28} alt="Another female diver saying ok underwater in Tenerife" />
                            <Modal
                                show={Modal28}
                                onHide={handleClose28}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Another female diver saying ok underwater in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb28} alt="Another female diver saying ok underwater in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow30} src={thumb30} alt="Scuba diver lying on his back on the reef in Tenerife" />
                            <Modal
                                show={Modal30}
                                onHide={handleClose30}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Scuba diver lying on his back on the reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb30} alt="Scuba diver lying on his back on the reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow31} src={thumb31} alt="Two scuba divers kneeling on the reef in Tenerife" />
                            <Modal
                                show={Modal31}
                                onHide={handleClose31}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two scuba divers kneeling on the reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb31} alt="Two scuba divers kneeling on the reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow32} src={thumb32} alt="A turtle under a rock on the reef in Tenerife" />
                            <Modal
                                show={Modal32}
                                onHide={handleClose32}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A turtle under a rock on the reef in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb32} alt="A turtle under a rock on the reef in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow33} src={thumb33} alt="A scuba diver in meditating position on the reef bottom in Tenerife" />
                            <Modal
                                show={Modal33}
                                onHide={handleClose33}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A scuba diver in meditating position on the reef bottom in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb33} alt="A scuba diver in meditating position on the reef bottom in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow34} src={thumb34} alt="A scuba diver visiting the ship wreck along the cliffs near Los Cristianos" />
                            <Modal
                                show={Modal34}
                                onHide={handleClose34}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>A scuba diver visiting the ship wreck along the cliffs near Los Cristianos</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb34} alt="A scuba diver visiting the ship wreck along the cliffs near Los Cristianos" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow35} src={thumb35} alt="Scuba diver swimming alongside a turtle in Tenerife" />
                            <Modal
                                show={Modal35}
                                onHide={handleClose35}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Scuba diver swimming alongside a turtle in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb35} alt="Scuba diver swimming alongside a turtle in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow36} src={thumb36} alt="Gonza and his daughter Moana snorkeling in deep water near Los Cristianos" />
                            <Modal
                                show={Modal36}
                                onHide={handleClose36}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Gonza and his daughter Moana snorkeling in deep water near Los Cristianos</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb36} alt="Gonza and his daughter Moana snorkeling in deep water near Los Cristianos" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow37} src={thumb37} alt="Marcos and Eliana taking a selfie underwater for the Instagram Scuba Point page" />
                            <Modal
                                show={Modal37}
                                onHide={handleClose37}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Marcos and Eliana taking a selfie underwater for the Instagram Scuba Point page</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb37} alt="Marcos and Eliana taking a selfie underwater for the Instagram Scuba Point page" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow38} src={thumb38} alt="Diver descending a rope near Tenerife" />
                            <Modal
                                show={Modal38}
                                onHide={handleClose38}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Diver descending a rope near Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb38} alt="Diver descending a rope near Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="item col-sm-6 col-lg-4 mb-4">
                        <div className="polaroid">
                            <img className="img-fluid" onClick={handleShow39} src={thumb39} alt="Two divers giving the  OK underwater with Scuba Point in Tenerife" />
                            <Modal
                                show={Modal39}
                                onHide={handleClose39}
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className='mt-3'>Two divers giving the  OK underwater with Scuba Point in Tenerife</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img className="img-fluid" src={thumb39} alt="Two divers giving the  OK underwater with Scuba Point in Tenerife" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    {/* <motion.div
      className='col-12 col-xl-12'38
      initial={{ opacity: 0 }}
      animate={{
       y: -20,
       opacity: 1,
       position: "relative",
       transition: { duration: 2.25, delay: 2.25 }
      }}
     >
      <br />
      <BookingsModal />
     </motion.div> */}
                </Row>
            </Container>
        </motion.div >
    )
}

export default InnerGallery
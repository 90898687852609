import React from 'react'
import "./../css/Socialicons.scss";
// import { Link } from "react-router-dom"; 
import facebookIcon from "./../img/icons/facebook.png";
import instagramIcon from "./../img/icons/instagram.png";
import pinterestIcon from "./../img/icons/pinterest.png";
import twitterIcon from "./../img/icons/twitter.png";
import linkedinIcon from "./../img/icons/linkedin.png";
import tiktokIcon from "./../img/icons/tiktok.png";
// import whatsappIcon from "./../img/icons/whatsapp.png";

// function showMenu() {
//  var toggleVariable: any = document.getElementById('main-menu');
//  //if shown
//  if (toggleVariable.style.display === 'block') {
//   // hide it after.5
//   setTimeout(function () {
//    toggleVariable.style.display = 'none';
//   }, 300);
//   // change the class
//   toggleVariable.classList.remove('menu-on');
//   toggleVariable.classList.add('menu-off');
//   //stuff to remove cross from burger
//   var showCross: any = document.getElementById('burger-cross');
//   showCross.classList.remove('show-cross');
//  } else {
//   //otherwise show it
//   toggleVariable.style.display = 'block';
//   // otherwise add the class
//   toggleVariable.classList.add('menu-on');
//   toggleVariable.classList.remove('menu-off');
//   //stuff to add cross from burger
//   var showCross2: any = document.getElementById('burger-cross');
//   showCross2.classList.add('show-cross');
//  }
// }


function SocialIcons() {
 var facebookRoll = {
  backgroundImage: `url(${facebookIcon})`,
 };
 var instagramRoll = {
  backgroundImage: `url(${instagramIcon})`,
 };
 var pinterestRoll = {
  backgroundImage: `url(${pinterestIcon})`,
 };
 var twitterRoll = {
  backgroundImage: `url(${twitterIcon})`,
 };
 var linkedinRoll = {
  backgroundImage: `url(${linkedinIcon})`,
 };
 var tiktokRoll = {
  backgroundImage: `url(${tiktokIcon})`,
 };
 // var whatsappRoll = {
 //  backgroundImage: `url(${whatsappIcon})`,
 // };
 return (

  <>
   <a
    aria-label="Social links"
    href="https://www.facebook.com/profile.php?id=100094764036107"
   >
    <span className="social-roll" style={facebookRoll}></span>
   </a>
   <a
    aria-label="Social links"
    href="https://www.instagram.com/scubapointtenerife/?hl=en"
   >
    <span className="social-roll" style={instagramRoll}></span>
   </a>
   <a
    aria-label="Social links"
    href="https://www.pinterest.com/ScubaPointDivingTenerife/"
   >
    <span className="social-roll" style={pinterestRoll}></span>
   </a>
   <a
    aria-label="Social links"
    href="https://twitter.com/ScubaPointDives"
   >
    <span className="social-roll" style={twitterRoll}></span>
   </a>

   <a
    aria-label="Social links"
    href="https://www.linkedin.com/in/scuba-point-diving-tenerife/"
   >
    <span className="social-roll" style={linkedinRoll}></span>
   </a>
   <a
    aria-label="Social links"
    href="https://www.tiktok.com/@scubapointtenerife"
   >
    <span className="social-roll" style={tiktokRoll}></span>
   </a>
   {/* <Link onClick={showMenu} to="/contact/">
    <span className="social-roll" style={whatsappRoll}></span>
   </Link> */}
  </>
 )
}

export default SocialIcons
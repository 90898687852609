import React from 'react';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Google_reviews.scss';
import TripAdvisor from './../img/partners/logo_trip_advisor_2024.png';
import Viator from './../img/partners/logo_viator_2024.png';
import GetYourGuide from './../img/partners/logo_get_your_guide_2024.png';

function AboutEventPartners() {


  return (
    <>
      <motion.div
        className='mb-2 col-12 mt-5'
        initial={{ opacity: 0 }}
        animate={{
          y: 80,
          opacity: 1,
          position: "relative",
          transition: { duration: 1.5, delay: 2.5 }
        }}
      ><span className="h1 mb-5">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              position: "relative",
              transition: { duration: 2, delay: 1 }
            }}
          >Our </motion.span>
          Event Partners</span>
      </motion.div>

      <motion.div
        className='col-12 mt-2'
        initial={{ opacity: 0 }}
        animate={{
          y: 80,
          opacity: 1,
          position: "relative",
          transition: { duration: 1.5, delay: 3 }
        }}
      >
        <p>
          We have partnered up with some other events websites to bring you more diving events! We are with <a href="/">Viator</a>, <a href="/">Get your Guide</a> and <a href="/">Trip Advisor</a>. Check out our Scuba Point partners:
        </p>

      </motion.div>
      <div className='container'>
        <div className='row'>
          <motion.div
            className='col-12 col-md-4 px-3'
            initial={{ opacity: 0 }}
            animate={{
              y: 80,
              opacity: 1,
              position: "relative",
              transition: { duration: 1.2, delay: 1.1 }
            }}
          >
            <img src={TripAdvisor} className='w-100 img-fluid' alt="Trip Advisor Scuba Point" />
            <h2 className='text-center pt-4'>Scuba Point on Trip Advisor</h2>
            <p className='text-center'>Check out our profile and book with us there if you prefer.</p>
            <a href="https://www.tripadvisor.com/Attraction_Review-g187479-d26224652-Reviews-ScubaPoint-Tenerife_Canary_Islands.html" className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" >VISIT TRIP AVISOR</a>

          </motion.div>
          <motion.div
            className='col-12 col-md-4 px-3'
            initial={{ opacity: 0 }}
            animate={{
              y: 80,
              opacity: 1,
              position: "relative",
              transition: { duration: 1.2, delay: 1.3 }
            }}
          >
            <img src={Viator} className='w-100 img-fluid' alt="Viator Scuba Point" />
            <h2 className='text-center pt-4'>Scuba Point on Viator website</h2>
            <p className='text-center'>Check out our profile and book with us there if you prefer.</p>
            <a href="https://www.viator.com/tours/Tenerife/Fun-Dive-With-Scuba-Point-in-Los-Cristianos/d5404-402432P1" className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" >VISIT VIATOR PROFILE</a>
          </motion.div>
          <motion.div
            className='col-12 col-md-4 px-3'
            initial={{ opacity: 0 }}
            animate={{
              y: 80,
              opacity: 1,
              position: "relative",
              transition: { duration: 1.2, delay: 1.5 }
            }}
          >
            <img src={GetYourGuide} className='w-100 img-fluid' alt="Get Your Guide Scuba Point" />
            <h2 className='text-center pt-4'>Scuba Point on Get Your Guide</h2>
            <p className='text-center'>Check out our profile and book with us there if you prefer.</p>
            <a href="https://www.getyourguide.com/los-cristianos-l103407/snorkel-boat-tour-in-los-cristianos-tenerife-t587100/?preview=CBVMQI2VY956AU5068L8LVJCJ6W51162" className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" >GET YOUR GUIDE</a>
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default AboutEventPartners


















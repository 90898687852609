import React from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion'
import './../css/DiveMap.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import DiveMapImg from "./../img/DiveMap/DiveMap.png";
import DiveMapClickablePoints from "./DiveMapClickablePoints";
import PlaneImg from "./../img/DiveMap/plane.png";
import Cloud1Img from "./../img/DiveMap/cloud1.png";
import Cloud2Img from "./../img/DiveMap/cloud2.png";
import Cloud3Img from "./../img/DiveMap/cloud3.png";
// import team3 from './../img/team3.png';
function DiveMap() {

    const DiveMapldJson = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Interactive Dive Map | Scuba Point Tenerife",
        "description": "Dive into our Interactive Dive Map at Scuba Point Tenerife! Experience a revolutionary way to explore underwater worlds!",
        "url": "https://www.scuba-point.com/interactive_scuba_diving_map_of_tenerife/",
        "about": [
            {
                "@type": "Place",
                "name": "La Vista Beach",
                "description": "La Vista Beach is a slice of paradise that provides an experience for both beach lovers and underwater adventurers.",
                "image": "https://scuba-point.com/static/media/playa-vista-tenerife.3959edf21f15e88d9761.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.049646",
                    "longitude": "-16.721589"
                }
            },
            {
                "@type": "Place",
                "name": "Pal Mar",
                "description": "This dive site, snuggled just off the quaint community of Palm Mar, is designed by Mother Nature herself to be a marine wonderland.",
                "image": "https://scuba-point.com/static/media/Pall1.4a884556e9ec2ba42e2e.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.034039",
                    "longitude": "-16.710198"
                }
            },
            {
                "@type": "Place",
                "name": "Ali Baba Cave",
                "description": "The Ali Baba Cave beckons with its aura of mystery and reverence",
                "image": "https://scuba-point.com/static/media/cave1.245ff92dbba7f00f8cca.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.032228",
                    "longitude": "-16.709125"
                }
            },
            {
                "@type": "Place",
                "name": "The Neptune Cave",
                "description": "Positioned just 20 minutes south of Tenerife, and a short swim away from the iconic Punta Rasca lighthouse, lies the enchanting Neptune Cave.",
                "image": "https://scuba-point.com/static/media/Puerto1.1aa232d7a0079906d216.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.030448",
                    "longitude": "-16.707364"
                }
            },
            {
                "@type": "Place",
                "name": "Cruz San Miguel",
                "description": "The dive starts with a good ol' splashdown from Los Cristianos or Las Galletas, aiming straight for La Punta de Rasca.",
                "image": "https://scuba-point.com/static/media/Cruz2.e42e2a390991cee602ff.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.001556",
                    "longitude": "-16.656928"
                }
            },
            {
                "@type": "Place",
                "name": "El Meridian Wreck",
                "description": "El Meridian (also known as The Old KFK 469 but she's sensitive about the old part! ",
                "image": "https://scuba-point.com/static/media/Meridian1.9dabbfb08af71303b64b.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "27.997161",
                    "longitude": "-16.652634"
                }
            },
            {
                "@type": "Place",
                "name": "El Condesito ship wreck diving site",
                "description": "Well this is a funny one, rumor has it the crew got drunk on the stocked alcohol, hit the reef and sunk it! ",
                "image": "https://scuba-point.com/static/media/Condesito1.35aa52b4ff1782239c97.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.007888",
                    "longitude": "-16.630302"
                }
            },
            {
                "@type": "Place",
                "name": "Roncadores Del Faro",
                "description": "Weird volcanic rock formations, just for you!",
                "image": "https://scuba-point.com/static/media/Roncadores1.65f5baa2e931bd6c707a.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.012738",
                    "longitude": "-16.624461"
                }
            },
            {
                "@type": "Place",
                "name": "Los Chuchos",
                "description": "Welcome to the Los Chuchos Dive Site! Just a hop, skip, and a two-minute boat ride away from the port of Las Galletas.",
                "image": "https://scuba-point.com/static/media/Chuchos1.25176d442f97b58ad2e5.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.006124",
                    "longitude": "-16.632162"
                }
            },
            {
                "@type": "Place",
                "name": "Los Champinones",
                "description": "It's not a trendy new restaurant or the name of a rock band, but a dive site in Las Galletas Bay christened after two mushroom-shaped rocks!",
                "image": "https://scuba-point.com/static/media/champ1.65386b47b912bc7c3a2e.jpg",
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "28.047826",
                    "longitude": "-16.530678"
                }
            },
        ]
    }


    return (
        <div
            className='dive-map-wrapper'
        >

            <motion.div
                className="dive-map"
                style={{ backgroundImage: `url(${DiveMapImg})` }}
                initial={{ width: "0%" }}
                animate={{ width: "100%" }}
                exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Interactive Dive Map of Tenerife | Scuba Point Dive School </title>
                    <link rel="canonical" href="https://scuba-point.com/interactive_scuba_diving_map_of_tenerife/" />
                    <meta name="description" content="Dive into our Interactive Dive Map at Scuba Point Tenerife! Experience a revolutionary way to explore underwater worlds!" />
                    <meta name="keywords" content="Scuba Diving, Dive Map, Interactive Dive Map, Scuba Point, Tenerife Diving, Diving Exploration, Dive Sites, Scuba Point Map" />
                </Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(DiveMapldJson)}
                </script>
                <div className='hero'>
                    <h1 className="text-white pt-5 mt-5">DIVE MAP</h1>
                    <p className="text-white w-50 pb-5 mb-5">Our Interactive Dive Map will guide you on our trips together! Click on a dive spot for more&nbsp;info!</p>
                </div>
                <img src={PlaneImg} alt="Plane" className="plane" />
                <img src={Cloud1Img} alt="Cloud1" className="cloud1" />
                <img src={Cloud2Img} alt="Cloud2" className="cloud2" />
                <img src={Cloud3Img} alt="Cloud3" className="cloud3" />
                <DiveMapClickablePoints />
            </motion.div >
        </div>
    )
}

export default DiveMap
import React from 'react';
import NotFound from "./../img/404.png";
import './../css/404.scss';
import { Link } from "react-router-dom";
function NotFoundPage() {
 return (
  <div className="mt-5 pt-5 mb-5 pb-5 Four04">
   <img className='d-block m-auto mw-25 mt-5 pt-5' src={NotFound} alt="Scuba Point Diving Tenerife 404 page ocean sunset illustration" />
   <h1 className="text-center mt-5">404: Page Not Found</h1>
   <h5 className="text-center  mt-5">Maybe it's swimming with the fishes?</h5>
   <h5 className="text-center">While we search the depths, why not dive back to our <Link to="/">homepage</Link>?</h5>
   <h5 className="text-center mb-5 pb-5">And remember, keep your friends close and your anemones closer!</h5>
  </div>
 );
}

export default NotFoundPage;
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Accordion.scss';

function FAQAccordion() {

  return (
    <>
      <motion.div
        className='mb-2 col-12'
        initial={{ opacity: 0 }}
        animate={{
          y: 20,
          opacity: 1,
          position: "relative",
          transition: { duration: 1.5, delay: 0.5 }
        }}
      >
        {/* <Accordion defaultActiveKey="0"> */}
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>1. What is Scuba Point?</Accordion.Header>
            <Accordion.Body>
              <p><Link to="/">Scuba Point</Link> is a premier diving school located in Tenerife, Spain. We offer a wide range of diving courses and excursions for all experience levels. Our aim is to help you discover the stunning underwater world of Tenerife, filled with vibrant marine life and breath-taking landscapes.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>2. I have never dived before. Can I still dive with Scuba Point?</Accordion.Header>
            <Accordion.Body>
              <p>Absolutely! We offer beginner courses that are designed to introduce you to scuba diving. Our professional instructors will ensure you are comfortable and ready before you make your first dive.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>3. Do I need any special equipment?</Accordion.Header>
            <Accordion.Body>
              <p>We provide all the necessary scuba diving equipment. However, if you have your own gear, feel free to bring it along.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>4. Are there any age or health restrictions for diving?</Accordion.Header>
            <Accordion.Body>
              <p>Diving is generally safe for most people who are in good health. However, certain medical conditions may require a doctor's approval before you can dive. As for age, we welcome divers of all ages, but children must be at least 10 years old to participate in our diving courses and excursions.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>5. What kind of marine life can I expect to see?</Accordion.Header>
            <Accordion.Body>
              <p>Tenerife's waters are teeming with marine life, including green turtles, rays, octopus, moray eels, and a variety of fish species. You might also encounter sunken ships and underwater caves on your diving excursions.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>6. How can I book a diving course or excursion?</Accordion.Header>
            <Accordion.Body>
              <p>You can book directly through our website. We offer a 10% discount for all bookings made online.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>7. What safety measures does Scuba Point take?</Accordion.Header>
            <Accordion.Body>
              <p>Your safety is our top priority. All our instructors are PADI-certified and adhere to the highest safety standards. We ensure that all equipment is regularly serviced and maintained.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>8. I am a certified diver. What courses or excursions do you offer?</Accordion.Header>
            <Accordion.Body>
              <p>We offer a variety of advanced courses and excursions for certified divers, including deep diving, night diving, and wreck diving.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>9. What is the cancellation policy?</Accordion.Header>
            <Accordion.Body>
              <p>Please contact us directly for information about our cancellation policy.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>10. I have more questions. How can I contact Scuba Point?</Accordion.Header>
            <Accordion.Body>
              <p>We would be happy to answer any other questions you may have. Please contact us via our website, email, or phone number provided on the "Contact Us" page.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>11. Dive into Fun in Tenerife</Accordion.Header>
            <Accordion.Body>
              <p>At Scuba Point, we believe in making a splash! Just like a clownfish in an anemone, we've found our happy place in Tenerife's vibrant underwater world, and we're all about sharing it with you. Why did the scuba diver go to school? Because he wanted to improve his 'current' knowledge, just like us! We've been schooling (fish pun intended) divers for over 4 years.
                We offer a sea-lection of <Link to="/packages_from_scuba_point_tenerife">diving courses</Link> that cater to everyone from 'just dipping a toe in' beginners to 'swimming with the sharks' professionals. Our diving excursions are more packed with adventure than a squid's suitcase! We’ll introduce you to underwater celebrities from the reclusive octopus to the gregarious green turtles. And trust us, once you’ve danced with the rays, you’ll never want to do the 'ray-gular' dance again!</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>12. The Crew</Accordion.Header>
            <Accordion.Body>
              <p> Now, meet our team. Our founder, Marcos, is so experienced, he was diving before dolphins made it cool. Then there's Gonza, our Argentinian legend. He's made more bubbles than a hiccupping seahorse! And don't forget Eliana, who might not be leading dives but she's the 'ray-sponsible' one keeping our fins in line on land.
              </p>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>13. Maintenance</Accordion.Header>
            <Accordion.Body>
              <p>  At Scuba Point, we’re about more than just a good time underwater. We’re committed to making every dive 'reel-y' count. From maintaining our top-notch equipment that's more checked than a clownfish’s calendar, to upholding safety standards higher than a jumping marlin, we ensure you’re in safe hands, or fins, so to speak.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>14. Discounts</Accordion.Header>
            <Accordion.Body>
              <p>So, come on in, the water’s fine! And remember, if you book through our website, you'll get a discount that'll make even a stingy crab smile. <Link to="/packages_from_scuba_point_tenerife">10% off</Link> - now, that's a deal worth diving for!</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>15. Come As You Are</Accordion.Header>
            <Accordion.Body>
              <p>We’re all about sea-rious fun at Scuba Point. So, if you're ready to have a whale of a time, come dive with us. Just don't forget to bring your sense of adventure!"
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion >
      </motion.div>
    </>








  )
}

export default FAQAccordion
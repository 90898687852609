import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom"
import { AnimatePresence } from 'framer-motion'
import Home from "./Home"
import About from "./About"
import Packages from "./Packages"
import Gallery from "./Gallery"
import FAQ from "./FAQ"
import Partners from "./Partners"
import DiveMap from "./DiveMap"
import Blog from "./Blog"
import Contact from "./Contact"
import NotFoundPage from "./404"
function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="about_scuba_point_diving_tenerife" element={<About />} />
        <Route path="packages_from_scuba_point_tenerife" element={<Packages />} />
        <Route path="gallery_of_scuba_diving_tenerife" element={<Gallery />} />
        <Route path="partners_of_scuba_point_in_tenerife" element={<Partners />} />
        <Route path="interactive_scuba_diving_map_of_tenerife" element={<DiveMap />} />
        <Route path="blog_about_scuba_diving_tenerife" element={<Blog />} />
        <Route path="faq_about_scuba_diving_tenerife" element={<FAQ />} />
        <Route path="contact_scuba_point_in_tenerife" element={<Contact />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AnimatePresence >
  )
}

export default AnimatedRoutes
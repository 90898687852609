import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutEventPartners from './AboutEventPartners';
import Archibaldbutler from './../img/partners/logo_Archibald_Butler_com_2024.png';
import TenerifeBoutique from './../img/partners/logo_tenerife_boutique_2024.png';
import EndlessSummer from './../img/partners/logo_endless_summer_tenerife_2024.png';

// import team3 from './../img/team3.png';
function Partners() {

  const [marcosModal, setMarcosModal] = useState(false);
  const [gonzaModal, setGonzaModal] = useState(false);
  const [elianaModal, setElianaModal] = useState(false);
  const handleMarcosClose = () => setMarcosModal(false);
  const handleBoutiqueShow = () => setMarcosModal(true);
  const handleGonzaClose = () => setGonzaModal(false);
  const handleGonzaShow = () => setGonzaModal(true);
  const handleElianaClose = () => setElianaModal(false);
  const handleElianaShow = () => setElianaModal(true);

  const PartnersldJson = {
    "@context": "http://schema.org",
    "@type": "AboutPage",
    "name": "Partners - Scuba Point Tenerife",
    "description": "Partners of Scuba Point Tenerife are about to make a splash! Stay tuned for an amazing line-up of collaborations. 'Water' you waiting for?",
    "url": "https://scuba-point.com/partners_of_scuba_point_in_tenerife/",
    "about": [
      {
        "@type": "Organization",
        "name": "Tenerife Boutique",
        "url": "https://tenerife.boutique/",
        "description": "The full 2024 guide to Tenerife for you to browse all events and excursions.",
        "logo": "https://tenerife.boutique/assets/img/logo/tenerife-boutique-guide.webp"
      },
      {
        "@type": "Organization",
        "name": "Endless Summer",
        "url": "https://www.endlesssummertenerife.com/",
        "description": "Our accommodation partner, the Endless Summer Hostel, is a great place to stay.",
        "logo": "https://i0.wp.com/www.endlesssummertenerife.com/wp-content/uploads/2022/05/Mesa-de-trabajo-34.png?resize=150%2C150&ssl=1"
      },
      {
        "@type": "Organization",
        "name": "Archibald Butler",
        "url": "https://archibaldbutler.com/",
        "description": "Our very own professional web developer living here in Tenerife, looking for new projects!",
        "logo": "https://archibaldbutler.com/static/media/archibald-butler-web-design-surfing-travel-logo.21666762.gif"
      },
      {
        "@type": "Organization",
        "name": "Trip Advisor",
        "url": "https://www.tripadvisor.com/Attraction_Review-g187479-d26224652-Reviews-ScubaPoint-Tenerife_Canary_Islands.html",
        "description": "Our very own page on Trip Advisor, you can book there too!",
        "logo": "https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg"
      },
      {
        "@type": "Organization",
        "name": "Viator",
        "url": "https://www.viator.com/tours/Tenerife/Fun-Dive-With-Scuba-Point-in-Los-Cristianos/d5404-402432P1",
        "description": "Find and book Scuba Point Dives on Viator!",
        "logo": "https://supplier.viator.com/static/images/logos/viator-logo.svg"
      },
      {
        "@type": "Organization",
        "name": "Get Your Guide",
        "url": "https://www.getyourguide.com/los-cristianos-l103407/snorkel-boat-tour-in-los-cristianos-tenerife-t587100/?preview=CBVMQI2VY956AU5068L8LVJCJ6W51162",
        "description": "Find and book Scuba Point Dives on GetYourGuide.com!",
        "logo": "https://cdn.getyourguide.com/tf/assets/static/logos/gyg-logo.svg"
      },
    ]
  }


  return (
    <motion.div
      className="wrapper mb-5 pb-5"
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partners - Scuba Point Tenerife</title>
        <link rel="canonical" href="https://www.scuba-point.com/partners_of_scuba_point_in_tenerife/" />
        <meta name="description" content="Partners of Scuba Point Tenerife are about to make a splash! Stay tuned for an amazing line-up of collaborations. 'Water' you waiting for?" />
        <meta name="keywords" content="Scuba Diving, Partners, Diving Partners, Scuba Point, Tenerife Diving, Diving Collaborations, Scuba Partners, Diving Companies, Scuba Point Partners" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(PartnersldJson)}
      </script>
      <Container>
        <Row>
          <div className="col-12 col-xl-8 offset-xl-2 mb-5 pb-5">
            <Container>
              <Row>
                <div
                  className='col-12'
                >
                  <motion.h1
                    className='col-xl-9'
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 1, delay: 0 }
                    }}>
                    <span className="h1 mb-5">
                      SCUBa
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{

                          opacity: 1,
                          position: "relative",
                          transition: { duration: 2, delay: 1 }
                        }}
                      >&nbsp;POINT </motion.span>
                      PARTNERS</span></motion.h1>
                  <motion.p
                    className="col-xl-9"
                    initial={{ opacity: 0 }}
                    animate={{
                      y: 20,
                      opacity: 1,
                      position: "relative",
                      transition: { duration: 0.3, delay: 0.3 }
                    }}
                  >

                    Our list of partners is currently doing the backstroke, but they'll be swimming up to the surface soon! They are so good, we bet you'll 'fall hook, line, and sinker' for them. 'Water' you waiting for? Check back&nbsp;soon!
                  </motion.p>
                </div>
                <motion.div
                  className='col-12 col-md-4'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.1 }
                  }}
                >
                  <img src={TenerifeBoutique} className='w-100 img-fluid' alt="Tenerife.Boutique: the number one events and excursions guide to Tenerife." />
                  <h2 className='text-center pt-4'>
                    <a href="https://tenerife.boutique/">Tenerife Boutique</a>
                  </h2>
                  <p className='text-center'>The full 2024 guide to Tenerife for you to browse all events and excursions.</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleBoutiqueShow}>READ&nbsp;MORE</span>
                  <Modal
                    keyboard={false}
                    show={marcosModal}
                    onHide={handleMarcosClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'>The <a href="https://tenerife.boutique/">Tenerife Boutique</a></h2></Modal.Title>

                    </Modal.Header>
                    <Modal.Body>

                      <p>We have had the privilege of working with an <a title="expert in technology information, creator of event articles at Tenerife.Boutique" aria-label="expert in technology information, creator of event articles at Tenerife.Boutique" href="https://tenerife.boutique/">expert in technology information, creator of event articles at Tenerife.Boutique</a>. Check out their <a title="expert in technology information, creator of event articles at Tenerife.Boutique" aria-label="expert in technology information, creator of event articles at Tenerife.Boutique" href="https://tenerife.boutique/">expert level website</a> for all the coolest events and excursions in Tenerife. </p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.3 }
                  }}
                >
                  <img src={EndlessSummer} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'><a href="https://www.endlesssummertenerife.com/">Endless Summer</a></h2>
                  <p className='text-center'> Our accomodation partner, the Endless Summer Hostel, are a great place to stay.</p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleGonzaShow}>READ&nbsp;MORE</span>
                  <Modal
                    show={gonzaModal}
                    onHide={handleGonzaClose}
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'>The <a href="https://www.endlesssummertenerife.com/">Endless Summer</a> hostel</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>A great place to <a href="https://www.endlesssummertenerife.com/">stay in the south coast of Tenerife</a>, where you can meet other travellers, partake in activites and social events!</p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
                <motion.div
                  className='col-12 col-md-4 mb-5'
                  initial={{ opacity: 0 }}
                  animate={{
                    y: 80,
                    opacity: 1,
                    position: "relative",
                    transition: { duration: 1.2, delay: 1.5 }
                  }}
                >
                  <img src={Archibaldbutler} className='w-100 img-fluid' alt="Gonza, instructor" />
                  <h2 className='text-center pt-4'><a href="https://archibaldbutler.com/">Archibald Butler</a></h2>
                  <p className='text-center'><p> Our very own professional web developer living here in Tenerife, is looking for new projects!</p></p>
                  <span className="btn btn-outline-dark btn-lg mx-auto w-100 d-block mb-3" onClick={handleElianaShow}>READ&nbsp;MORE</span>
                  <Modal
                    show={elianaModal}
                    onHide={handleElianaClose}
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title><h2 className='mt-3'><a href="https://archibaldbutler.com/">Archibald Butler</a></h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p> Our very own now Tenerifian (but from London) <a href="https://archibaldbutler.com/">expert web developer</a> with over 20 years of professional experience in London. Now living here to surf and provide us with awesome illustrated websites! Check out his portfolio <a href="https://archibaldbutler.com/">here</a>.</p>
                    </Modal.Body>
                  </Modal>
                </motion.div>
                <AboutEventPartners />
              </Row>
            </Container>
          </div>

        </Row>
      </Container>

    </motion.div >
  )
}

export default Partners
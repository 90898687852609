import React from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import FAQAccordion from "./FAQAccordion";
import 'bootstrap/dist/css/bootstrap.min.css';
// import team3 from './../img/team3.png';
function FAQ() {
  const FAQldJson = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "name": "Scuba Point FAQ - Your Deep Dive into Diving Queries | Coming Soon",
    "description": "Find answers to all your under-the-sea queries on Scuba Point's FAQ page. Dive into our knowledge treasure trove and surface with a pearl of wisdom.",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What is Scuba Point?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Scuba Point is a premier diving school located in Tenerife, Spain. We offer a wide range of diving courses and excursions for all experience levels. Our aim is to help you discover the stunning underwater world of Tenerife, filled with vibrant marine life and breathtaking landscapes."
        }
      },
      {
        "@type": "Question",
        "name": "I have never dived before. Can I still dive with Scuba Point?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Absolutely! We offer beginner courses that are designed to introduce you to scuba diving. Our professional instructors will ensure you are comfortable and ready before you make your first dive."
        }
      },
      {
        "@type": "Question",
        "name": "Do I need any special equipment?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We provide all the necessary scuba diving equipment. However, if you have your own gear, feel free to bring it along."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any age or health restrictions for diving?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Diving is generally safe for most people who are in good health. However, certain medical conditions may require a doctor's approval before you can dive. As for age, we welcome divers of all ages, but children must be at least 10 years old to participate in our diving courses and excursions."
        }
      },
      {
        "@type": "Question",
        "name": "What kind of marine life can I expect to see?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Tenerife's waters are teeming with marine life, including green turtles, rays, octopus, moray eels, and a variety of fish species. You might also encounter sunken ships and underwater caves on your diving excursions."
        }
      },
      {
        "@type": "Question",
        "name": "How can I book a diving course or excursion?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can book directly through our website. We offer a 10% discount for all bookings made online."
        }
      },
      {
        "@type": "Question",
        "name": "What safety measures does Scuba Point take?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Your safety is our top priority. All our instructors are PADI-certified and adhere to the highest safety standards. We ensure that all equipment is regularly serviced and maintained."
        }
      },
      {
        "@type": "Question",
        "name": "I am a certified diver. What courses or excursions do you offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We offer a variety of advanced courses and excursions for certified divers, including deep diving, night diving, and wreck diving."
        }
      },
      {
        "@type": "Question",
        "name": "What is the cancellation policy?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Please contact us directly for information about our cancellation policy."
        }
      },
      {
        "@type": "Question",
        "name": "I have more questions. How can I contact Scuba Point?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We would be happy to answer any other questions you may have. Please contact us via our website, email, or phone number provided on the Contact Us page."
        }
      },
      {
        "@type": "Question",
        "name": "Dive into Fun in Tenerife",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "At Scuba Point, we believe in making a splash! Just like a clownfish in an anemone, we've found our happy place in Tenerife's vibrant underwater world, and we're all about sharing it with you. Why did the scuba diver go to school? Because he wanted to improve his 'current' knowledge, just like us! We've been schooling (fish pun intended) divers for over 4 years. We offer a sea-lection of diving courses that cater to everyone from 'just dipping a toe in' beginners to 'swimming with the sharks' professionals. Our diving excursions are more packed with adventure than a squid's suitcase! We'll introduce you to underwater celebrities from the reclusive octopus to the gregarious green turtles. And trust us, once you've danced with the rays, you'll never want to do the 'ray-gular' dance again!"
        }
      },
      {
        "@type": "Question",
        "name": "Can you tell me about the crew?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Now, meet our team. Our founder, Marcos, is so experienced, he was diving before dolphins made it cool. Then there's Gonza, our Argentinian legend. He's made more bubbles than a hiccupping seahorse! And don't forget Eliana, who might not be leading dives but she's the 'ray-sponsible' one keeping our fins in line on land."
        }
      },
      {
        "@type": "Question",
        "name": "Do you maintain the equipment?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "At Scuba Point, we're about more than just a good time underwater. We're committed to making every dive 'reel-y' count. From maintaining our top-notch equipment that's more checked than a clownfish's calendar, to upholding safety standards higher than a jumping marlin, we ensure you're in safe hands, or fins, so to speak."
        }
      },
      {
        "@type": "Question",
        "name": "Do you have discounts?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "So, come on in, the water's fine! And remember, if you book through our website, you'll get a discount that'll make even a stingy crab smile. 10% off - now, that's a deal worth diving for!"
        }
      },
      {
        "@type": "Question",
        "name": "Do you see all types of people?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "We're all about sea-rious fun at Scuba Point. So, if you're ready to have a whale of a time, come dive with us. Just don't forget to bring your sense of adventure!"
        }
      },
    ]
  }
  return (
    <motion.div
      className="wrapper"
      initial={{ width: "0%" }}
      animate={{ width: "100%" }}
      exit={{ transform: "translate(100%, 0)", transition: { duration: 0.5 } }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Scuba Point FAQ - Your Deep Dive into Diving Queries | Coming Soon</title>
        <link rel="canonical" href="https://www.scuba-point.com/faq_about_scuba_diving_tenerife/" />
        <meta name="description" content="Find answers to all your under-the-sea queries on Scuba Point's FAQ page. Dive into our knowledge treasure trove and surface with a pearl of wisdom. Frequently Asked Questions are coming soon!" />
        <meta name="keywords" content="Scuba Diving, FAQ, Diving Queries, Scuba Point, Tenerife Diving, Diving Help, Diving Information, Scuba FAQ, Diving Tips, Scuba Point FAQ" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(FAQldJson)}
      </script>
      <Container>
        <Row>
          <motion.h1
            className='col-xl-7'
            initial={{ opacity: 0 }}
            animate={{
              y: 20,
              opacity: 1,
              position: "relative",
              transition: { duration: 1, delay: 0 }
            }}
          >
            <span className="h1">THE SCUBa
              <motion.span
                initial={{ opacity: 0 }}
                animate={{

                  opacity: 1,
                  position: "relative",
                  transition: { duration: 2, delay: 1 }
                }}
              >&nbsp;point </motion.span>
              FAQ</span></motion.h1>
          <motion.p
            className="col-xl-6"
            initial={{ opacity: 0 }}
            animate={{
              y: 20,
              opacity: 1,
              position: "relative",
              transition: { duration: 0.3, delay: 0.3 }
            }}
          >
            Welcome to the most entertaining FAQ page you'll ever encounter under the sea or on dry land! Here at Scuba Point, we believe that even the serious task of answering questions can have a splash of fun. So, dive right in!
          </motion.p>

          <motion.div
            className='mb-2 col-12 mb-3'
            initial={{ opacity: 0 }}
            animate={{
              y: 20,
              opacity: 1,
              position: "relative",
              transition: { duration: 1.5, delay: 0 }
            }}
          >
            <FAQAccordion />
          </motion.div>
        </Row>
      </Container>
    </motion.div >
  )
}

export default FAQ